// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { InvoiceItemResponse } from './invoice-item-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentTotalAmount!: number;

	@GandalfProperty()
	age!: number;

	@GandalfProperty({ propertyType: 'Money' })
	amountPaid!: number;

	@GandalfProperty()
	appointmentId!: number | null;

	@GandalfProperty()
	approved!: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	balance!: number;

	@GandalfProperty({ propertyType: 'Money' })
	discountTotalAmount!: number;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	feeScheduleDate!: Date | null;

	@GandalfProperty()
	feeScheduleId!: number | null;

	@GandalfProperty()
	feeScheduleName!: string | null;

	@GandalfProperty()
	financeChargePlanId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	firstStatementPrintDate!: Date | null;

	@GandalfProperty()
	id!: number;

	@GandalfProperty()
	insurancePriorityValue!: string | null;

	@GandalfProperty()
	insuranceTypeValue!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDate!: Date;

	@GandalfArray(InvoiceItemResponse)
	items!: InvoiceItemResponse[];

	@GandalfProperty()
	locationId!: number;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	orderId!: number | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	payerAddress!: AddressResponse | null;

	@GandalfProperty()
	payerAllowItemDiscounts!: boolean | null;

	@GandalfProperty()
	payerAllowTransferTax!: boolean | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty()
	payerDefaultPaymentMethod!: constants.PaymentMethodType | null;

	@GandalfProperty()
	payerEmail!: string | null;

	@GandalfProperty()
	payerEntityId!: number | null;

	@GandalfProperty()
	payerId!: number;

	@GandalfProperty()
	payerName!: string | null;

	@GandalfProperty()
	payerPhone!: string | null;

	@GandalfProperty()
	payerPolicyFeeScheduleId!: number | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType;

	@GandalfProperty()
	personInsuranceId!: number | null;

	@GandalfProperty()
	providerId!: number | null;

	@GandalfProperty()
	providerName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statementPrintDate!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceStatus)
	@GandalfProperty()
	status!: constants.InvoiceStatus;

	@GandalfProperty({ propertyType: 'Money' })
	subTotalAmount!: number;

	@GandalfProperty({ propertyType: 'Money' })
	taxTotalAmount!: number;

	@GandalfProperty({ propertyType: 'Money' })
	totalAmount!: number;

	@GandalfProperty()
	transferTaxInvoiceId!: number | null;

}
