// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.AddDiscountRequest */
/* istanbul ignore next */
@GandalfModel
export class AddDiscountRequest extends GandalfModelBase {

	@GandalfLabel('Amount')
	@GandalfValidator({ moneyConstraints: { positiveAllowed: true, zeroAllowed: true, negativeAllowed: false, message: 'Amount cannot be negative' } })
	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	amount!: number;

	@GandalfLabel('Description')
	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	description!: string;

	@GandalfLabel('Discount Reference')
	@GandalfProperty()
	discountReferenceId!: number | null;

	@GandalfLabel('Invoice')
	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId!: number;

}
