// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeResponse } from './employee-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.encounter.EncounterResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	approvalDate!: Date | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty()
	approvalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfProperty()
	employee!: EmployeeResponse | null;

	@GandalfProperty()
	encounterDate!: Date | null;

	@GandalfProperty()
	firstApprovalDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	provider!: ProviderResponse | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty()
	status!: constants.EncounterStatus | null;

	@GandalfProperty()
	subTypeName!: string | null;

	@GandalfProperty()
	templateName!: string | null;

	@GandalfProperty()
	wasSigned!: boolean | null;

}
