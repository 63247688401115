// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.LocationProductForOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class LocationProductForOrderResponse extends GandalfModelBase {

	@GandalfProperty()
	brandName!: string | null;

	@GandalfProperty()
	collectionName!: string | null;

	@GandalfConstantDecorator(constants.ContactLensType)
	@GandalfProperty()
	contactLensType!: constants.ContactLensType | null;

	@GandalfProperty()
	eyeGlassLensTypeId!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	invoiceDescription!: string | null;

	@GandalfProperty()
	manufacturerName!: string | null;

	@GandalfConstantDecorator(constants.MasterProductCategory)
	@GandalfProperty()
	masterProductCategory!: constants.MasterProductCategory | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	price!: number | null;

	@GandalfProperty()
	productCode!: string | null;

	@GandalfProperty()
	productId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	wholesalePrice!: number | null;

}
