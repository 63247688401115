// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.schedule.EncounterReportRequest */
/* istanbul ignore next */
@GandalfModel
export class EncounterReportRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	approvalEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	approvalStartDate!: Date | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty()
	approvalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfProperty()
	employeeId!: number | null;

	@GandalfProperty()
	encounterTypeId!: number | null;

	@GandalfValidator({ notNull: { message: 'Encounter End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	endDate!: Date;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty()
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Patient', maxLength: 1000, minLength: 0, message: 'Patient must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	patientName!: string | null;

	@GandalfProperty()
	providerId!: number | null;

	@GandalfValidator({ notNull: { message: 'Encounter Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate!: Date;

	@GandalfConstantDecorator(constants.EncounterSearchStatus)
	@GandalfProperty()
	status!: constants.EncounterSearchStatus | null;

	@GandalfProperty()
	subTypeId!: number | null;

}
