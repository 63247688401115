// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.family.FamilyAccountMemberDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class FamilyAccountMemberDetailResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	balance!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	credit!: number | null;

	@GandalfProperty()
	email!: string | null;

	@GandalfConstantDecorator(constants.EmergencyContactType)
	@GandalfProperty()
	emergencyContactType!: constants.EmergencyContactType | null;

	@GandalfProperty()
	headOfHousehold!: boolean | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isEmergencyContact!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	lastComprehensiveEncounterDate!: Date | null;

	@GandalfProperty()
	lastComprehensiveEncounterId!: number | null;

	@GandalfProperty()
	lastComprehensiveEncounterType!: string | null;

	@GandalfProperty()
	name!: PersonNameResponse | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	phone!: string | null;

	@GandalfProperty()
	role!: ReferenceDataSummaryResponse | null;

	@GandalfConstantDecorator(constants.PatientStatus)
	@GandalfProperty()
	status!: constants.PatientStatus | null;

}
