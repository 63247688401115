// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeFileInfoResponse } from './practice-file-info-response';

/** See com.rev360.pms.api.controller.document.ConsentFormResponse */
/* istanbul ignore next */
@GandalfModel
export class ConsentFormResponse extends GandalfModelBase {

	@GandalfProperty()
	allowElectronicClick!: boolean | null;

	@GandalfProperty()
	allowPaper!: boolean | null;

	@GandalfProperty()
	comments!: string | null;

	@GandalfConstantDecorator(constants.PracticeConsentFormExpirationType)
	@GandalfProperty()
	expirationType!: constants.PracticeConsentFormExpirationType | null;

	@GandalfProperty()
	expireDurationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	expireDurationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfConstantDecorator(constants.PracticeConsentFormRequired)
	@GandalfProperty()
	required!: constants.PracticeConsentFormRequired | null;

	@GandalfProperty()
	template!: PracticeFileInfoResponse | null;

	@GandalfConstantDecorator(constants.PracticeConsentFormType)
	@GandalfProperty()
	type!: constants.PracticeConsentFormType | null;

}
