// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.file.DrugAllergyImportResponse */
/* istanbul ignore next */
@GandalfModel
export class DrugAllergyImportResponse extends GandalfModelBase {

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	drugCode!: string | null;

	@GandalfProperty()
	drugName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	lastModificationDate!: Date | null;

	@GandalfArray(ReferenceDataResponse)
	reactions!: ReferenceDataResponse[];

	@GandalfProperty()
	snomedCodeCode!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.AllergyStatus)
	@GandalfProperty()
	status!: constants.AllergyStatus;

}
