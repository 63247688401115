// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { EyeglassLensMeasurementResponse } from './eyeglass-lens-measurement-response';

// @ts-ignore
import { EyeglassLensOrderDetailResponse } from './eyeglass-lens-order-detail-response';

// @ts-ignore
import { FrameColorResponse } from './frame-color-response';

// @ts-ignore
import { FrameSizeResponse } from './frame-size-response';

// @ts-ignore
import { LocationProductForOrderResponse } from './location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './location-product-item-for-order-response';

// @ts-ignore
import { OrderEyeglassPrescriptionResponse } from './order-eyeglass-prescription-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderProductOptionResponse } from './order-product-option-response';

// @ts-ignore
import { OrderResponse } from './order-response';

// @ts-ignore
import { OrderTwelve84Response } from './order-twelve84-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.EyeglassOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassOrderResponse extends OrderResponse {

	@GandalfProperty()
	arCoating!: OrderProductOptionResponse | null;

	@GandalfProperty()
	arCoatingSide!: string | null;

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty()
	createdOn!: Date | null;

	@GandalfProperty()
	density!: number | null;

	@GandalfProperty()
	distancePd!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate!: Date | null;

	@GandalfProperty()
	edgeTreatment!: OrderProductOptionResponse | null;

	@GandalfProperty()
	encounter!: EncounterTableResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedTaxes!: number | null;

	@GandalfProperty()
	externalFrameSupplierIdentifier!: string | null;

	@GandalfProperty()
	externalFrameSupplierName!: string | null;

	@GandalfProperty()
	externalSupplierIdentifier!: string | null;

	@GandalfProperty()
	externalSupplierName!: string | null;

	@GandalfProperty()
	frameColor!: FrameColorResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	frameCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	frameRemakeCost!: number | null;

	@GandalfProperty()
	frameSize!: FrameSizeResponse | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	lensCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost!: number | null;

	@GandalfConstantDecorator(constants.OrderLensRequest)
	@GandalfProperty()
	lensRequest!: constants.OrderLensRequest | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	material!: OrderProductOptionResponse | null;

	@GandalfProperty()
	mirrorCoating!: OrderProductOptionResponse | null;

	@GandalfProperty()
	mountingType!: OrderProductOptionResponse | null;

	@GandalfProperty()
	nearPd!: number | null;

	@GandalfProperty()
	notificationComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate!: Date | null;

	@GandalfProperty()
	odDetail!: EyeglassLensOrderDetailResponse | null;

	@GandalfProperty()
	odLensMeasurements!: EyeglassLensMeasurementResponse | null;

	@GandalfProperty()
	onHold!: boolean | null;

	@GandalfConstantDecorator(constants.OrderOpticalCenterType)
	@GandalfProperty()
	opticalCenterType!: constants.OrderOpticalCenterType | null;

	@GandalfConstantDecorator(constants.OrderFrameIndication)
	@GandalfProperty()
	orderFrameIndication!: constants.OrderFrameIndication | null;

	@GandalfProperty()
	orderFrameProduct!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	orderFrameProductItem!: LocationProductItemForOrderResponse | null;

	@GandalfConstantDecorator(constants.OrderFrameSource)
	@GandalfProperty()
	orderFrameSource!: constants.OrderFrameSource | null;

	@GandalfProperty()
	orderId!: number | null;

	@GandalfProperty()
	originalOrderId!: number | null;

	@GandalfConstantDecorator(constants.OriginalOrderReason)
	@GandalfProperty()
	originalOrderReason!: constants.OriginalOrderReason | null;

	@GandalfProperty()
	osDetail!: EyeglassLensOrderDetailResponse | null;

	@GandalfProperty()
	osLensMeasurements!: EyeglassLensMeasurementResponse | null;

	@GandalfProperty()
	otherCoating!: OrderProductOptionResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost!: number | null;

	@GandalfProperty()
	pantoscopicTilt!: number | null;

	@GandalfProperty()
	patient!: PatientNameResponse | null;

	@GandalfProperty()
	patientCommunication!: PatientCommunicationResponse | null;

	@GandalfProperty()
	patientNotified!: boolean | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfProperty()
	photochromic!: OrderProductOptionResponse | null;

	@GandalfProperty()
	polarized!: OrderProductOptionResponse | null;

	@GandalfProperty()
	prescription!: OrderEyeglassPrescriptionResponse | null;

	@GandalfProperty()
	processor!: OrderProcessorConfigResponse | null;

	@GandalfProperty()
	quantity!: number | null;

	@GandalfProperty()
	scratchCoating!: OrderProductOptionResponse | null;

	@GandalfConstantDecorator(constants.OrderSegHeightType)
	@GandalfProperty()
	segHeightType!: constants.OrderSegHeightType | null;

	@GandalfProperty()
	shade!: string | null;

	@GandalfProperty()
	shipAddress!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost!: number | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfProperty()
	shipToType!: constants.OrderShipToType | null;

	@GandalfConstantDecorator(constants.OrderShipmentCompany)
	@GandalfProperty()
	shippingCompany!: constants.OrderShipmentCompany | null;

	@GandalfProperty()
	smartflowOrderIdentifier!: number | null;

	@GandalfProperty()
	specialOption!: OrderProductOptionResponse | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty()
	statusCode!: constants.OrderStatusCode | null;

	@GandalfProperty()
	statusDate!: Date | null;

	@GandalfProperty()
	thickness!: OrderProductOptionResponse | null;

	@GandalfProperty()
	tintFactor!: OrderProductOptionResponse | null;

	@GandalfProperty()
	tintType!: OrderProductOptionResponse | null;

	@GandalfProperty()
	trackingNumber!: string | null;

	@GandalfProperty()
	tray!: string | null;

	@GandalfProperty()
	twelve84Response!: OrderTwelve84Response | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty()
	type!: constants.OrderType | null;

	@GandalfProperty()
	uvTreatment!: OrderProductOptionResponse | null;

	@GandalfProperty()
	vendor!: OrderVendorResponse | null;

	@GandalfProperty()
	vendorOrderIdentifier!: string | null;

	@GandalfProperty()
	version!: number | null;

	@GandalfProperty()
	wrapAngle!: number | null;

}
