// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.plan.EncounterCarePlanItemGeneralResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterCarePlanItemGeneralResponse extends GandalfModelBase {

	@GandalfProperty()
	carePlanItemGeneralId!: number | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	diagnosisCode!: string | null;

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfProperty()
	diagnosisCodeSet!: constants.CodeSet | null;

	@GandalfProperty()
	diagnosisShortDescription!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

}
