// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.general.location.AdminLocationOrderProcessingVisionwebRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminLocationOrderProcessingVisionwebRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'VisionWeb Password cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	password!: string | null;

	@GandalfValidator({ sizeString: { message: 'VisionWeb Login Name cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	username!: string | null;

}
