// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.assessmentandplan.PatientTriggerOutcomeResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientTriggerOutcomeResponse extends GandalfModelBase {

	@GandalfProperty()
	actionTaken!: string | null;

	@GandalfConstantDecorator(constants.CarePlanItemCategory)
	@GandalfProperty()
	carePlanItemCategory!: constants.CarePlanItemCategory | null;

	@GandalfProperty()
	clinicalExceptionComments!: string | null;

	@GandalfProperty()
	clinicalExceptionSnomedCodeDescription!: string | null;

	@GandalfConstantDecorator(constants.PatientTriggerOutcomeConfirmation)
	@GandalfProperty()
	confirmationStatus!: constants.PatientTriggerOutcomeConfirmation | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	feedback!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfConstantDecorator(constants.TriggerOutcomeType)
	@GandalfProperty()
	outcomeType!: constants.TriggerOutcomeType | null;

	@GandalfProperty()
	practiceFileId!: number | null;

	@GandalfProperty()
	queryTriggerQueryName!: string | null;

	@GandalfProperty()
	snomedCodeId!: number | null;

	@GandalfProperty()
	webSite!: string | null;

}
