// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FamilyHealthHistoryResponse } from './family-health-history-response';

// @ts-ignore
import { UserSummaryResponse } from './user-summary-response';

/** See com.rev360.pms.api.controller.encounter.history.familyhealthhistory.FamilyHealthHistoryReviewSetResponse */
/* istanbul ignore next */
@GandalfModel
export class FamilyHealthHistoryReviewSetResponse extends GandalfModelBase {

	@GandalfProperty()
	comments!: string | null;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryConditionType)
	@GandalfProperty()
	conditionType!: constants.FamilyHealthHistoryConditionType | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfArray(FamilyHealthHistoryResponse)
	familyHealthHistoryItems!: FamilyHealthHistoryResponse[] | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	reviewed!: boolean | null;

	@GandalfProperty()
	reviewedBy!: UserSummaryResponse | null;

	@GandalfProperty()
	reviewedOn!: Date | null;

	@GandalfProperty()
	updatedOn!: Date | null;

	@GandalfProperty()
	version!: number | null;

}
