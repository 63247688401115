// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.family.UpdateFamilyAccountMemberRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateFamilyAccountMemberRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Account Member is required' } })
	@GandalfProperty({ isRequired: true })
	familyAccountMemberId!: number;

	@GandalfValidator({ notNull: { message: 'Head of Household is required' } })
	@GandalfProperty({ isRequired: true })
	headOfHousehold!: boolean;

	@GandalfValidator({ notNull: { message: 'Role is required' } })
	@GandalfProperty({ isRequired: true })
	roleId!: number;

}
