// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.dataconfiguration.PracticeFamilyHealthHistoryFilterRequest */
/* istanbul ignore next */
@GandalfModel
export class PracticeFamilyHealthHistoryFilterRequest extends GandalfModelBase {

	@GandalfProperty()
	practiceFamilyHealthHistoryFilterId!: number | null;

	@GandalfValidator({ notNull: { message: 'SnomedCodeId is required' } })
	@GandalfProperty({ isRequired: true })
	snomedCodeId!: number;

	@GandalfValidator({ notNull: { message: 'SortOrder is required' } })
	@GandalfProperty({ isRequired: true })
	sortOrder!: number;

}
