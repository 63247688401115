// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.messaging.SaveMessageFileAsPatientFileRequest */
/* istanbul ignore next */
@GandalfModel
export class SaveMessageFileAsPatientFileRequest extends GandalfModelBase {

	@GandalfLabel('Description')
	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	description!: string;

	@GandalfLabel('Message File')
	@GandalfValidator({ notNull: { message: 'Message File is required' } })
	@GandalfProperty({ isRequired: true })
	messageFileId!: number;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

}
