// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.admin.MeaningfulUseReportResponse */
/* istanbul ignore next */
@GandalfModel
export class MeaningfulUseReportResponse extends GandalfModelBase {

	@GandalfProperty()
	allowExclude!: boolean | null;

	@GandalfProperty()
	denominator!: number | null;

	@GandalfProperty()
	displayOrder!: number | null;

	@GandalfProperty()
	exclusion!: boolean | null;

	@GandalfProperty()
	exclusionCount!: number | null;

	@GandalfProperty()
	exclusionLimit!: number | null;

	@GandalfProperty()
	measureName!: string | null;

	@GandalfConstantDecorator(constants.MeaningfulUseLogMeasureType)
	@GandalfProperty()
	measureType!: constants.MeaningfulUseLogMeasureType | null;

	@GandalfProperty()
	measureUrl!: string | null;

	@GandalfProperty()
	numerator!: number | null;

	@GandalfProperty()
	percent!: number | null;

	@GandalfProperty()
	percentRequired!: number | null;

	@GandalfProperty()
	performanceMultiplier!: number | null;

	@GandalfProperty()
	success!: boolean | null;

}
