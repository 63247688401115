// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.reasonforvisit.CreateOrUpdateHistoryDiagnosisCarePlanItemRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateOrUpdateHistoryDiagnosisCarePlanItemRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Care Plan Item is required' } })
	@GandalfProperty({ isRequired: true })
	carePlanItemId!: number;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isPrimary!: boolean | null;

	@GandalfValidator({ notNull: { message: 'Person Diagnosis is required' } })
	@GandalfProperty({ isRequired: true })
	personDiagnosisId!: number;

	@GandalfValidator({ notNull: { message: 'Reason For Visit is required' } })
	@GandalfProperty({ isRequired: true })
	reasonForVisitId!: number;

}
