// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

/** See com.rev360.pms.api.controller.patient.PatientEmploymentRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientEmploymentRequest extends GandalfModelBase {

	@GandalfSubRequestLabel('Employer Address')
	@GandalfLabel('Employer Address')
	@GandalfProperty()
	address!: AddressOptionalRequest | null;

	@GandalfValidator({ sizeString: { message: 'Employer cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	employerName!: string | null;

	@GandalfValidator({ sizeString: { message: 'Position cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	position!: string | null;

	@GandalfConstantDecorator(constants.PatientEmploymentStatus)
	@GandalfProperty()
	status!: constants.PatientEmploymentStatus | null;

}
