// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminCreatePracticeLocationDetailsRequest } from './admin-create-practice-location-details-request';

// @ts-ignore
import { AdminPracticeLocationDistributionCenterRequest } from './admin-practice-location-distribution-center-request';

// @ts-ignore
import { AdminPracticeLocationOrderProcessingRequest } from './admin-practice-location-order-processing-request';

// @ts-ignore
import { AdminPracticeLocationPhrRequest } from './admin-practice-location-phr-request';

// @ts-ignore
import { PracticeLocationTaxRequest } from './practice-location-tax-request';

// @ts-ignore
import { StringAdditionalPreferenceRequest } from './string-additional-preference-request';

// @ts-ignore
import { UpdateOfficeHoursWeeklyRequest } from './update-office-hours-weekly-request';

/** See com.rev360.pms.api.controller.admin.general.location.AdminCreatePracticeLocationRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminCreatePracticeLocationRequest extends GandalfModelBase {

	@GandalfArray(StringAdditionalPreferenceRequest)
	additionalPreferences!: StringAdditionalPreferenceRequest[] | null;

	@GandalfValidator({ notNull: { message: 'Details are required' } })
	@GandalfProperty({ isRequired: true })
	details!: AdminCreatePracticeLocationDetailsRequest;

	@GandalfValidator({ notNull: { message: 'Distribution Center Settings are required' } })
	@GandalfProperty({ isRequired: true })
	distributionCenter!: AdminPracticeLocationDistributionCenterRequest;

	@GandalfValidator({ notNull: { message: 'Office Hours are required' } })
	@GandalfProperty({ isRequired: true })
	officeHours!: UpdateOfficeHoursWeeklyRequest;

	@GandalfValidator({ notNull: { message: 'Order Processing Settings are required' } })
	@GandalfProperty({ isRequired: true })
	orderProcessing!: AdminPracticeLocationOrderProcessingRequest;

	@GandalfValidator({ notNull: { message: 'PHR Settings are required' } })
	@GandalfProperty({ isRequired: true })
	phr!: AdminPracticeLocationPhrRequest;

	@GandalfSubRequestLabel('Tax')
	@GandalfArray(PracticeLocationTaxRequest)
	taxes!: PracticeLocationTaxRequest[] | null;

}
