// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.implantabledevice.CreatePatientImplantableDeviceRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientImplantableDeviceRequest extends GandalfModelBase {

	@GandalfLabel('Location')
	@GandalfProperty()
	bodyLocationId!: number | null;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments!: string | null;

	@GandalfLabel('Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	installedDate!: Date | null;

	@GandalfLabel('Date Is Estimated')
	@GandalfValidator({ notNull: { message: 'Date Is Estimated is required' } })
	@GandalfProperty({ isRequired: true })
	isInstalledDateEstimated!: boolean;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfLabel('Surgeon')
	@GandalfProperty()
	surgeonId!: number | null;

	@GandalfLabel('UDI')
	@GandalfValidator({ notNull: { message: 'UDI is required' } })
	@GandalfValidator({ sizeString: { message: 'UDI must be between 1 and 87 characters', minLength: 1, maxLength: 87 } })
	@GandalfProperty({ isRequired: true })
	udi!: string;

}
