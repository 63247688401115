// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { BusinessPhonesResponse } from './business-phones-response';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationDetailsResponse extends GandalfModelBase {

	@GandalfProperty()
	address!: AddressResponse | null;

	@GandalfProperty()
	barcodeIdentifier!: string | null;

	@GandalfProperty()
	billingLocationClaimsId!: number | null;

	@GandalfProperty()
	billingLocationStatementsId!: number | null;

	@GandalfProperty()
	billingProviderName!: string | null;

	@GandalfConstantDecorator(constants.ClaimNpiEntity)
	@GandalfProperty()
	claimNpiEntity!: constants.ClaimNpiEntity | null;

	@GandalfProperty()
	coInsuranceTaxId!: number | null;

	@GandalfProperty()
	coPayTaxId!: number | null;

	@GandalfProperty()
	deductibleTaxId!: number | null;

	@GandalfProperty()
	defaultFinanceChargePlanId!: number | null;

	@GandalfProperty()
	email!: string | null;

	@GandalfProperty()
	facilityNumber!: string | null;

	@GandalfConstantDecorator(constants.FacilityType)
	@GandalfProperty()
	facilityType!: constants.FacilityType | null;

	@GandalfProperty()
	fein!: string | null;

	@GandalfProperty()
	logoPracticeFileId!: number | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty()
	npi!: string | null;

	@GandalfProperty()
	payToAddress!: AddressResponse | null;

	@GandalfProperty()
	phones!: BusinessPhonesResponse | null;

	@GandalfProperty()
	printNameAs!: string | null;

	@GandalfConstantDecorator(constants.BillingId)
	@GandalfProperty()
	providerBillingId!: constants.BillingId | null;

	@GandalfConstantDecorator(constants.ServiceLocationIndicator)
	@GandalfProperty()
	serviceLocationIndicator!: constants.ServiceLocationIndicator | null;

	@GandalfProperty()
	storeNumber!: string | null;

	@GandalfProperty()
	websiteUrl!: string | null;

}
