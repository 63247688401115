// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.PrescriptionSelectContactLensEyeResponse */
/* istanbul ignore next */
@GandalfModel
export class PrescriptionSelectContactLensEyeResponse extends GandalfModelBase {

	@GandalfProperty()
	addDesignation!: string | null;

	@GandalfProperty()
	addPower!: number | null;

	@GandalfProperty()
	axis!: number | null;

	@GandalfProperty()
	baseCurve!: number | null;

	@GandalfProperty()
	cylinder!: number | null;

	@GandalfProperty()
	diameter!: number | null;

	@GandalfProperty()
	locationProductName!: string | null;

	@GandalfProperty()
	monovision!: boolean | null;

	@GandalfProperty()
	sphere!: number | null;

}
