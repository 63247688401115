// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.UpdateInvoiceAppointmentRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateInvoiceAppointmentRequest extends GandalfModelBase {

	@GandalfLabel('Appointment')
	@GandalfValidator({ notNull: { message: 'Appointment is required' } })
	@GandalfProperty({ isRequired: true })
	appointmentId!: number;

	@GandalfLabel('Invoice')
	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId!: number;

}
