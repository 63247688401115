// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { BusinessPhonesResponse } from './business-phones-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.schedule.sidebardetails.PersonInsuranceDetailsResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonInsuranceDetailsResponse extends GandalfModelBase {

	@GandalfProperty()
	coInsurancePct!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	effectiveDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money' })
	familyDeductible!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	groupContactLensFittingCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	groupGeneralCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	groupMaterials!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	groupMedicalExamCoPay!: number | null;

	@GandalfProperty()
	groupNumber!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	groupRoutineExamCoPay!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	individualContactLensFittingCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	individualGeneralCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	individualMaterials!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	individualMedicalExamCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	individualRoutineExamCoPay!: number | null;

	@GandalfProperty()
	insuranceCompanyAddress!: AddressResponse | null;

	@GandalfProperty()
	insuranceCompanyName!: string | null;

	@GandalfProperty()
	insuranceCompanyPhones!: BusinessPhonesResponse | null;

	@GandalfProperty()
	insuranceCompanyWebSite!: string | null;

	@GandalfProperty()
	payerId!: string | null;

	@GandalfProperty()
	planName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	policyHolderDob!: Date | null;

	@GandalfProperty()
	policyHolderName!: PersonNameResponse | null;

	@GandalfProperty()
	policyNumber!: string | null;

	@GandalfConstantDecorator(constants.InsuranceCompanyStatus)
	@GandalfProperty()
	practiceInsuranceCompanyStatus!: constants.InsuranceCompanyStatus | null;

	@GandalfProperty()
	priority!: ReferenceDataResponse | null;

	@GandalfProperty()
	rxBinNumber!: string | null;

	@GandalfProperty()
	rxPcnNumber!: string | null;

	@GandalfConstantDecorator(constants.PersonInsuranceStatus)
	@GandalfProperty()
	status!: constants.PersonInsuranceStatus | null;

	@GandalfProperty()
	type!: ReferenceDataResponse | null;

}
