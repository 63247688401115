// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.framesdata.FramesDataSubscriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class FramesDataSubscriptionResponse extends GandalfModelBase {

	@GandalfProperty()
	billingPostalCode!: string | null;

	@GandalfProperty()
	framesDataExpireDate!: Date | null;

	@GandalfProperty()
	framesDataRenewalMessage!: string | null;

	@GandalfProperty()
	framesDataRenewalUrl!: string | null;

	@GandalfProperty()
	framesDataResponseMessage!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	internalContactEmployeeId!: number | null;

	@GandalfProperty()
	numberOfLocations!: number | null;

	@GandalfProperty()
	shippingPostalCode!: string | null;

	@GandalfConstantDecorator(constants.FramesDataSubscriptionStatus)
	@GandalfProperty()
	status!: constants.FramesDataSubscriptionStatus | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	updatedOn!: Date | null;

	@GandalfProperty()
	username!: string | null;

	@GandalfProperty()
	version!: number | null;

}
