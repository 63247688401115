// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.reporting.patient.PatientBirthdaySearchResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientBirthdaySearchResponse extends GandalfModelBase {

	@GandalfProperty()
	address!: AddressResponse | null;

	@GandalfProperty()
	dob!: Date | null;

	@GandalfProperty()
	emailAddress!: string | null;

	@GandalfProperty()
	firstName!: string | null;

	@GandalfProperty()
	lastName!: string | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	preferredPhone!: string | null;

}
