// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UpdateInvoiceItemResponsiblePersonRequest } from './update-invoice-item-responsible-person-request';

/** See com.rev360.pms.api.controller.accounting.UpdateInvoiceItemResponsiblePersonListRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateInvoiceItemResponsiblePersonListRequest extends GandalfModelBase {

	@GandalfLabel('Invoice')
	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId!: number;

	@GandalfLabel('Invoice Items')
	@GandalfValidator({ notNull: { message: 'Invoice Items is required' } })
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: '{jakarta.validation.constraints.Size.message}' } })
	@GandalfArray(UpdateInvoiceItemResponsiblePersonRequest)
	invoiceItems!: UpdateInvoiceItemResponsiblePersonRequest[];

}
