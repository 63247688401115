// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.updatepersoninsurance.UpdatePatientGeneralBenefitsRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientGeneralBenefitsRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	contactLensFittingBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensFittingCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	deductible!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay!: number | null;

	@GandalfProperty()
	hasContactLensFittingBenefit!: boolean | null;

	@GandalfProperty()
	hasRoutineExamBenefit!: boolean | null;

	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	routineExamBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay!: number | null;

}
