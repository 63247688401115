// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueSelectionResponse } from './field-value-selection-response';

// @ts-ignore
import { HistoryDiagnosisCarePlanItemResponse } from './history-diagnosis-care-plan-item-response';

/** See com.rev360.pms.api.controller.encounter.history.reasonforvisit.ReasonForVisitResponse */
/* istanbul ignore next */
@GandalfModel
export class ReasonForVisitResponse extends GandalfModelBase {

	@GandalfProperty()
	additionalReason!: FieldValueSelectionResponse | null;

	@GandalfArray(HistoryDiagnosisCarePlanItemResponse)
	diagnosisCarePlanItems!: HistoryDiagnosisCarePlanItemResponse[] | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isMedicalReasonForVisit!: boolean | null;

	@GandalfProperty()
	isTransitionOfCare!: boolean | null;

	@GandalfProperty()
	patientReasonForVisit!: string | null;

	@GandalfProperty()
	providerReasonForVisit!: string | null;

	@GandalfProperty()
	referringProviderId!: number | null;

	@GandalfConstantDecorator(constants.TocReceivedStatus)
	@GandalfProperty()
	tocReceivedStatus!: constants.TocReceivedStatus | null;

}
