// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.schedule.AppointmentReportResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentReportResponse extends GandalfModelBase {

	@GandalfProperty()
	appointmentConfirmationStatus!: boolean | null;

	@GandalfProperty()
	appointmentId!: number | null;

	@GandalfProperty()
	employeeFirstName!: string | null;

	@GandalfProperty()
	employeeLastName!: string | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty()
	encounterApprovalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty()
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfProperty()
	insuranceVerificationComment!: string | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty()
	interviewStatus!: constants.InterviewStatus | null;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfProperty()
	preferredPhoneNumber!: string | null;

	@GandalfProperty()
	providerFirstName!: string | null;

	@GandalfProperty()
	providerLastName!: string | null;

	@GandalfProperty()
	roleName!: string | null;

	@GandalfProperty()
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty()
	status!: constants.ScheduleItemStatus | null;

	@GandalfProperty()
	subTypeName!: string | null;

	@GandalfProperty()
	templateName!: string | null;

	@GandalfConstantDecorator(constants.VerificationStatus)
	@GandalfProperty()
	verificationStatus!: constants.VerificationStatus | null;

}
