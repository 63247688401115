// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { WorkflowTestRequest } from './workflow-test-request';

/** See com.rev360.pms.api.controller.encounter.dynamic.UpdateWorkflowTestsRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateWorkflowTestsRequest extends GandalfModelBase {

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	templateId!: number | null;

	@GandalfArray(WorkflowTestRequest)
	tests!: WorkflowTestRequest[] | null;

	@GandalfProperty()
	workflowScreenId!: number | null;

}
