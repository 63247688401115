// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.EClaimsSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class EClaimsSearchRequest extends GandalfModelBase {

	@GandalfLabel('Claim Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	claimDateEnd!: Date | null;

	@GandalfLabel('Claim Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	claimDateStart!: Date | null;

	@GandalfLabel('Claim ID')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Claim ID may not be more than 18 digits' } })
	@GandalfProperty()
	claimId!: number | null;

	@GandalfLabel('Insurance')
	@GandalfProperty()
	insuranceCompanyId!: number | null;

	@GandalfLabel('Invoice Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDateEnd!: Date | null;

	@GandalfLabel('Invoice Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDateStart!: Date | null;

	@GandalfLabel('Invoice ID')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Invoice ID may not be more than 18 digits' } })
	@GandalfProperty()
	invoiceId!: number | null;

	@GandalfLabel('Location')
	@GandalfProperty()
	locationId!: number | null;

	@GandalfLabel('Patient ID')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Patient ID may not be more than 18 digits' } })
	@GandalfProperty()
	patientId!: number | null;

	@GandalfLabel('Patient Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Patient Name', maxLength: 1000, minLength: 0, message: 'Patient Name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	patientName!: string | null;

	@GandalfLabel('Insurance')
	@GandalfArray(Number)
	practiceInsuranceCompanyIds!: number[] | null;

	@GandalfLabel('Service Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDateEnd!: Date | null;

	@GandalfLabel('Service Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDateStart!: Date | null;

	@GandalfConstantDecorator(constants.InsuranceClaimStatus)
	@GandalfLabel('Status')
	@GandalfProperty()
	status!: constants.InsuranceClaimStatus | null;

	@GandalfLabel('Status Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDateEnd!: Date | null;

	@GandalfLabel('Status Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDateStart!: Date | null;

	@GandalfLabel('Submit Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	submitDateEnd!: Date | null;

	@GandalfLabel('Submit Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	submitDateStart!: Date | null;

}
