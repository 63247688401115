// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { OrderProductOptionRequest } from './order-product-option-request';

// @ts-ignore
import { UpdateEyeglassLensMeasurementsRequest } from './update-eyeglass-lens-measurements-request';

// @ts-ignore
import { UpdateEyeglassLensOrderDetailRequest } from './update-eyeglass-lens-order-detail-request';

// @ts-ignore
import { UpdateOrderRequest } from './update-order-request';

/** See com.rev360.pms.api.controller.order.UpdateEyeglassOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEyeglassOrderRequest extends UpdateOrderRequest {

	@GandalfSubRequestLabel('AR Coating')
	@GandalfProperty()
	arCoating!: OrderProductOptionRequest | null;

	@GandalfValidator({ sizeString: { message: 'AR Coating Side cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	arCoatingSide!: string | null;

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comment!: string | null;

	@GandalfValidator({ min: { min: 0, message: 'Density % must be between 0 and 100' } })
	@GandalfValidator({ max: { max: 100, message: 'Density % must be between 0 and 100' } })
	@GandalfProperty()
	density!: number | null;

	@GandalfProperty()
	distancePd!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate!: Date | null;

	@GandalfSubRequestLabel('Edge Treatment')
	@GandalfProperty()
	edgeTreatment!: OrderProductOptionRequest | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfLabel('Estimate Discounts')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts!: number | null;

	@GandalfLabel('Estimated Insurance Benefit')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit!: number | null;

	@GandalfValidator({ sizeString: { message: 'Frame Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalFrameSupplierIdentifier!: string | null;

	@GandalfValidator({ sizeString: { message: 'Frame Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalFrameSupplierName!: string | null;

	@GandalfValidator({ sizeString: { message: 'Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalSupplierIdentifier!: string | null;

	@GandalfValidator({ sizeString: { message: 'Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalSupplierName!: string | null;

	@GandalfProperty()
	frameColorId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	frameCost!: number | null;

	@GandalfProperty()
	frameId!: number | null;

	@GandalfProperty()
	frameItemId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	frameRemakeCost!: number | null;

	@GandalfProperty()
	frameSizeId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	lensCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost!: number | null;

	@GandalfConstantDecorator(constants.OrderLensRequest)
	@GandalfValidator({ notNull: { message: 'Lens Request is required' } })
	@GandalfProperty({ isRequired: true })
	lensRequest!: constants.OrderLensRequest;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId!: number;

	@GandalfSubRequestLabel('Material')
	@GandalfProperty()
	material!: OrderProductOptionRequest | null;

	@GandalfSubRequestLabel('Mirror Coating')
	@GandalfProperty()
	mirrorCoating!: OrderProductOptionRequest | null;

	@GandalfSubRequestLabel('Mounting Type')
	@GandalfProperty()
	mountingType!: OrderProductOptionRequest | null;

	@GandalfProperty()
	nearPd!: number | null;

	@GandalfValidator({ sizeString: { message: 'Notification Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	notificationComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate!: Date | null;

	@GandalfSubRequestLabel('OD')
	@GandalfValidator({ notNull: { message: 'OD details are required' } })
	@GandalfProperty({ isRequired: true })
	odLensDetail!: UpdateEyeglassLensOrderDetailRequest;

	@GandalfSubRequestLabel('OD')
	@GandalfValidator({ notNull: { message: 'OD measurements are required' } })
	@GandalfProperty({ isRequired: true })
	odLensMeasurements!: UpdateEyeglassLensMeasurementsRequest;

	@GandalfConstantDecorator(constants.OrderOpticalCenterType)
	@GandalfProperty()
	opticalCenterType!: constants.OrderOpticalCenterType | null;

	@GandalfConstantDecorator(constants.OrderFrameIndication)
	@GandalfValidator({ notNull: { message: 'Frame Indicator is required' } })
	@GandalfProperty({ isRequired: true })
	orderFrameIndication!: constants.OrderFrameIndication;

	@GandalfConstantDecorator(constants.OrderFrameSource)
	@GandalfValidator({ notNull: { message: 'Frame Source is required' } })
	@GandalfProperty({ isRequired: true })
	orderFrameSource!: constants.OrderFrameSource;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfProperty({ isRequired: true })
	orderId!: number;

	@GandalfSubRequestLabel('OS')
	@GandalfValidator({ notNull: { message: 'OS details are required' } })
	@GandalfProperty({ isRequired: true })
	osLensDetail!: UpdateEyeglassLensOrderDetailRequest;

	@GandalfSubRequestLabel('OS')
	@GandalfValidator({ notNull: { message: 'OS measurements are required' } })
	@GandalfProperty({ isRequired: true })
	osLensMeasurements!: UpdateEyeglassLensMeasurementsRequest;

	@GandalfSubRequestLabel('Other Coating')
	@GandalfProperty()
	otherCoating!: OrderProductOptionRequest | null;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost!: number | null;

	@GandalfProperty()
	pantoscopicTilt!: number | null;

	@GandalfValidator({ notNull: { message: 'Patient Notified is required' } })
	@GandalfProperty({ isRequired: true })
	patientNotified!: boolean;

	@GandalfSubRequestLabel('Photochromic')
	@GandalfProperty()
	photochromic!: OrderProductOptionRequest | null;

	@GandalfSubRequestLabel('Polarized')
	@GandalfProperty()
	polarized!: OrderProductOptionRequest | null;

	@GandalfProperty()
	prescriptionId!: number | null;

	@GandalfProperty()
	processorId!: number | null;

	@GandalfValidator({ notNull: { message: 'Quantity is required' } })
	@GandalfProperty({ isRequired: true })
	quantity!: number;

	@GandalfSubRequestLabel('Scratch Coating')
	@GandalfProperty()
	scratchCoating!: OrderProductOptionRequest | null;

	@GandalfConstantDecorator(constants.OrderSegHeightType)
	@GandalfProperty()
	segHeightType!: constants.OrderSegHeightType | null;

	@GandalfValidator({ sizeString: { message: 'Shade cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	shade!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost!: number | null;

	@GandalfProperty()
	shipToOtherAddress!: AddressOptionalRequest | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfValidator({ notNull: { message: 'Ship To is required' } })
	@GandalfProperty({ isRequired: true })
	shipToType!: constants.OrderShipToType;

	@GandalfConstantDecorator(constants.OrderShipmentCompany)
	@GandalfProperty()
	shippingCompany!: constants.OrderShipmentCompany | null;

	@GandalfSubRequestLabel('Special Option')
	@GandalfProperty()
	specialOption!: OrderProductOptionRequest | null;

	@GandalfSubRequestLabel('Thickness')
	@GandalfProperty()
	thickness!: OrderProductOptionRequest | null;

	@GandalfSubRequestLabel('Tint Factor')
	@GandalfProperty()
	tintFactor!: OrderProductOptionRequest | null;

	@GandalfSubRequestLabel('Tint Type')
	@GandalfProperty()
	tintType!: OrderProductOptionRequest | null;

	@GandalfValidator({ sizeString: { message: 'Tracking # cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	trackingNumber!: string | null;

	@GandalfValidator({ sizeString: { message: 'Tray cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	tray!: string | null;

	@GandalfSubRequestLabel('UV Treatment')
	@GandalfProperty()
	uvTreatment!: OrderProductOptionRequest | null;

	@GandalfProperty()
	vendorId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Vendor Order # cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	vendorOrderIdentifier!: string | null;

	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version!: number;

	@GandalfProperty()
	wrapAngle!: number | null;

}
