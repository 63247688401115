// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.amendment.AmendmentResponse */
/* istanbul ignore next */
@GandalfModel
export class AmendmentResponse extends GandalfModelBase {

	@GandalfProperty()
	amendmentId!: number | null;

	@GandalfConstantDecorator(constants.AmendmentSource)
	@GandalfProperty()
	amendmentSource!: constants.AmendmentSource | null;

	@GandalfProperty()
	amendmentSourceText!: string | null;

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty({ propertyType: 'LocalDateTime' })
	requestDate!: Date | null;

	@GandalfConstantDecorator(constants.AmendmentStatus)
	@GandalfProperty()
	status!: constants.AmendmentStatus | null;

	@GandalfProperty({ propertyType: 'LocalDateTime' })
	statusChangeDate!: Date | null;

}
