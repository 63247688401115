// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.CreateRecurPatternWeeklyRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateRecurPatternWeeklyRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Weekly amount must be filled in' } })
	@GandalfValidator({ min: { min: 0, message: 'Weekly amount cannot be under 0' } })
	@GandalfValidator({ max: { max: 52, message: 'Weekly amount cannot be over 52' } })
	@GandalfProperty({ isRequired: true })
	weeklyAmount!: number;

	@GandalfProperty()
	weeklyFriday!: boolean | null;

	@GandalfProperty()
	weeklyMonday!: boolean | null;

	@GandalfProperty()
	weeklySaturday!: boolean | null;

	@GandalfProperty()
	weeklySunday!: boolean | null;

	@GandalfProperty()
	weeklyThursday!: boolean | null;

	@GandalfProperty()
	weeklyTuesday!: boolean | null;

	@GandalfProperty()
	weeklyWednesday!: boolean | null;

}
