// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.ScheduleItemPrintResponse */
/* istanbul ignore next */
@GandalfModel
export class ScheduleItemPrintResponse extends GandalfModelBase {

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	encounterTemplateName!: string | null;

	@GandalfArray(String)
	encounterTemplateNames!: string[] | null;

	@GandalfProperty()
	encounterTemplateSubCategory!: string | null;

	@GandalfProperty()
	endDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	patientDob!: Date | null;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfProperty()
	patientPreferredPhone!: string | null;

	@GandalfConstantDecorator(constants.PreferredPhoneType)
	@GandalfProperty()
	patientPreferredPhoneType!: constants.PreferredPhoneType | null;

	@GandalfProperty()
	relatedScheduleItemId!: number | null;

	@GandalfProperty()
	roleName!: string | null;

	@GandalfProperty()
	scheduleItemId!: number | null;

	@GandalfProperty()
	scheduleItemPerson!: PersonNameResponse | null;

	@GandalfProperty()
	scheduleItemPersonCredentials!: string | null;

	@GandalfConstantDecorator(constants.ScheduleItemType)
	@GandalfProperty()
	scheduleItemType!: constants.ScheduleItemType | null;

	@GandalfProperty()
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty()
	status!: constants.ScheduleItemStatus | null;

	@GandalfProperty()
	summary!: string | null;

}
