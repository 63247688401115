// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.MarkClaimAsSubmittedRequest */
/* istanbul ignore next */
@GandalfModel
export class MarkClaimAsSubmittedRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Claims are required' } })
	@GandalfArray(Number)
	claimIds!: number[];

	@GandalfValidator({ notNull: { message: 'Claim Submit Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	submissionDate!: Date;

}
