// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.practicepreferences.UpdateRevClearConfigurationRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateRevClearConfigurationRequest extends GandalfModelBase {

	@GandalfLabel('Auto-Posting Default Payment Location')
	@GandalfProperty()
	defaultPaymentPracticeLocationId!: number | null;

	@GandalfLabel('Persons')
	@GandalfArray(Number)
	personIds!: number[] | null;

}
