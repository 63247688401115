// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.RefillEyeglassPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class RefillEyeglassPrescriptionRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Authorize Prescription indicator is required' } })
	@GandalfProperty({ isRequired: true })
	authorizePrescription!: boolean;

	@GandalfLabel('Encounter')
	@GandalfProperty()
	encounterId!: number | null;

	@GandalfLabel('Expiration Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate!: Date | null;

	@GandalfLabel('Prescription')
	@GandalfValidator({ notNull: { message: 'Prescription is required' } })
	@GandalfProperty({ isRequired: true })
	eyeglassPrescriptionId!: number;

	@GandalfLabel('Instructions')
	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions!: string | null;

	@GandalfLabel('Start Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfLabel('Use Provider Signature')
	@GandalfValidator({ notNull: { message: 'Use Provider Signature is required' } })
	@GandalfProperty({ isRequired: true })
	useProviderSignature!: boolean;

}
