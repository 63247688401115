// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodPersonInsuranceResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodPersonInsuranceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	effectiveDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money' })
	fittingContactLensCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay!: number | null;

	@GandalfProperty()
	groupNumber!: string | null;

	@GandalfProperty()
	insuranceCompanyAddress!: AddressResponse | null;

	@GandalfProperty()
	insuranceCompanyFaxNumber!: string | null;

	@GandalfProperty()
	insuranceCompanyName!: string | null;

	@GandalfProperty()
	insuranceCompanyPhoneNumber!: string | null;

	@GandalfConstantDecorator(constants.InsuranceCompanyStatus)
	@GandalfProperty()
	insuranceCompanyStatus!: constants.InsuranceCompanyStatus | null;

	@GandalfProperty()
	insurancePriority!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	insuranceType!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	insuranceWebsite!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay!: number | null;

	@GandalfProperty()
	personInsuranceId!: number | null;

	@GandalfConstantDecorator(constants.PersonInsuranceStatus)
	@GandalfProperty()
	personInsuranceStatus!: constants.PersonInsuranceStatus | null;

	@GandalfProperty()
	planName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	policyHolderDob!: Date | null;

	@GandalfProperty()
	policyHolderName!: PersonNameResponse | null;

	@GandalfProperty()
	policyNumber!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay!: number | null;

}
