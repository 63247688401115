// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateCarePlanItemTemplateRequest } from './create-care-plan-item-template-request';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.CreateCarePlanItemTemplateLabOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateCarePlanItemTemplateLabOrderRequest extends CreateCarePlanItemTemplateRequest {

	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 4000 characters', minLength: 1, maxLength: 4000 } })
	@GandalfProperty({ isRequired: true })
	description!: string;

	@GandalfConstantDecorator(constants.CarePlanItemLabOrderDuration)
	@GandalfValidator({ notNull: { message: 'End Range Unit is required' } })
	@GandalfProperty({ isRequired: true })
	endDateRangeUnit!: constants.CarePlanItemLabOrderDuration;

	@GandalfValidator({ notNull: { message: 'End Range Value is required' } })
	@GandalfProperty({ isRequired: true })
	endDateRangeValue!: number;

	@GandalfValidator({ notNull: { message: 'Checkout Task is required' } })
	@GandalfProperty({ isRequired: true })
	isCheckoutTask!: boolean;

	@GandalfConstantDecorator(constants.LabOrderLabType)
	@GandalfValidator({ notNull: { message: 'Lab Type is required' } })
	@GandalfProperty({ isRequired: true })
	labType!: constants.LabOrderLabType;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfValidator({ sizeString: { message: 'Order must be between 1 and 8 characters', minLength: 1, maxLength: 8 } })
	@GandalfProperty({ isRequired: true })
	loincNum!: string;

	@GandalfConstantDecorator(constants.LabOrderResultType)
	@GandalfValidator({ notNull: { message: 'Result Type is required' } })
	@GandalfProperty({ isRequired: true })
	resultType!: constants.LabOrderResultType;

	@GandalfConstantDecorator(constants.CarePlanItemLabOrderDuration)
	@GandalfValidator({ notNull: { message: 'Start Range Unit is required' } })
	@GandalfProperty({ isRequired: true })
	startDateRangeUnit!: constants.CarePlanItemLabOrderDuration;

	@GandalfValidator({ notNull: { message: 'Start Range Value is required' } })
	@GandalfProperty({ isRequired: true })
	startDateRangeValue!: number;

}
