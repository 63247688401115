// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.incorporate.IncorporateDrugAllergyRequest */
/* istanbul ignore next */
@GandalfModel
export class IncorporateDrugAllergyRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	allergyDescription!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfArray(Number)
	reactionIds!: number[] | null;

	@GandalfValidator({ sizeString: { message: 'RxNorm ConceptId cannot be longer than 8 characters', minLength: 0, maxLength: 8 } })
	@GandalfProperty()
	rxNormConceptId!: string | null;

	@GandalfProperty()
	snomedCodeCode!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

}
