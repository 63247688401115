// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.preferences.PatientSchedulingPreferenceResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientSchedulingPreferenceResponse extends GandalfModelBase {

	@GandalfProperty()
	allowPhrScheduling!: boolean | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	friday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfProperty()
	generalNote!: string | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	monday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	saturday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesScheduleRisk)
	@GandalfProperty()
	scheduleRisk!: constants.SchedulingPreferencesScheduleRisk | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	sunday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	thursday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	tuesday!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	wednesday!: constants.SchedulingPreferencesTimeOfDay | null;

}
