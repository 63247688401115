// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.dataconfiguration.medication.PracticeDrugResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeDrugResponse extends GandalfModelBase {

	@GandalfProperty()
	dispensingInfoAmount!: number | null;

	@GandalfProperty()
	dispensingInfoUnitId!: number | null;

	@GandalfProperty()
	doNotSubstitute!: boolean | null;

	@GandalfProperty()
	dosageAmount!: number | null;

	@GandalfProperty()
	dosageUnitId!: number | null;

	@GandalfProperty()
	drugName!: string | null;

	@GandalfProperty()
	durationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	frequencyId!: number | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty()
	numRefillsAllowed!: number | null;

	@GandalfProperty()
	practiceDrugId!: number | null;

	@GandalfProperty()
	routeId!: number | null;

}
