// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateRecurringDefinitionRequest } from './create-recurring-definition-request';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.CreateAppointmentSlotRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateAppointmentSlotRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Allow PHR Scheduling is required' } })
	@GandalfProperty({ isRequired: true })
	allowPhrScheduling!: boolean;

	@GandalfProperty()
	displayColor!: number | null;

	@GandalfProperty()
	employeeId!: number | null;

	@GandalfArray(Number)
	encounterTemplateIds!: number[] | null;

	@GandalfValidator({ notNull: { message: 'End Time is required' } })
	@GandalfProperty({ isRequired: true })
	endDate!: Date;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId!: number;

	@GandalfProperty()
	recurringDefinition!: CreateRecurringDefinitionRequest | null;

	@GandalfProperty()
	roleId!: number | null;

	@GandalfValidator({ notNull: { message: 'Start Time is required' } })
	@GandalfProperty({ isRequired: true })
	startDate!: Date;

}
