// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.general.location.AdminLocationPhoneRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminLocationPhoneRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Emergency Phone cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Phone must contain at least 10 digits' } })
	@GandalfProperty()
	emergencyPhone!: string | null;

	@GandalfValidator({ sizeString: { message: 'Fax cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Phone must contain at least 10 digits' } })
	@GandalfProperty()
	fax!: string | null;

	@GandalfValidator({ sizeString: { message: 'Main Phone cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfValidator({ phoneNumber: { message: 'Phone must contain at least 10 digits' } })
	@GandalfProperty()
	mainPhone!: string | null;

}
