// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.patient.implantabledevice.PatientImplantableDeviceResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientImplantableDeviceResponse extends GandalfModelBase {

	@GandalfProperty()
	bodyLocation!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty()
	implantableDeviceId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	installedDate!: Date | null;

	@GandalfProperty()
	isInstalledDateEstimated!: boolean | null;

	@GandalfConstantDecorator(constants.ImplantableDeviceStatus)
	@GandalfProperty()
	status!: constants.ImplantableDeviceStatus | null;

	@GandalfProperty()
	surgeon!: ProviderResponse | null;

	@GandalfProperty()
	udi!: string | null;

}
