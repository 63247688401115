// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.immunization.PatientVaccinationRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientVaccinationRequest extends GandalfModelBase {

	@GandalfLabel('Administered Amount')
	@GandalfProperty()
	administeredAmount!: number | null;

	@GandalfLabel('Administered Amount Unit')
	@GandalfProperty()
	administeredAmountUnitId!: number | null;

	@GandalfLabel('Administered At')
	@GandalfProperty()
	administeredAtLocationId!: number | null;

	@GandalfLabel('Administered By')
	@GandalfProperty()
	administeredByEmployeeId!: number | null;

	@GandalfLabel('Administered On')
	@GandalfProperty()
	administeredOn!: Date | null;

	@GandalfLabel('Administered Route')
	@GandalfProperty()
	administeredRouteId!: number | null;

	@GandalfLabel('Administered Site')
	@GandalfProperty()
	administeredSiteId!: number | null;

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments!: string | null;

	@GandalfLabel('CVX Code')
	@GandalfValidator({ notNull: { message: 'CVX Code is required' } })
	@GandalfValidator({ sizeString: { min: 1, max: 20, message: 'CVX Code must be between 1 and 20 characters' } })
	@GandalfProperty({ isRequired: true })
	cvxDetailCode!: string;

	@GandalfLabel('End Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfLabel('Not Given')
	@GandalfProperty()
	notGiven!: boolean | null;

	@GandalfLabel('Reason')
	@GandalfProperty()
	notGivenReasonId!: number | null;

	@GandalfLabel('Ordered By')
	@GandalfProperty()
	orderedByProviderId!: number | null;

	@GandalfLabel('Substance Expiration Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	substanceExpireDate!: Date | null;

	@GandalfLabel('Substance Lot')
	@GandalfValidator({ sizeString: { message: 'Substance Lot cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	substanceLot!: string | null;

	@GandalfLabel('Substance Manufacturer')
	@GandalfValidator({ sizeString: { min: 1, max: 255, message: 'Substance Manufacturer must be between 1 and 255 characters' } })
	@GandalfProperty()
	substanceManufacturerId!: string | null;

}
