// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.PatientPaymentResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPaymentResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount!: number;

	@GandalfConstantDecorator(constants.PaymentMethodCreditCardType)
	@GandalfProperty()
	card!: constants.PaymentMethodCreditCardType | null;

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty()
	invoiceId!: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	paymentDate!: Date;

	@GandalfProperty()
	paymentGroupId!: number | null;

	@GandalfProperty()
	paymentId!: number;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty()
	paymentMethodType!: constants.PaymentMethodType;

	@GandalfProperty()
	paymentPayorName!: string | null;

	@GandalfProperty()
	referenceNumber!: string | null;

	@GandalfConstantDecorator(constants.PaymentStatus)
	@GandalfProperty()
	status!: constants.PaymentStatus;

}
