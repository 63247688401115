// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InterviewResponse } from './interview-response';

// @ts-ignore
import { PatientFileInfoResponse } from './patient-file-info-response';

/** See com.rev360.pms.api.controller.encounter.interview.InterviewHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class InterviewHistoryResponse extends InterviewResponse {

	@GandalfProperty()
	appointmentEncounterTemplateName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	appointmentStartDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate!: Date | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty()
	encounterType!: string | null;

	@GandalfProperty()
	intakeId!: number | null;

	@GandalfProperty()
	intakePatientFile!: PatientFileInfoResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	interviewDate!: Date | null;

	@GandalfProperty()
	interviewId!: number | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty()
	interviewStatus!: constants.InterviewStatus | null;

}
