// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.family.AddFamilyMemberWithEmergencyContactRequest */
/* istanbul ignore next */
@GandalfModel
export class AddFamilyMemberWithEmergencyContactRequest extends GandalfModelBase {

	@GandalfLabel('Family patient')
	@GandalfValidator({ notNull: { message: 'Family patient is required' } })
	@GandalfProperty({ isRequired: true })
	familyPatientId!: number;

	@GandalfLabel('Head of Household')
	@GandalfValidator({ notNull: { message: 'Head of Household is required' } })
	@GandalfProperty({ isRequired: true })
	headOfHousehold!: boolean;

	@GandalfLabel('Emergency Contact')
	@GandalfValidator({ notNull: { message: 'Emergency Contact is required' } })
	@GandalfProperty({ isRequired: true })
	isEmergencyContact!: boolean;

	@GandalfLabel('Primary Emergency Contact')
	@GandalfValidator({ notNull: { message: 'Primary Emergency Contact is required' } })
	@GandalfProperty({ isRequired: true })
	isPrimaryEmergencyContact!: boolean;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientIdToAdd!: number;

	@GandalfLabel('Role')
	@GandalfValidator({ notNull: { message: 'Role is required' } })
	@GandalfProperty({ isRequired: true })
	roleId!: number;

}
