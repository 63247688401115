// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.plan.EncounterAddCarePlanTemplateToPersonDiagnosesRequest */
/* istanbul ignore next */
@GandalfModel
export class EncounterAddCarePlanTemplateToPersonDiagnosesRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Care Plan Template is required' } })
	@GandalfProperty({ isRequired: true })
	carePlanTemplateId!: number;

	@GandalfValidator({ notNull: { message: 'Diagnoses are required' } })
	@GandalfArray(Number)
	personDiagnosisIds!: number[];

}
