// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterDeviceResponse } from './master-device-response';

// @ts-ignore
import { PracticeLocationDeviceResponse } from './practice-location-device-response';

/** See com.rev360.pms.api.controller.shared.device.PracticeLocationFileDeviceResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeLocationFileDeviceResponse extends PracticeLocationDeviceResponse {

	@GandalfProperty()
	directoryPath!: string | null;

	@GandalfProperty()
	disablePreview!: boolean;

	@GandalfProperty()
	displayName!: string;

	@GandalfProperty()
	masterDevice!: MasterDeviceResponse;

	@GandalfProperty()
	practiceLocationDeviceId!: number;

}
