// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateMessageParticipantRequest } from './create-message-participant-request';

/** See com.rev360.pms.api.controller.messaging.CreateMessageRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateMessageRequest extends GandalfModelBase {

	@GandalfLabel('Message Content')
	@GandalfValidator({ sizeString: { message: 'Message Content cannot be longer than 65535 characters', minLength: 0, maxLength: 65535 } })
	@GandalfProperty()
	messageContent!: string | null;

	@GandalfLabel('Parent Message')
	@GandalfProperty()
	parentMessageId!: number | null;

	@GandalfLabel('Participants')
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Participants are required' } })
	@GandalfArray(CreateMessageParticipantRequest)
	participants!: CreateMessageParticipantRequest[];

	@GandalfConstantDecorator(constants.MessagePriority)
	@GandalfLabel('Priority')
	@GandalfValidator({ notNull: { message: 'Priority is required' } })
	@GandalfProperty({ isRequired: true })
	priority!: constants.MessagePriority;

	@GandalfLabel('Root Message')
	@GandalfProperty()
	rootMessageId!: number | null;

	@GandalfLabel('Subject')
	@GandalfValidator({ notNull: { message: 'Subject is required' } })
	@GandalfValidator({ sizeString: { message: 'Subject must be between 1 and 78 characters', minLength: 1, maxLength: 78 } })
	@GandalfProperty({ isRequired: true })
	subject!: string;

}
