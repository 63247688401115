// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.PracticeInsuranceCompanyResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeInsuranceCompanyResponse extends GandalfModelBase {

	@GandalfProperty()
	abbr!: string | null;

	@GandalfProperty()
	address!: AddressResponse | null;

	@GandalfProperty()
	allowItemDiscounts!: boolean | null;

	@GandalfProperty()
	chargedTax!: boolean | null;

	@GandalfConstantDecorator(constants.ClaimFilingCode)
	@GandalfProperty()
	claimFilingCode!: constants.ClaimFilingCode | null;

	@GandalfConstantDecorator(constants.ClaimSubmitMechanism)
	@GandalfProperty()
	claimSubmissionMechanism!: constants.ClaimSubmitMechanism | null;

	@GandalfProperty({ propertyType: 'Money' })
	credit!: number | null;

	@GandalfProperty()
	defaultInsuranceTypeReferenceId!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty()
	defaultPaymentMethod!: constants.PaymentMethodType | null;

	@GandalfProperty()
	defaultPriorityReferenceId!: number | null;

	@GandalfProperty()
	fax!: string | null;

	@GandalfProperty()
	groupNumber!: string | null;

	@GandalfConstantDecorator(constants.GroupNumberQualifier)
	@GandalfProperty()
	groupNumberQualifier!: constants.GroupNumberQualifier | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	includePqrsAlert!: boolean | null;

	@GandalfProperty()
	insuranceClassMasterReferenceId!: number | null;

	@GandalfProperty()
	mainPhone!: string | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty()
	notes!: string | null;

	@GandalfProperty()
	npiOnly!: boolean | null;

	@GandalfProperty()
	participatingPractice!: boolean | null;

	@GandalfProperty()
	payerId!: string | null;

	@GandalfProperty()
	removeMatchingServiceNpi!: boolean | null;

	@GandalfProperty()
	renderingIsReferringProvider!: boolean | null;

	@GandalfProperty()
	transferTax!: boolean | null;

	@GandalfProperty()
	webSite!: string | null;

}
