// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.updatepersoninsurance.UpdatePatientInsuranceBasicInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePatientInsuranceBasicInformationRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ isRequired: true })
	insuranceTypeId!: number;

	@GandalfConstantDecorator(constants.PolicyHolderRelationship)
	@GandalfProperty()
	policyHolderRelationship!: constants.PolicyHolderRelationship | null;

	@GandalfValidator({ notNull: { message: 'Policy Number is required' } })
	@GandalfValidator({ sizeString: { message: 'Policy Number must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	policyNumber!: string;

	@GandalfValidator({ notNull: { message: 'Priority is required' } })
	@GandalfProperty({ isRequired: true })
	priorityId!: number;

	@GandalfProperty()
	referralRequired!: boolean | null;

}
