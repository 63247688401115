// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.note.CreateNoteRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateNoteRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.EntityType)
	@GandalfValidator({ notNull: { message: 'Entity Type is required' } })
	@GandalfProperty({ isRequired: true })
	entityType!: constants.EntityType;

	@GandalfValidator({ notNull: { message: 'Parent Id is required' } })
	@GandalfProperty({ isRequired: true })
	parentId!: number;

	@GandalfLabel('Description')
	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 8000 characters', minLength: 1, maxLength: 8000 } })
	@GandalfProperty({ isRequired: true })
	text!: string;

}
