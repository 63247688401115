// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceDashboardResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceDashboardResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentTotal!: number;

	@GandalfProperty()
	approval!: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	balance!: number;

	@GandalfProperty()
	id!: number;

	@GandalfProperty()
	invoiceAge!: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDate!: Date;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfProperty()
	payerName!: string;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType;

	@GandalfProperty()
	processingPaymentGroupId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statementDate!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceStatus)
	@GandalfProperty()
	status!: constants.InvoiceStatus;

	@GandalfProperty({ propertyType: 'Money' })
	total!: number;

}
