// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.intervention.PredictiveInterventionAttributesResponse */
/* istanbul ignore next */
@GandalfModel
export class PredictiveInterventionAttributesResponse extends GandalfModelBase {

	@GandalfProperty()
	approach: string;

	@GandalfProperty()
	bias: string;

	@GandalfProperty()
	cautioned: string;

	@GandalfProperty()
	criteria: string;

	@GandalfProperty()
	developer: string;

	@GandalfProperty()
	externalDataSource: string;

	@GandalfProperty()
	externalFairness: string;

	@GandalfProperty()
	externalParty: string;

	@GandalfProperty()
	externalRepresentativeness: string;

	@GandalfProperty()
	externalValidation: string;

	@GandalfProperty()
	externalValidity: string;

	@GandalfProperty()
	fairnessMonitoring: string;

	@GandalfProperty()
	funding: string;

	@GandalfProperty()
	localFairness: string;

	@GandalfProperty()
	localValidity: string;

	@GandalfProperty()
	name: string;

	@GandalfProperty()
	output: string;

	@GandalfProperty()
	performanceCorrection: string;

	@GandalfProperty()
	populations: string;

	@GandalfProperty()
	predictiveInterventionId: number;

	@GandalfProperty()
	references: string;

	@GandalfProperty()
	relevance: string;

	@GandalfProperty()
	representativeness: string;

	@GandalfProperty()
	risks: string;

	@GandalfProperty()
	role: string;

	@GandalfProperty()
	testFairness: string;

	@GandalfProperty()
	testValidity: string;

	@GandalfProperty()
	type: string;

	@GandalfProperty()
	updateProcess: string;

	@GandalfProperty()
	use: string;

	@GandalfProperty()
	users: string;

	@GandalfProperty()
	validityMonitoring: string;

	@GandalfProperty()
	variables: string;

}
