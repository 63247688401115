// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.revdirect.RevDirectResponse */
/* istanbul ignore next */
@GandalfModel
export class RevDirectResponse extends GandalfModelBase {

	@GandalfProperty()
	directAddressId!: number | null;

	@GandalfProperty()
	practiceLocationId!: number | null;

	@GandalfProperty()
	practiceLocationName!: string | null;

	@GandalfProperty()
	revDirectAddress!: string | null;

	@GandalfConstantDecorator(constants.DirectAddressStatus)
	@GandalfProperty()
	status!: constants.DirectAddressStatus | null;

}
