// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.familyhealthhistory.CreateFamilyHealthHistoryRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateFamilyHealthHistoryRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Active is required' } })
	@GandalfProperty({ isRequired: true })
	active!: boolean;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments!: string | null;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryConditionType)
	@GandalfValidator({ notNull: { message: 'Condition Type is required' } })
	@GandalfProperty({ isRequired: true })
	conditionType!: constants.FamilyHealthHistoryConditionType;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfValidator({ notNull: { message: 'Snomed Code is required' } })
	@GandalfProperty({ isRequired: true })
	snomedCodeId!: number;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfValidator({ notNull: { message: 'Status Brother is required' } })
	@GandalfProperty({ isRequired: true })
	statusBrother!: constants.FamilyHealthHistoryHealthCondition;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfValidator({ notNull: { message: 'Status Daughter is required' } })
	@GandalfProperty({ isRequired: true })
	statusDaughter!: constants.FamilyHealthHistoryHealthCondition;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfValidator({ notNull: { message: 'Status Father is required' } })
	@GandalfProperty({ isRequired: true })
	statusFather!: constants.FamilyHealthHistoryHealthCondition;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfValidator({ notNull: { message: 'Status Mother is required' } })
	@GandalfProperty({ isRequired: true })
	statusMother!: constants.FamilyHealthHistoryHealthCondition;

	@GandalfValidator({ notNull: { message: 'Status No Immediate Family Member is required' } })
	@GandalfProperty({ isRequired: true })
	statusNoImmediateFamilyMember!: boolean;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfValidator({ notNull: { message: 'Status Sister is required' } })
	@GandalfProperty({ isRequired: true })
	statusSister!: constants.FamilyHealthHistoryHealthCondition;

	@GandalfConstantDecorator(constants.FamilyHealthHistoryHealthCondition)
	@GandalfValidator({ notNull: { message: 'Status Son is required' } })
	@GandalfProperty({ isRequired: true })
	statusSon!: constants.FamilyHealthHistoryHealthCondition;

}
