// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.billing.BillingPayerResponse */
/* istanbul ignore next */
@GandalfModel
export class BillingPayerResponse extends GandalfModelBase {

	@GandalfProperty()
	entityId!: number | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType | null;

	@GandalfProperty()
	personInsuranceId!: number | null;

	@GandalfConstantDecorator(constants.PersonInsuranceStatus)
	@GandalfProperty()
	personInsuranceStatus!: constants.PersonInsuranceStatus | null;

}
