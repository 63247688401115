// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OrderDashboardSortRequest } from './order-dashboard-sort-request';

/** See com.rev360.pms.api.controller.order.OrdersSimpleSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class OrdersSimpleSearchRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Limit is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Limit must be greater than 0' } })
	@GandalfProperty({ isRequired: true })
	limit!: number;

	@GandalfProperty()
	masterOrderProcessorId!: number | null;

	@GandalfValidator({ notNull: { message: 'Offset is required' } })
	@GandalfValidator({ min: { min: 0, message: 'Offset must be greater than or equal to 0' } })
	@GandalfProperty({ isRequired: true })
	offset!: number;

	@GandalfConstantDecorator(constants.OrderSearchStatusCode)
	@GandalfProperty()
	orderSearchStatusCode!: constants.OrderSearchStatusCode | null;

	@GandalfConstantDecorator(constants.OrderSearchType)
	@GandalfProperty()
	orderSearchType!: constants.OrderSearchType | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Search text', maxLength: 1000, minLength: 0, message: 'Search text must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	searchText!: string | null;

	@GandalfArray(OrderDashboardSortRequest)
	sort!: OrderDashboardSortRequest[] | null;

	@GandalfProperty()
	total!: number | null;

	@GandalfProperty()
	vendorId!: number | null;

}
