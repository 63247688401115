// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.person.diagnosis.ResolvePersonDiagnosisRequest */
/* istanbul ignore next */
@GandalfModel
export class ResolvePersonDiagnosisRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Comment is required' } })
	@GandalfValidator({ sizeString: { message: 'Comment must be between 1 and 244 characters', minLength: 1, maxLength: 244 } })
	@GandalfProperty({ isRequired: true })
	comment!: string;

	@GandalfValidator({ notNull: { message: 'Date Resolved is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	dateResolved!: Date;

	@GandalfValidator({ notNull: { message: 'Diagnosis is required' } })
	@GandalfProperty({ isRequired: true })
	id!: number;

}
