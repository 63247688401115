// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.vendor.VendorResponse */
/* istanbul ignore next */
@GandalfModel
export class VendorResponse extends GandalfModelBase {

	@GandalfProperty()
	accountNumber!: string | null;

	@GandalfProperty()
	address!: AddressResponse | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	directorExternalId!: string | null;

	@GandalfProperty()
	directorName!: string | null;

	@GandalfProperty()
	email!: string | null;

	@GandalfProperty()
	externalId!: string | null;

	@GandalfProperty()
	externalSource!: string | null;

	@GandalfProperty()
	fax!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	labLocationId!: number | null;

	@GandalfProperty()
	labLocationName!: string | null;

	@GandalfProperty()
	mainPhone!: string | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty()
	smartflowMasterSupplierId!: number | null;

	@GandalfProperty()
	tollFree!: string | null;

	@GandalfConstantDecorator(constants.VendorOrderSubmissionMechanism)
	@GandalfProperty()
	vendorOrderSubmissionMechanism!: constants.VendorOrderSubmissionMechanism | null;

	@GandalfProperty()
	webSite!: string | null;

}
