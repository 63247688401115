// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.schedule.AppointmentReportRequest */
/* istanbul ignore next */
@GandalfModel
export class AppointmentReportRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.AppointmentConfirmationStatusSearch)
	@GandalfProperty()
	appointmentConfirmationStatus!: constants.AppointmentConfirmationStatusSearch | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty()
	appointmentStatus!: constants.ScheduleItemStatus | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	confirmationEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	confirmationStartDate!: Date | null;

	@GandalfProperty()
	createdByEmployeeId!: number | null;

	@GandalfProperty()
	employeeId!: number | null;

	@GandalfProperty()
	encounterTypeId!: number | null;

	@GandalfLabel('Appointment End Date')
	@GandalfValidator({ notNull: { message: 'Appointment End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	endDate!: Date;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty()
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfConstantDecorator(constants.InsuranceVerifiedStatusSearch)
	@GandalfProperty()
	insuranceVerifiedStatus!: constants.InsuranceVerifiedStatusSearch | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Patient', maxLength: 1000, minLength: 0, message: 'Patient must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	patientName!: string | null;

	@GandalfProperty()
	providerId!: number | null;

	@GandalfProperty()
	roleId!: number | null;

	@GandalfLabel('Appointment Start Date')
	@GandalfValidator({ notNull: { message: 'Appointment Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate!: Date;

	@GandalfProperty()
	subTypeId!: number | null;

}
