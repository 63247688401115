// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.EncounterStatusesResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterStatusesResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty()
	approvalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfProperty()
	firstApprovalDate!: Date | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty()
	status!: constants.EncounterStatus | null;

}
