// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.UpdateAdditionalClaimInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateAdditionalClaimInformationRequest extends GandalfModelBase {

	@GandalfLabel('Alternate Service Location')
	@GandalfProperty()
	alternateServiceLocationId!: number | null;

	@GandalfLabel('Attachment Control Number')
	@GandalfValidator({ sizeString: { message: 'Attachment Control Number cannot be longer than 80 characters', minLength: 0, maxLength: 80 } })
	@GandalfProperty()
	attachmentControlNumber!: string | null;

	@GandalfConstantDecorator(constants.ClaimAttachmentTransmission)
	@GandalfLabel('Attachment Transmission')
	@GandalfProperty()
	attachmentTransmission!: constants.ClaimAttachmentTransmission | null;

	@GandalfConstantDecorator(constants.ClaimAttachmentType)
	@GandalfLabel('Attachment Type')
	@GandalfProperty()
	attachmentType!: constants.ClaimAttachmentType | null;

	@GandalfLabel('Authorization')
	@GandalfProperty()
	authorizationId!: number | null;

	@GandalfConstantDecorator(constants.ClaimFrequencyCode)
	@GandalfLabel('Claim Frequency Code')
	@GandalfProperty()
	claimFrequencyCode!: constants.ClaimFrequencyCode | null;

	@GandalfLabel('CLIA Number')
	@GandalfValidator({ sizeString: { message: 'CLIA Number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	cliaNumber!: string | null;

	@GandalfConstantDecorator(constants.ClaimVisionConditionApply)
	@GandalfLabel('Condition Contacts Apply')
	@GandalfProperty()
	conditionContactsApply!: constants.ClaimVisionConditionApply | null;

	@GandalfLabel('Condition Contacts L1')
	@GandalfProperty()
	conditionContactsL1!: boolean | null;

	@GandalfLabel('Condition Contacts L2')
	@GandalfProperty()
	conditionContactsL2!: boolean | null;

	@GandalfLabel('Condition Contacts L3')
	@GandalfProperty()
	conditionContactsL3!: boolean | null;

	@GandalfLabel('Condition Contacts L4')
	@GandalfProperty()
	conditionContactsL4!: boolean | null;

	@GandalfLabel('Condition Contacts L5')
	@GandalfProperty()
	conditionContactsL5!: boolean | null;

	@GandalfConstantDecorator(constants.ClaimVisionConditionApply)
	@GandalfLabel('Condition Frames Apply')
	@GandalfProperty()
	conditionFramesApply!: constants.ClaimVisionConditionApply | null;

	@GandalfLabel('Condition Frames L1')
	@GandalfProperty()
	conditionFramesL1!: boolean | null;

	@GandalfLabel('Condition Frames L2')
	@GandalfProperty()
	conditionFramesL2!: boolean | null;

	@GandalfLabel('Condition Frames L3')
	@GandalfProperty()
	conditionFramesL3!: boolean | null;

	@GandalfLabel('Condition Frames L4')
	@GandalfProperty()
	conditionFramesL4!: boolean | null;

	@GandalfLabel('Condition Frames L5')
	@GandalfProperty()
	conditionFramesL5!: boolean | null;

	@GandalfConstantDecorator(constants.ClaimVisionConditionApply)
	@GandalfLabel('Condition Lenses Apply')
	@GandalfProperty()
	conditionLensesApply!: constants.ClaimVisionConditionApply | null;

	@GandalfLabel('Condition Lenses L1')
	@GandalfProperty()
	conditionLensesL1!: boolean | null;

	@GandalfLabel('Condition Lenses L2')
	@GandalfProperty()
	conditionLensesL2!: boolean | null;

	@GandalfLabel('Condition Lenses L3')
	@GandalfProperty()
	conditionLensesL3!: boolean | null;

	@GandalfLabel('Condition Lenses L4')
	@GandalfProperty()
	conditionLensesL4!: boolean | null;

	@GandalfLabel('Condition Lenses L5')
	@GandalfProperty()
	conditionLensesL5!: boolean | null;

	@GandalfLabel('Current Illness Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	currentIllnessDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimCurrentIllnessDateQualifier)
	@GandalfLabel('Current Illness Date Qualifier')
	@GandalfProperty()
	currentIllnessDateQualifier!: constants.ClaimCurrentIllnessDateQualifier | null;

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfLabel('Diagnosis Code Set')
	@GandalfValidator({ notNull: { message: 'Diagnosis Code Set is required' } })
	@GandalfProperty({ isRequired: true })
	diagnosisCodeSet!: constants.CodeSet;

	@GandalfLabel('End Care Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	endCareDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtApply)
	@GandalfLabel('EPSDT Apply')
	@GandalfProperty()
	epsdtApply!: constants.ClaimEpsdtApply | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtCode)
	@GandalfLabel('EPSDT Code 1')
	@GandalfProperty()
	epsdtCode1!: constants.ClaimEpsdtCode | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtCode)
	@GandalfLabel('EPSDT Code 2')
	@GandalfProperty()
	epsdtCode2!: constants.ClaimEpsdtCode | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtCode)
	@GandalfLabel('EPSDT Code 3')
	@GandalfProperty()
	epsdtCode3!: constants.ClaimEpsdtCode | null;

	@GandalfLabel('Hospitalization End Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	hospitalizationEndDate!: Date | null;

	@GandalfLabel('Hospitalization Start Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	hospitalizationStartDate!: Date | null;

	@GandalfLabel('Additional Claim Information')
	@GandalfValidator({ notNull: { message: 'Additional Claim Information is required' } })
	@GandalfProperty({ isRequired: true })
	id!: number;

	@GandalfLabel('Initial Treatment Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	initialTreatmentDate!: Date | null;

	@GandalfLabel('Local Use')
	@GandalfValidator({ sizeString: { message: 'Local Use cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	localUse!: string | null;

	@GandalfLabel('Claim Codes')
	@GandalfValidator({ sizeString: { message: 'Claim Codes cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	localUse10d!: string | null;

	@GandalfLabel('Medicaid Resubmit Code')
	@GandalfValidator({ sizeString: { message: 'Medicaid Resubmit Code cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	medicaidResubmitCode!: string | null;

	@GandalfLabel('Ordering Provider')
	@GandalfProperty()
	orderingProviderId!: number | null;

	@GandalfLabel('Original Reference Number')
	@GandalfValidator({ sizeString: { message: 'Original Reference Number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	originalReferenceNumber!: string | null;

	@GandalfLabel('Out of Work End Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	outOfWorkEndDate!: Date | null;

	@GandalfLabel('Out of Work Start Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	outOfWorkStartDate!: Date | null;

	@GandalfLabel('Outside Lab')
	@GandalfValidator({ notNull: { message: 'Outside Lab is required' } })
	@GandalfProperty({ isRequired: true })
	outsideLab!: boolean;

	@GandalfLabel('Outside Lab Charges')
	@GandalfValidator({ notNull: { message: 'Outside Lab Charges is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	outsideLabCharges!: number;

	@GandalfLabel('Prior Authorization Number')
	@GandalfValidator({ sizeString: { message: 'Prior Authorization Number cannot be longer than 40 characters', minLength: 0, maxLength: 40 } })
	@GandalfProperty()
	priorAuthorizationNumber!: string | null;

	@GandalfLabel('Referral Number')
	@GandalfValidator({ sizeString: { message: 'Referral Number cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	referralNumber!: string | null;

	@GandalfLabel('Referring Provider')
	@GandalfProperty()
	referringProviderId!: number | null;

	@GandalfLabel('Person Insurance')
	@GandalfProperty()
	secondaryPayerId!: number | null;

	@GandalfLabel('Signature Handwritten')
	@GandalfProperty()
	signatureHandwritten!: boolean | null;

	@GandalfLabel('Similar Illness Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	similarIllnessDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimSimilarIllnessDateQualifier)
	@GandalfLabel('Similar Illness Date Qualifier')
	@GandalfProperty()
	similarIllnessDateQualifier!: constants.ClaimSimilarIllnessDateQualifier | null;

	@GandalfLabel('Start Care Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	startCareDate!: Date | null;

	@GandalfLabel('Version')
	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version!: number;

	@GandalfLabel('Vision Prescription Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	visionPrescriptionDate!: Date | null;

}
