// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.smartflow.SmartflowSupplierResponse */
/* istanbul ignore next */
@GandalfModel
export class SmartflowSupplierResponse extends GandalfModelBase {

	@GandalfProperty()
	isOptedIn!: boolean | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty()
	optInByFirstName!: string | null;

	@GandalfProperty()
	optInByLastName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	optInDate!: Date | null;

	@GandalfProperty()
	phoneNumber!: string | null;

	@GandalfProperty()
	smartflowMasterSupplierId!: number | null;

	@GandalfProperty()
	smartflowPracticeSupplierId!: number | null;

	@GandalfConstantDecorator(constants.SmartflowProductPartnerCategory)
	@GandalfProperty()
	smartflowProductPartnerCategory!: constants.SmartflowProductPartnerCategory | null;

	@GandalfProperty()
	webSite!: string | null;

}
