// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UpdatePatientGeneralBenefitsRequest } from './update-patient-general-benefits-request';

// @ts-ignore
import { UpdatePatientInsuranceBasicInformationRequest } from './update-patient-insurance-basic-information-request';

// @ts-ignore
import { UpdatePatientMaterialBenefitsRequest } from './update-patient-material-benefits-request';

/** See com.rev360.pms.api.controller.insurance.updatepersoninsurance.UpdatePersonInsuranceRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePersonInsuranceRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Patient Benefits cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	patientBenefits!: string | null;

	@GandalfValidator({ notNull: { message: 'General Benefits is required' } })
	@GandalfProperty({ isRequired: true })
	patientGeneralBenefitsRequest!: UpdatePatientGeneralBenefitsRequest;

	@GandalfValidator({ notNull: { message: 'Basic Information is required' } })
	@GandalfProperty({ isRequired: true })
	patientInsuranceBasicInformationRequest!: UpdatePatientInsuranceBasicInformationRequest;

	@GandalfValidator({ notNull: { message: 'Material Benefits is required' } })
	@GandalfProperty({ isRequired: true })
	patientMaterialBenefitsRequest!: UpdatePatientMaterialBenefitsRequest;

	@GandalfProperty()
	personInsuranceId!: number | null;

}
