// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { LocationProductForOrderResponse } from './location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './location-product-item-for-order-response';

// @ts-ignore
import { OrderPrescriptionResponse } from './order-prescription-response';

/** See com.rev360.pms.api.controller.order.OrderContactLensPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderContactLensPrescriptionResponse extends OrderPrescriptionResponse {

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty()
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	odLocationProduct!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	odLocationProductItem!: LocationProductItemForOrderResponse | null;

	@GandalfProperty()
	odProductId!: number | null;

	@GandalfProperty()
	osLocationProduct!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	osLocationProductItem!: LocationProductItemForOrderResponse | null;

	@GandalfProperty()
	osProductId!: number | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty()
	status!: constants.PrescriptionStatus | null;

}
