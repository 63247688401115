// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminWorkflowScreenTemplateTestResponse } from './admin-workflow-screen-template-test-response';

/** See com.rev360.pms.api.controller.admin.encounter.screenlibrary.AdminWorkflowScreenTemplateResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminWorkflowScreenTemplateResponse extends GandalfModelBase {

	@GandalfProperty()
	canScreenExcludePullForward!: boolean | null;

	@GandalfProperty()
	categoryId!: number | null;

	@GandalfProperty()
	displayOrder!: number | null;

	@GandalfProperty()
	excludePullForward!: boolean | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isStatic!: boolean | null;

	@GandalfProperty()
	label!: string | null;

	@GandalfConstantDecorator(constants.WorkflowScreenTemplateLayout)
	@GandalfProperty()
	layout!: constants.WorkflowScreenTemplateLayout | null;

	@GandalfProperty()
	masterId!: number | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfArray(AdminWorkflowScreenTemplateTestResponse)
	workflowScreenTemplateTests!: AdminWorkflowScreenTemplateTestResponse[] | null;

}
