// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { BaseSalesReportResponse } from './base-sales-report-response';

/** See com.rev360.pms.api.controller.reporting.inventory.sales.EyeglassFrameSalesReportResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassFrameSalesReportResponse extends BaseSalesReportResponse {

	@GandalfProperty({ propertyType: 'Money' })
	averageAgeOfSales!: number | null;

	@GandalfProperty()
	brand!: string | null;

	@GandalfProperty()
	bridge!: number | null;

	@GandalfProperty()
	category!: string | null;

	@GandalfProperty()
	collection!: string | null;

	@GandalfProperty()
	color!: string | null;

	@GandalfProperty()
	colorCode!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	costOfSales!: number | null;

	@GandalfProperty()
	eye!: number | null;

	@GandalfProperty()
	itemDescription!: string | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	locationProductActualCost!: number | null;

	@GandalfProperty()
	locationProductItemId!: number | null;

	@GandalfProperty()
	manufacturer!: string | null;

	@GandalfProperty()
	model!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	salesAdjustments!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	salesDiscountAmount!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	salesGrossSales!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	salesNetSales!: number | null;

	@GandalfProperty()
	salesQuantity!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	salesTaxAmount!: number | null;

	@GandalfProperty()
	sku!: string | null;

	@GandalfProperty()
	stock!: number | null;

	@GandalfProperty()
	temple!: number | null;

	@GandalfProperty()
	upc!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	wholesaleOfSales!: number | null;

}
