// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryCriteriaFieldRequest } from './query-criteria-field-request';

/** See com.rev360.pms.api.controller.query.UpdateCustomQueryRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateCustomQueryRequest extends GandalfModelBase {

	@GandalfArray(QueryCriteriaFieldRequest)
	criteriaFields!: QueryCriteriaFieldRequest[] | null;

	@GandalfValidator({ notNull: { message: 'ID required' } })
	@GandalfProperty({ isRequired: true })
	id!: number;

	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Access Roles is required' } })
	@GandalfArray(Number)
	roleIds!: number[] | null;

}
