// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.insurance.PersonInsuranceTooltipResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonInsuranceTooltipResponse extends GandalfModelBase {

	@GandalfProperty()
	insuranceCompanyName!: string;

	@GandalfConstantDecorator(constants.InsuranceCompanyStatus)
	@GandalfProperty()
	insuranceCompanyStatus!: constants.InsuranceCompanyStatus;

	@GandalfProperty()
	insurancePriority!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	insuranceType!: ReferenceDataSummaryResponse | null;

}
