// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionDiffResponse } from './field-value-multi-selection-diff-response';

// @ts-ignore
import { StringValueDiffResponse } from './string-value-diff-response';

/** See com.rev360.pms.api.controller.encounter.history.pfsh.OcularDiffResponse */
/* istanbul ignore next */
@GandalfModel
export class OcularDiffResponse extends GandalfModelBase {

	@GandalfProperty()
	comments!: StringValueDiffResponse | null;

	@GandalfProperty()
	eyeConditions!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	hasChanges!: boolean | null;

	@GandalfProperty()
	hasOcularFromIntake!: boolean | null;

}
