// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.eyefinity.EyefinityOrderItemResponse */
/* istanbul ignore next */
@GandalfModel
export class EyefinityOrderItemResponse extends GandalfModelBase {

	@GandalfProperty()
	billableItemId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityChargeback!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityChargebackBeforeCopay!: number | null;

	@GandalfProperty()
	eyefinityFeeOptionId!: number | null;

	@GandalfProperty()
	eyefinityGenerated!: boolean | null;

	@GandalfProperty()
	eyefinityOrderItemId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityPays!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityReimbursement!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityServiceFee!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	eyefinityTotal!: number | null;

	@GandalfProperty()
	hcpcsCode!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	invoiceCost!: number | null;

	@GandalfProperty()
	itemIsCovered!: boolean | null;

	@GandalfConstantDecorator(constants.EyefinityOrderItemType)
	@GandalfProperty()
	itemType!: constants.EyefinityOrderItemType | null;

	@GandalfProperty()
	locationProductId!: number | null;

	@GandalfProperty()
	locationProductItemId!: number | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientCopay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientCopayAmount!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientPortion!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientPortionExceedingAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientPortionForNonCoveredItem!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientPortionFromUnallocatedCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientTotal!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientTransferAmount!: number | null;

	@GandalfProperty()
	quantity!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	recoupmentAmount!: number | null;

	@GandalfProperty()
	rememberItem!: boolean | null;

	@GandalfProperty({ propertyType: 'Money' })
	retailCost!: number | null;

	@GandalfProperty()
	serviceId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	unallocatedCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	writeoffAmount!: number | null;

}
