// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InvoiceItemAdjustmentResponse } from './invoice-item-adjustment-response';

// @ts-ignore
import { InvoiceItemPersonDiagnosisResponse } from './invoice-item-person-diagnosis-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceItemDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceItemDetailResponse extends GandalfModelBase {

	@GandalfArray(InvoiceItemPersonDiagnosisResponse)
	additionalDiagnoses!: InvoiceItemPersonDiagnosisResponse[];

	@GandalfProperty()
	additionalModifierId0!: number | null;

	@GandalfProperty()
	additionalModifierId1!: number | null;

	@GandalfProperty()
	additionalModifierId2!: number | null;

	@GandalfProperty()
	additionalModifierId3!: number | null;

	@GandalfProperty()
	claimNote!: string | null;

	@GandalfConstantDecorator(constants.ClaimNoteCode)
	@GandalfProperty()
	claimNoteCode!: constants.ClaimNoteCode | null;

	@GandalfProperty()
	code!: string;

	@GandalfProperty()
	description!: string;

	@GandalfProperty()
	dosageAmount!: string | null;

	@GandalfConstantDecorator(constants.DosageUnitCode)
	@GandalfProperty()
	dosageUnit!: constants.DosageUnitCode | null;

	@GandalfProperty()
	emergencyService!: string | null;

	@GandalfProperty()
	epsdt!: string | null;

	@GandalfProperty()
	externalProviderId!: number | null;

	@GandalfConstantDecorator(constants.FacilityType)
	@GandalfProperty()
	facilityType!: constants.FacilityType | null;

	@GandalfProperty()
	formattedExternalProviderName!: string | null;

	@GandalfProperty()
	invoiceItemId!: number;

	@GandalfArray(InvoiceItemAdjustmentResponse)
	itemAdjustments!: InvoiceItemAdjustmentResponse[];

	@GandalfProperty()
	modifier!: string | null;

	@GandalfProperty()
	nationalDrugCode!: string | null;

	@GandalfProperty()
	responsiblePersonId!: number | null;

	@GandalfProperty()
	rxNumber!: string | null;

	@GandalfConstantDecorator(constants.RxQualifier)
	@GandalfProperty()
	rxQualifier!: constants.RxQualifier | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceEndDate!: Date | null;

	@GandalfProperty()
	serviceLocationId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceStartDate!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceItemStatus)
	@GandalfProperty()
	status!: constants.InvoiceItemStatus;

	@GandalfConstantDecorator(constants.InvoiceItemType)
	@GandalfProperty()
	type!: constants.InvoiceItemType;

}
