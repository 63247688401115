// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.dynamic.RefractionEyeRequest */
/* istanbul ignore next */
@GandalfModel
export class RefractionEyeRequest extends GandalfModelBase {

	@GandalfProperty()
	axis!: number | null;

	@GandalfProperty()
	balanced!: boolean | null;

	@GandalfProperty()
	cylinder!: number | null;

	@GandalfProperty()
	horizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty()
	horizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty()
	intermediateAdd!: number | null;

	@GandalfProperty()
	nearAdd!: number | null;

	@GandalfProperty()
	sphere!: number | null;

	@GandalfProperty()
	verticalPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty()
	verticalPrismOrientation!: constants.VerticalPrismOrientation | null;

}
