// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminWorkflowTestTemplateFormFieldRequest } from './admin-workflow-test-template-form-field-request';

/** See com.rev360.pms.api.controller.admin.encounter.testlibrary.AdminUpdateWorkflowTestTemplateRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminUpdateWorkflowTestTemplateRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Category is required' } })
	@GandalfProperty({ isRequired: true })
	categoryId!: number;

	@GandalfLabel('Exclude Pull Forward')
	@GandalfValidator({ notNull: { message: 'Exclude Pull Forward is required' } })
	@GandalfProperty({ isRequired: true })
	excludePullForward!: boolean;

	@GandalfValidator({ notNull: { message: 'Field properties are required.' } })
	@GandalfArray(AdminWorkflowTestTemplateFormFieldRequest)
	fields!: AdminWorkflowTestTemplateFormFieldRequest[];

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name!: string;

	@GandalfValidator({ notNull: { message: 'Workflow Test Template is required' } })
	@GandalfProperty({ isRequired: true })
	workflowTestTemplateId!: number;

}
