// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.family.CreatePatientFamilyDemographicsRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientFamilyDemographicsRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'DOB is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	dob!: Date;

	@GandalfValidator({ notNull: { message: 'First name is required' } })
	@GandalfValidator({ sizeString: { message: 'First name must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ isRequired: true })
	firstName!: string;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfValidator({ notNull: { message: 'Gender is required' } })
	@GandalfProperty({ isRequired: true })
	gender!: constants.Gender;

	@GandalfValidator({ notNull: { message: 'Last name is required' } })
	@GandalfValidator({ sizeString: { message: 'Last name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	lastName!: string;

	@GandalfValidator({ sizeString: { message: 'Middle name cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	middleName!: string | null;

	@GandalfValidator({ sizeString: { message: 'Nickname cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	nickname!: string | null;

	@GandalfValidator({ sin: { message: 'SIN/PHN must only contain alphanumeric or dash characters' } })
	@GandalfValidator({ sizeString: { message: 'SIN/PHN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfProperty()
	sin!: string | null;

	@GandalfValidator({ socialSecurityNumber: { message: 'Social security number must contain 9 digits' } })
	@GandalfValidator({ sizeString: { message: 'SSN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfProperty()
	ssn!: string | null;

	@GandalfValidator({ sizeString: { message: 'Suffix cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	suffix!: string | null;

}
