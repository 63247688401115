// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueResponse } from './field-value-response';

// @ts-ignore
import { FormComponentResponse } from './form-component-response';

// @ts-ignore
import { FormPositionResponse } from './form-position-response';

/** See com.rev360.pms.api.controller.encounter.dynamic.DynamicTestResponse */
/* istanbul ignore next */
@GandalfModel
export class DynamicTestResponse extends GandalfModelBase {

	@GandalfArray(FormComponentResponse)
	children!: FormComponentResponse[] | null;

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty()
	fileCount!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isReadOnly!: boolean | null;

	@GandalfProperty()
	isServerCopyDirty!: boolean | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty()
	position!: FormPositionResponse | null;

	@GandalfProperty()
	testMasterId!: number | null;

	@GandalfProperty()
	testTemplateId!: number | null;

	@GandalfProperty()
	useRefractionFields!: boolean | null;

	@GandalfArray(FieldValueResponse)
	values!: FieldValueResponse[] | null;

	@GandalfProperty()
	version!: number | null;

}
