// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { RefractionEyeRequest } from './refraction-eye-request';

/** See com.rev360.pms.api.controller.encounter.dynamic.CreateEyeglassPrescriptionFromRefractionRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateEyeglassPrescriptionFromRefractionRequest extends GandalfModelBase {

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfValidator({ notNull: { message: 'Expiration Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	expirationDate!: Date;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions!: string | null;

	@GandalfSubRequestLabel('OD')
	@GandalfValidator({ notNull: { message: 'OD is required' } })
	@GandalfProperty({ isRequired: true })
	od!: RefractionEyeRequest;

	@GandalfSubRequestLabel('OS')
	@GandalfValidator({ notNull: { message: 'OS is required' } })
	@GandalfProperty({ isRequired: true })
	os!: RefractionEyeRequest;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate!: Date;

}
