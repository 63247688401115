// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.patientmanagement.PatientManagementSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientManagementSearchRequest extends GandalfModelBase {

	@GandalfLabel('DOB')
	@GandalfProperty({ propertyType: 'LocalDate' })
	dateOfBirth!: Date | null;

	@GandalfLabel('First Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'First Name', maxLength: 1000, minLength: 0, message: 'First Name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	firstName!: string | null;

	@GandalfLabel('Last Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Last Name', maxLength: 1000, minLength: 0, message: 'Last Name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	lastName!: string | null;

	@GandalfLabel('Location')
	@GandalfProperty()
	locationId!: number | null;

	@GandalfLabel('Patient')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'ID may not be more than 18 digits' } })
	@GandalfProperty()
	patientId!: number | null;

	@GandalfConstantDecorator(constants.PatientSearchStatus)
	@GandalfLabel('Patient Search Status')
	@GandalfProperty()
	patientSearchStatus!: constants.PatientSearchStatus | null;

	@GandalfLabel('Phone Number')
	@GandalfValidator({ phoneNumber: { message: 'Phone Number must contain at least 10 digits' } })
	@GandalfProperty()
	phoneNumber!: string | null;

	@GandalfLabel('SIN/PHN')
	@GandalfValidator({ sizeString: { fieldLabel: 'SIN/PHN', maxLength: 1000, minLength: 0, message: 'SIN/PHN must be less than or equal to 1000 characters' } })
	@GandalfValidator({ sin: { message: 'SIN/PHN must only contain alphanumeric or dash characters' } })
	@GandalfProperty()
	socialInsuranceNumber!: string | null;

	@GandalfLabel('SSN')
	@GandalfValidator({ sizeString: { fieldLabel: 'SSN', maxLength: 1000, minLength: 0, message: 'SSN must be less than or equal to 1000 characters' } })
	@GandalfValidator({ socialSecurityNumber: { message: 'Social security number must contain 9 digits' } })
	@GandalfProperty()
	socialSecurityNumber!: string | null;

}
