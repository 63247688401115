// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.accounting.payment.PaymentInvoiceListResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentInvoiceListResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentTotal!: number;

	@GandalfProperty({ propertyType: 'Money' })
	balance!: number;

	@GandalfProperty()
	id!: number;

	@GandalfProperty()
	invoiceDate!: Date;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType;

	@GandalfProperty()
	processingPaymentGroupId!: number | null;

	@GandalfProperty()
	serviceDate!: Date;

	@GandalfProperty({ propertyType: 'Money' })
	total!: number;

}
