// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateCarePlanItemTemplateRequest } from './create-care-plan-item-template-request';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.CreateCarePlanItemTemplateRecallRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateCarePlanItemTemplateRecallRequest extends CreateCarePlanItemTemplateRequest {

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments!: string | null;

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	description!: string | null;

	@GandalfValidator({ notNull: { message: 'Encounter Type is required' } })
	@GandalfProperty({ isRequired: true })
	encounterTemplateId!: number;

	@GandalfValidator({ notNull: { message: 'Checkout Task is required' } })
	@GandalfProperty({ isRequired: true })
	isCheckoutTask!: boolean;

	@GandalfValidator({ notNull: { message: 'Recall Amount is required' } })
	@GandalfProperty({ isRequired: true })
	recallAmount!: number;

	@GandalfConstantDecorator(constants.CarePlanItemRecallDuration)
	@GandalfValidator({ notNull: { message: 'Recall Unit is required' } })
	@GandalfProperty({ isRequired: true })
	recallUnit!: constants.CarePlanItemRecallDuration;

}
