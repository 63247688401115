// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.assessment.MedicalDecisionMakingResponse */
/* istanbul ignore next */
@GandalfModel
export class MedicalDecisionMakingResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfConstantDecorator(constants.MedicalDecisionMakingComplexity)
	@GandalfProperty()
	medicalDecisionMakingComplexity!: constants.MedicalDecisionMakingComplexity | null;

	@GandalfConstantDecorator(constants.MedicalDecisionMakingConsultation)
	@GandalfProperty()
	medicalDecisionMakingConsultation!: constants.MedicalDecisionMakingConsultation | null;

	@GandalfConstantDecorator(constants.MedicalDecisionMakingNumDx)
	@GandalfProperty()
	medicalDecisionMakingNumDxLevel!: constants.MedicalDecisionMakingNumDx | null;

	@GandalfConstantDecorator(constants.MedicalDecisionMakingRisk)
	@GandalfProperty()
	medicalDecisionMakingRisk!: constants.MedicalDecisionMakingRisk | null;

}
