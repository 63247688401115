// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.LocationProductItemForOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class LocationProductItemForOrderResponse extends GandalfModelBase {

	@GandalfProperty()
	a!: number | null;

	@GandalfProperty()
	addDesignation!: string | null;

	@GandalfProperty()
	addPower!: number | null;

	@GandalfProperty()
	axis!: number | null;

	@GandalfProperty()
	b!: number | null;

	@GandalfProperty()
	baseCurve!: number | null;

	@GandalfProperty()
	bridge!: number | null;

	@GandalfProperty()
	circumference!: number | null;

	@GandalfProperty()
	color!: string | null;

	@GandalfProperty()
	colorCode!: string | null;

	@GandalfProperty()
	cylinder!: number | null;

	@GandalfProperty()
	dbl!: number | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	diameter!: number | null;

	@GandalfProperty()
	ed!: number | null;

	@GandalfProperty()
	edAngle!: number | null;

	@GandalfProperty()
	eye!: number | null;

	@GandalfProperty()
	frameColorId!: number | null;

	@GandalfProperty()
	frameSizeId!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isTrial!: boolean | null;

	@GandalfProperty()
	lensType!: string | null;

	@GandalfProperty()
	locationProductId!: number | null;

	@GandalfProperty()
	material!: string | null;

	@GandalfProperty()
	packaging!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	retail!: number | null;

	@GandalfProperty()
	screwToScrew!: number | null;

	@GandalfProperty()
	sku!: string | null;

	@GandalfProperty()
	sphere!: number | null;

	@GandalfProperty()
	stockQuantity!: number | null;

	@GandalfProperty()
	stocked!: boolean | null;

	@GandalfProperty()
	temple!: number | null;

	@GandalfProperty()
	templeUnit!: string | null;

	@GandalfProperty()
	upc!: string | null;

}
