// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.payment.ServiceAdjustmentResponse */
/* istanbul ignore next */
@GandalfModel
export class ServiceAdjustmentResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount1!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount2!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount3!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount4!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount5!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	adjustmentAmount6!: number | null;

	@GandalfProperty()
	adjustmentReasonCode1!: string | null;

	@GandalfProperty()
	adjustmentReasonCode2!: string | null;

	@GandalfProperty()
	adjustmentReasonCode3!: string | null;

	@GandalfProperty()
	adjustmentReasonCode4!: string | null;

	@GandalfProperty()
	adjustmentReasonCode5!: string | null;

	@GandalfProperty()
	adjustmentReasonCode6!: string | null;

	@GandalfProperty()
	claimAdjustmentGroupCode!: string | null;

}
