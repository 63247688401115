// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.SearchAppointmentSlotRequest */
/* istanbul ignore next */
@GandalfModel
export class SearchAppointmentSlotRequest extends GandalfModelBase {

	@GandalfProperty()
	employeeId!: number | null;

	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ isRequired: true })
	encounterTemplateId!: number;

	@GandalfValidator({ notNull: { message: 'Appointment End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	endDate!: Date;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	fridayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	mondayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfProperty()
	roleId!: number | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	saturdayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfValidator({ notNull: { message: 'Appointment Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate!: Date;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	sundayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	thursdayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	tuesdayTime!: constants.SchedulingPreferencesTimeOfDay | null;

	@GandalfConstantDecorator(constants.SchedulingPreferencesTimeOfDay)
	@GandalfProperty()
	wednesdayTime!: constants.SchedulingPreferencesTimeOfDay | null;

}
