// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.ScheduleViewPreferencesResponse */
/* istanbul ignore next */
@GandalfModel
export class ScheduleViewPreferencesResponse extends GandalfModelBase {

	@GandalfProperty()
	enableGroupingPreference!: boolean | null;

	@GandalfConstantDecorator(constants.ScheduleIncrementPreferenceValues)
	@GandalfProperty()
	incrementPreference!: constants.ScheduleIncrementPreferenceValues | null;

	@GandalfConstantDecorator(constants.ScheduleLayoutPreferenceValues)
	@GandalfProperty()
	layoutPreference!: constants.ScheduleLayoutPreferenceValues | null;

	@GandalfConstantDecorator(constants.ScheduleView)
	@GandalfProperty()
	scheduleView!: constants.ScheduleView | null;

}
