// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.medication.MedicationReviewHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class MedicationReviewHistoryResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.ReviewHistoryCategory)
	@GandalfProperty()
	category!: constants.ReviewHistoryCategory | null;

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty()
	noKnownMedications!: boolean | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfProperty()
	providerReviewAttested!: boolean | null;

	@GandalfProperty()
	providerReviewDate!: Date | null;

	@GandalfProperty()
	providerReviewedByName!: string | null;

	@GandalfProperty()
	reconciliationPerformed!: boolean | null;

	@GandalfProperty()
	reviewDate!: Date | null;

	@GandalfProperty()
	reviewId!: number | null;

	@GandalfProperty()
	reviewedByName!: string | null;

	@GandalfProperty()
	reviewedByUserId!: number | null;

	@GandalfConstantDecorator(constants.ReviewHistorySubCategory)
	@GandalfProperty()
	subCategory!: constants.ReviewHistorySubCategory | null;

}
