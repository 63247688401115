// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.admin.AccessLogSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class AccessLogSearchResponse extends GandalfModelBase {

	@GandalfProperty()
	duration!: number | null;

	@GandalfProperty()
	emergencyAccess!: boolean | null;

	@GandalfProperty()
	endDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	startDate!: Date | null;

	@GandalfProperty()
	userId!: number | null;

	@GandalfProperty()
	userName!: string | null;

}
