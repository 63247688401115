// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.practicepreferences.UpdateClaimsProcessorAttributeRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateClaimsProcessorAttributeRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'All Attributes require a Name' } })
	@GandalfValidator({ sizeString: { min: 0, max: 255, message: 'All Attribute Names must less than or equal to {max} characters' } })
	@GandalfProperty({ isRequired: true })
	name!: string;

	@GandalfProperty()
	required!: boolean | null;

	@GandalfValidator({ sizeString: { min: 0, max: 255, message: 'All Attribute Values must less than or equal to {max} characters' } })
	@GandalfProperty()
	value!: string | null;

}
