// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientPodContactLensEyeResponse } from './patient-pod-contact-lens-eye-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodContactLensResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodContactLensResponse extends GandalfModelBase {

	@GandalfProperty()
	authorizedBy!: PersonNameResponse | null;

	@GandalfProperty()
	contactLensPrescriptionId!: number | null;

	@GandalfProperty()
	contactLensTrialId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate!: Date | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty()
	lensType!: constants.ContactLensPrescriptionType | null;

	@GandalfProperty()
	od!: PatientPodContactLensEyeResponse | null;

	@GandalfProperty()
	odDispensed!: boolean | null;

	@GandalfProperty()
	os!: PatientPodContactLensEyeResponse | null;

	@GandalfProperty()
	osDispensed!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty()
	status!: constants.PrescriptionCurrentStatus | null;

}
