// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.patient.order.ContactLensOrderSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensOrderSearchResponse extends GandalfModelBase {

	@GandalfProperty()
	contactLensPrescriptionId!: number | null;

	@GandalfProperty()
	contactLensTrialId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate!: Date | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty()
	lensType!: constants.ContactLensPrescriptionType | null;

	@GandalfProperty()
	odManufacturer!: string | null;

	@GandalfProperty()
	odModelName!: string | null;

	@GandalfProperty()
	osManufacturer!: string | null;

	@GandalfProperty()
	osModelName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty()
	status!: constants.PrescriptionCurrentStatus | null;

}
