// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.PullForwardEncounterRequest */
/* istanbul ignore next */
@GandalfModel
export class PullForwardEncounterRequest extends GandalfModelBase {

	@GandalfLabel('From encounter')
	@GandalfValidator({ notNull: { message: 'From encounter is required' } })
	@GandalfProperty({ isRequired: true })
	fromEncounterId!: number;

	@GandalfLabel('To encounter')
	@GandalfValidator({ notNull: { message: 'To encounter is required' } })
	@GandalfProperty({ isRequired: true })
	toEncounterId!: number;

}
