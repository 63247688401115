// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.pupillarydistance.PupillaryDistanceHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class PupillaryDistanceHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	odMonoDistancePupillaryDistance!: number | null;

	@GandalfProperty()
	odMonoNearPupillaryDistance!: number | null;

	@GandalfProperty()
	osMonoDistancePupillaryDistance!: number | null;

	@GandalfProperty()
	osMonoNearPupillaryDistance!: number | null;

	@GandalfProperty()
	ouDistancePupillaryDistance!: number | null;

	@GandalfProperty()
	ouNearPupillaryDistance!: number | null;

	@GandalfConstantDecorator(constants.PupillaryDistanceSource)
	@GandalfProperty()
	source!: constants.PupillaryDistanceSource | null;

	@GandalfProperty()
	sourceDate!: Date | null;

	@GandalfProperty()
	sourceName!: string | null;

}
