// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryResponse } from './query-response';

// @ts-ignore
import { QueryTriggerCarePlanItemTemplateResponse } from './query-trigger-care-plan-item-template-response';

// @ts-ignore
import { QueryTriggerCarePlanTemplateResponse } from './query-trigger-care-plan-template-response';

/** See com.rev360.pms.api.controller.query.QueryTriggerResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryTriggerResponse extends GandalfModelBase {

	@GandalfArray(QueryTriggerCarePlanItemTemplateResponse)
	carePlanItemTemplates!: QueryTriggerCarePlanItemTemplateResponse[] | null;

	@GandalfArray(QueryTriggerCarePlanTemplateResponse)
	carePlanTemplates!: QueryTriggerCarePlanTemplateResponse[] | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	interventionBibliographicCitation!: string | null;

	@GandalfProperty()
	interventionDeveloper!: string | null;

	@GandalfProperty()
	interventionDeveloperFunding!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	interventionReleaseDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	interventionRevisionDate!: Date | null;

	@GandalfProperty()
	query!: QueryResponse | null;

	@GandalfProperty()
	useOfDateOfBirth!: string | null;

	@GandalfProperty()
	useOfEthnicity!: string | null;

	@GandalfProperty()
	useOfGenderIdentity!: string | null;

	@GandalfProperty()
	useOfHealthStatusAssessments!: string | null;

	@GandalfProperty()
	useOfLanguage!: string | null;

	@GandalfProperty()
	useOfRace!: string | null;

	@GandalfProperty()
	useOfSex!: string | null;

	@GandalfProperty()
	useOfSexualOrientation!: string | null;

	@GandalfProperty()
	useOfSocialDeterminants!: string | null;

}
