// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.person.PhoneNumbersResponse */
/* istanbul ignore next */
@GandalfModel
export class PhoneNumbersResponse extends GandalfModelBase {

	@GandalfProperty()
	cellPhone!: string | null;

	@GandalfProperty()
	cellPhoneExt!: string | null;

	@GandalfProperty()
	fax!: string | null;

	@GandalfProperty()
	homePhone!: string | null;

	@GandalfProperty()
	homePhoneExt!: string | null;

	@GandalfProperty()
	pager!: string | null;

	@GandalfProperty()
	preferredPhoneNumber!: string | null;

	@GandalfConstantDecorator(constants.PreferredPhoneType)
	@GandalfProperty()
	preferredPhoneType!: constants.PreferredPhoneType | null;

	@GandalfProperty()
	workPhone!: string | null;

	@GandalfProperty()
	workPhoneExt!: string | null;

}
