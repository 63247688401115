// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { Hl7ValueSetResponse } from './hl7-value-set-response';

// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

/** See com.rev360.pms.api.controller.laborder.LabOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class LabOrderResponse extends GandalfModelBase {

	@GandalfArray(Hl7ValueSetResponse)
	abnormalFlags!: Hl7ValueSetResponse[] | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfProperty()
	frequencyId!: number | null;

	@GandalfProperty()
	hl7upload!: boolean | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	interpretationNotes!: string | null;

	@GandalfProperty()
	interpretationOtherComments!: string | null;

	@GandalfConstantDecorator(constants.LabOrderInterpretationStatus)
	@GandalfProperty()
	interpretationStatus!: constants.LabOrderInterpretationStatus | null;

	@GandalfConstantDecorator(constants.LabOrderLabType)
	@GandalfProperty()
	labType!: constants.LabOrderLabType | null;

	@GandalfProperty()
	labVendorId!: number | null;

	@GandalfProperty()
	nonCpoeEntry!: boolean | null;

	@GandalfProperty()
	normalRange!: string | null;

	@GandalfProperty()
	observationResultStatus!: Hl7ValueSetResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	observationStartedOn!: Date | null;

	@GandalfProperty()
	order!: LoincCodeResponse | null;

	@GandalfProperty()
	orderComment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDate!: Date | null;

	@GandalfProperty()
	orderInstructions!: string | null;

	@GandalfConstantDecorator(constants.LabOrderStatus)
	@GandalfProperty()
	orderStatus!: constants.LabOrderStatus | null;

	@GandalfConstantDecorator(constants.LabOrderType)
	@GandalfProperty()
	orderType!: constants.LabOrderType | null;

	@GandalfProperty()
	orderedByProviderId!: number | null;

	@GandalfProperty()
	parentId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	reportDate!: Date | null;

	@GandalfProperty()
	resultComments!: string | null;

	@GandalfProperty()
	resultLabNote!: string | null;

	@GandalfConstantDecorator(constants.LabOrderResultStatus)
	@GandalfProperty()
	resultStatus!: constants.LabOrderResultStatus | null;

	@GandalfConstantDecorator(constants.LabOrderResultType)
	@GandalfProperty()
	resultType!: constants.LabOrderResultType | null;

	@GandalfProperty()
	resultUnitId!: number | null;

	@GandalfProperty()
	resultValue!: string | null;

	@GandalfProperty()
	specimenAppropriateness!: Hl7ValueSetResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	specimenCollectionEndedOn!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	specimenCollectionStartedOn!: Date | null;

	@GandalfArray(Hl7ValueSetResponse)
	specimenConditions!: Hl7ValueSetResponse[] | null;

	@GandalfProperty()
	specimenDisposition!: string | null;

	@GandalfProperty()
	specimenQuality!: Hl7ValueSetResponse | null;

	@GandalfArray(Hl7ValueSetResponse)
	specimenRejectReasons!: Hl7ValueSetResponse[] | null;

	@GandalfProperty()
	specimenSourceId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfProperty()
	testName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	testPerformedDate!: Date | null;

	@GandalfProperty()
	testTypeId!: number | null;

	@GandalfProperty()
	version!: number | null;

}
