// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.openedge.OpenEdgeFinishTransactionRequest */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeFinishTransactionRequest extends GandalfModelBase {

	@GandalfLabel('Card reader')
	@GandalfValidator({ notNull: { message: 'Card reader is required' } })
	@GandalfProperty({ isRequired: true })
	openEdgeCardReaderId!: number;

	@GandalfConstantDecorator(constants.OpenEdgeTransactionType)
	@GandalfValidator({ notNull: { message: 'Open Edge Transaction Type is required' } })
	@GandalfProperty({ isRequired: true })
	openEdgeTransactionType!: constants.OpenEdgeTransactionType;

	@GandalfProperty()
	personId!: number | null;

	@GandalfLabel('Reference Identifier')
	@GandalfValidator({ notNull: { message: 'Reference Identifier is required' } })
	@GandalfValidator({ sizeString: { min: 1, max: 255, message: 'Reference Identifier must be between 1 and 255 characters' } })
	@GandalfProperty({ isRequired: true })
	referenceIdentifier!: string;

}
