// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.admin.UserSessionLogSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class UserSessionLogSearchRequest extends GandalfModelBase {

	@GandalfProperty()
	employeeId!: number | null;

	@GandalfLabel('End Date')
	@GandalfValidator({ notNull: { message: 'End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	endDate!: Date;

	@GandalfLabel('Entity #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Entity # may not be more than 18 digits' } })
	@GandalfProperty()
	entityId!: number | null;

	@GandalfConstantDecorator(constants.UserSessionLogEntityType)
	@GandalfProperty()
	entityType!: constants.UserSessionLogEntityType | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfLabel('Start Date')
	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate!: Date;

}
