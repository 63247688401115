// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueRequest } from './field-value-request';

/** See com.rev360.pms.api.controller.shared.field.FieldValueSmallStringRequest */
/* istanbul ignore next */
@GandalfModel
export class FieldValueSmallStringRequest extends FieldValueRequest {

	@GandalfProperty()
	fieldId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Value cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	value!: string | null;

}
