// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

/** See com.rev360.pms.api.controller.shared.person.PersonDetailsRequest */
/* istanbul ignore next */
@GandalfModel
export class PersonDetailsRequest extends GandalfModelBase {

	@GandalfProperty()
	address!: AddressOptionalRequest | null;

	@GandalfValidator({ phoneNumber: { message: 'Phone number must contain at least 10 digits' } })
	@GandalfValidator({ sizeString: { message: 'Cell Phone cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	cellPhone!: string | null;

	@GandalfValidator({ sizeString: { message: 'Credentials cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	credentials!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateOfBirth!: Date | null;

	@GandalfValidator({ email: { message: 'Invalid Email format' } })
	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	email!: string | null;

	@GandalfValidator({ notNull: { message: 'First Name is required' } })
	@GandalfValidator({ sizeString: { message: 'First Name must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ isRequired: true })
	firstName!: string;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty()
	gender!: constants.Gender | null;

	@GandalfValidator({ phoneNumber: { message: 'Phone number must contain at least 10 digits' } })
	@GandalfValidator({ sizeString: { message: 'Home Phone cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	homePhone!: string | null;

	@GandalfValidator({ notNull: { message: 'Last Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Last Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	lastName!: string;

	@GandalfValidator({ sizeString: { message: 'Middle Name cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	middleName!: string | null;

	@GandalfValidator({ sizeString: { message: 'NickName cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	nickName!: string | null;

	@GandalfValidator({ phoneNumber: { message: 'Phone number must contain at least 10 digits' } })
	@GandalfValidator({ sizeString: { message: 'Pager cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	pager!: string | null;

	@GandalfProperty()
	photoId!: number | null;

	@GandalfConstantDecorator(constants.PreferredPhoneType)
	@GandalfProperty()
	preferredPhoneType!: constants.PreferredPhoneType | null;

	@GandalfValidator({ sizeString: { message: 'SIN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfValidator({ sin: { message: 'SIN/PHN must only contain alphanumeric or dash characters' } })
	@GandalfProperty()
	sin!: string | null;

	@GandalfValidator({ socialSecurityNumber: { message: 'Social security number must contain 9 digits' } })
	@GandalfValidator({ sizeString: { message: 'SSN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfProperty()
	ssn!: string | null;

	@GandalfValidator({ sizeString: { message: 'Suffix cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	suffix!: string | null;

	@GandalfProperty()
	titleId!: number | null;

	@GandalfValidator({ phoneNumber: { message: 'Phone number must contain at least 10 digits' } })
	@GandalfValidator({ sizeString: { message: 'Work Phone cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	workPhone!: string | null;

}
