// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.encounter.contactlens.CreateContactLensTrialRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateContactLensTrialRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	commentsOd!: string | null;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	commentsOs!: string | null;

	@GandalfProperty()
	dispensedOd!: boolean | null;

	@GandalfProperty()
	dispensedOs!: boolean | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty()
	od!: ContactLensEyeRequest | null;

	@GandalfProperty()
	os!: ContactLensEyeRequest | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfValidator({ notNull: { message: 'Lens Type is required' } })
	@GandalfProperty({ isRequired: true })
	overallLensType!: constants.ContactLensPrescriptionType;

}
