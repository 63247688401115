// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateLabOrderRequest } from './create-lab-order-request';

/** See com.rev360.pms.api.controller.laborder.UpdateLabOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateLabOrderRequest extends CreateLabOrderRequest {

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfProperty()
	fileCount!: number | null;

	@GandalfProperty()
	frequencyId!: number | null;

	@GandalfValidator({ notNull: { message: 'ID is required' } })
	@GandalfProperty({ isRequired: true })
	id!: number;

	@GandalfValidator({ sizeString: { message: 'Interpretation Notes cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	interpretationNotes!: string | null;

	@GandalfValidator({ sizeString: { message: 'Interpretation Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	interpretationOtherComments!: string | null;

	@GandalfConstantDecorator(constants.LabOrderInterpretationStatus)
	@GandalfValidator({ notNull: { message: 'Interpretation Status is required' } })
	@GandalfProperty({ isRequired: true })
	interpretationStatus!: constants.LabOrderInterpretationStatus;

	@GandalfConstantDecorator(constants.LabOrderLabType)
	@GandalfValidator({ notNull: { message: 'Lab Type is required' } })
	@GandalfProperty({ isRequired: true })
	labType!: constants.LabOrderLabType;

	@GandalfProperty()
	labVendorId!: number | null;

	@GandalfProperty()
	nonCpoeEntry!: boolean | null;

	@GandalfValidator({ sizeString: { message: 'Normal Range cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	normalRange!: string | null;

	@GandalfValidator({ sizeString: { message: 'Order Comment cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	orderComment!: string | null;

	@GandalfValidator({ notNull: { message: 'Order Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	orderDate!: Date;

	@GandalfValidator({ sizeString: { message: 'Order Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	orderInstructions!: string | null;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfValidator({ sizeString: { message: 'Order must be between 1 and 8 characters', minLength: 1, maxLength: 8 } })
	@GandalfProperty({ isRequired: true })
	orderLoincNum!: string;

	@GandalfConstantDecorator(constants.LabOrderType)
	@GandalfValidator({ notNull: { message: 'Order Type is required' } })
	@GandalfProperty({ isRequired: true })
	orderType!: constants.LabOrderType;

	@GandalfValidator({ notNull: { message: 'Ordered By is required' } })
	@GandalfProperty({ isRequired: true })
	orderedByProviderId!: number;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	reportDate!: Date | null;

	@GandalfValidator({ sizeString: { message: 'Result Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	resultComments!: string | null;

	@GandalfValidator({ sizeString: { message: 'Lab Note cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	resultLabNote!: string | null;

	@GandalfConstantDecorator(constants.LabOrderResultStatus)
	@GandalfValidator({ notNull: { message: 'Result Status is required' } })
	@GandalfProperty({ isRequired: true })
	resultStatus!: constants.LabOrderResultStatus;

	@GandalfConstantDecorator(constants.LabOrderResultType)
	@GandalfValidator({ notNull: { message: 'Result Type is required' } })
	@GandalfProperty({ isRequired: true })
	resultType!: constants.LabOrderResultType;

	@GandalfProperty()
	resultUnitId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Result Value cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	resultValue!: string | null;

	@GandalfValidator({ sizeString: { message: 'Specimen Disposition cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	specimenDisposition!: string | null;

	@GandalfProperty()
	specimenSourceId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfValidator({ sizeString: { message: 'Test Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	testName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	testPerformedDate!: Date | null;

	@GandalfProperty()
	testTypeId!: number | null;

	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version!: number;

}
