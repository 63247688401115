// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.category.CreateCategoryRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateCategoryRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Active is required' } })
	@GandalfProperty({ isRequired: true })
	active!: boolean;

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	description!: string | null;

	@GandalfConstantDecorator(constants.CategoryEntityType)
	@GandalfValidator({ notNull: { message: 'EntityType is required' } })
	@GandalfProperty({ isRequired: true })
	entityType!: constants.CategoryEntityType;

	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 50 characters', minLength: 1, maxLength: 50 } })
	@GandalfProperty({ isRequired: true })
	name!: string;

	@GandalfProperty()
	sortOrder!: number | null;

	@GandalfArray(CreateCategoryRequest)
	subCategoriesCreate!: CreateCategoryRequest[] | null;

}
