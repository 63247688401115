// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.openedge.OpenEdgeCreatePayFieldsTransactionRequest */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeCreatePayFieldsTransactionRequest extends GandalfModelBase {

	@GandalfLabel('Amount')
	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	amount!: number;

	@GandalfLabel('Payer')
	@GandalfProperty()
	payerId!: number | null;

	@GandalfLabel('Person')
	@GandalfProperty()
	personId!: number | null;

	@GandalfLabel('Practice Location')
	@GandalfValidator({ notNull: { message: 'Practice Location is required' } })
	@GandalfProperty({ isRequired: true })
	practiceLocationId!: number;

	@GandalfLabel('Save Credit Card')
	@GandalfValidator({ notNull: { message: 'Save Credit Card is required' } })
	@GandalfProperty({ isRequired: true })
	saveCreditCard!: boolean;

	@GandalfLabel('Temporary Token')
	@GandalfValidator({ notNull: { message: 'Temporary Token is required' } })
	@GandalfValidator({ sizeString: { min: 1, max: 100, message: 'Temporary Token must be between 1 and 100 characters' } })
	@GandalfProperty({ isRequired: true })
	temporaryToken!: string;

}
