// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { QueryFieldOptionResponse } from './query-field-option-response';

/** See com.rev360.pms.api.controller.query.QueryFieldResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryFieldResponse extends GandalfModelBase {

	@GandalfProperty()
	allowAll!: boolean | null;

	@GandalfProperty()
	criteria!: boolean | null;

	@GandalfConstantDecorator(constants.QueryFieldDataSource)
	@GandalfProperty()
	dataSource!: constants.QueryFieldDataSource | null;

	@GandalfConstantDecorator(constants.QueryFieldDataSourceList)
	@GandalfProperty()
	dataSourceList!: constants.QueryFieldDataSourceList | null;

	@GandalfProperty()
	id!: string | null;

	@GandalfProperty()
	label!: string | null;

	@GandalfArray(QueryFieldOptionResponse)
	options!: QueryFieldOptionResponse[] | null;

	@GandalfProperty()
	orderable!: boolean | null;

	@GandalfProperty()
	selectable!: boolean | null;

	@GandalfConstantDecorator(constants.QueryFieldType)
	@GandalfProperty()
	type!: constants.QueryFieldType | null;

}
