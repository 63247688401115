// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.patient.PatientMinerSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientMinerSearchResponse extends GandalfModelBase {

	@GandalfProperty()
	address!: string | null;

	@GandalfProperty()
	city!: string | null;

	@GandalfProperty()
	contactLensOdManufacturer!: string | null;

	@GandalfProperty()
	contactLensOdModel!: string | null;

	@GandalfProperty()
	contactLensOsManufacturer!: string | null;

	@GandalfProperty()
	contactLensOsModel!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dob!: Date | null;

	@GandalfProperty()
	email!: string | null;

	@GandalfProperty()
	eyeglassManufacturer!: string | null;

	@GandalfProperty()
	eyeglassModel!: string | null;

	@GandalfProperty()
	eyeglassOdCylinder!: number | null;

	@GandalfProperty()
	eyeglassOdSphere!: number | null;

	@GandalfProperty()
	eyeglassOsCylinder!: number | null;

	@GandalfProperty()
	eyeglassOsSphere!: number | null;

	@GandalfProperty()
	firstName!: string | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty()
	gender!: constants.Gender | null;

	@GandalfProperty()
	genderString!: string | null;

	@GandalfProperty()
	lastExamDate!: Date | null;

	@GandalfProperty()
	lastName!: string | null;

	@GandalfProperty()
	middleInitial!: string | null;

	@GandalfProperty()
	nextApptDate!: Date | null;

	@GandalfProperty()
	nextRecallDate!: Date | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	postalCode!: string | null;

	@GandalfProperty()
	practiceId!: number | null;

	@GandalfProperty()
	preferredPhoneNumber!: string | null;

	@GandalfProperty()
	primaryLocationId!: number | null;

	@GandalfProperty()
	stateProvince!: string | null;

	@GandalfProperty()
	suffix!: string | null;

	@GandalfProperty()
	title!: string | null;

}
