// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeOptionRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminEyefinityFeeOptionRequest extends GandalfModelBase {

	@GandalfLabel('Lens Addon Charge Back')
	@GandalfValidator({ notNull: { message: 'Lens Addon Charge Back is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	chargeBack!: number;

	@GandalfLabel('Lens Addon Code')
	@GandalfValidator({ notNull: { message: 'Lens Addon Code is required' } })
	@GandalfValidator({ sizeString: { message: 'Lens Addon Code must be between 1 and 2 characters', minLength: 1, maxLength: 2 } })
	@GandalfProperty({ isRequired: true })
	code!: string;

	@GandalfLabel('Fee Option')
	@GandalfProperty()
	eyefinityFeeOptionId!: number | null;

	@GandalfLabel('Lens Addon Name')
	@GandalfValidator({ notNull: { message: 'Lens Addon Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Lens Addon Name must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	optionName!: string;

	@GandalfConstantDecorator(constants.EyefinityFeeOptionType)
	@GandalfLabel('Lens Addon Type')
	@GandalfValidator({ notNull: { message: 'Lens Addon Type is required' } })
	@GandalfProperty({ isRequired: true })
	optionType!: constants.EyefinityFeeOptionType;

	@GandalfLabel('Lens Addon Patient Fee')
	@GandalfValidator({ notNull: { message: 'Lens Addon Patient Fee is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	patientFee!: number;

	@GandalfLabel('Lens Addon Patient %')
	@GandalfValidator({ min: { min: 0, message: 'Lens Addon Patient % must be between 0 and 100' } })
	@GandalfValidator({ max: { max: 100, message: 'Lens Addon Patient % must be between 0 and 100' } })
	@GandalfValidator({ notNull: { message: 'Lens Addon Patient % is required' } })
	@GandalfProperty({ isRequired: true })
	patientPercentageRetailCost!: number;

	@GandalfLabel('Lens Addon Service Fee')
	@GandalfValidator({ notNull: { message: 'Lens Addon Service Fee is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	serviceFee!: number;

}
