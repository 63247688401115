// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.inventory.product.ProductCategoryResponse */
/* istanbul ignore next */
@GandalfModel
export class ProductCategoryResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.ProductCategoryCatalog)
	@GandalfProperty()
	catalog!: constants.ProductCategoryCatalog | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfConstantDecorator(constants.MasterProductCategory)
	@GandalfProperty()
	masterCategory!: constants.MasterProductCategory | null;

	@GandalfProperty()
	name!: string | null;

}
