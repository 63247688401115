// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { TransferItemResponse } from './transfer-item-response';

/** See com.rev360.pms.api.controller.accounting.payment.PaymentTransferInvoiceItemsResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentTransferInvoiceItemsResponse extends GandalfModelBase {

	@GandalfProperty()
	includeAllItems!: boolean | null;

	@GandalfProperty()
	payerEntityId!: number | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType | null;

	@GandalfProperty()
	personInsuranceId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	transferAmount!: number | null;

	@GandalfArray(TransferItemResponse)
	transferItems!: TransferItemResponse[] | null;

	@GandalfProperty()
	transferReferenceId!: number | null;

	@GandalfProperty()
	transferToExistingInvoiceId!: number | null;

	@GandalfConstantDecorator(constants.TransferType)
	@GandalfProperty()
	transferType!: constants.TransferType | null;

	@GandalfProperty()
	writeoffReferenceId!: number | null;

}
