// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PaymentTransactionResponse } from './payment-transaction-response';

/** See com.rev360.pms.api.controller.accounting.payment.AccountingInvoicePaymentResponse */
/* istanbul ignore next */
@GandalfModel
export class AccountingInvoicePaymentResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount!: number | null;

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	paymentDate!: Date | null;

	@GandalfProperty()
	paymentGroupId!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty()
	paymentMethod!: constants.PaymentMethodType | null;

	@GandalfConstantDecorator(constants.PaymentMethodCreditCardType)
	@GandalfProperty()
	paymentMethodCardType!: constants.PaymentMethodCreditCardType | null;

	@GandalfProperty()
	paymentPayerName!: string | null;

	@GandalfConstantDecorator(constants.PaymentStatus)
	@GandalfProperty()
	paymentStatus!: constants.PaymentStatus | null;

	@GandalfProperty()
	paymentTransaction!: PaymentTransactionResponse | null;

	@GandalfProperty()
	practiceLocationId!: number | null;

	@GandalfProperty()
	referenceNumber!: string | null;

}
