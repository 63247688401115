// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.immunization.PatientVaccinationResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientVaccinationResponse extends GandalfModelBase {

	@GandalfProperty()
	administeredAmount!: number | null;

	@GandalfProperty()
	administeredAmountUnitId!: number | null;

	@GandalfProperty()
	administeredAtLocationId!: number | null;

	@GandalfProperty()
	administeredByEmployeeId!: number | null;

	@GandalfProperty()
	administeredOn!: Date | null;

	@GandalfProperty()
	administeredRouteId!: number | null;

	@GandalfProperty()
	administeredSiteId!: number | null;

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty()
	cvxDetailCode!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfProperty()
	notGiven!: boolean | null;

	@GandalfProperty()
	notGivenReasonId!: number | null;

	@GandalfProperty()
	orderedByProviderId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	substanceExpireDate!: Date | null;

	@GandalfProperty()
	substanceLot!: string | null;

	@GandalfProperty()
	substanceManufacturerId!: string | null;

	@GandalfProperty()
	vaccinationId!: number | null;

}
