// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionDiffResponse } from './field-value-multi-selection-diff-response';

/** See com.rev360.pms.api.controller.encounter.history.ros.ReviewOfSystemsDiffResponse */
/* istanbul ignore next */
@GandalfModel
export class ReviewOfSystemsDiffResponse extends GandalfModelBase {

	@GandalfProperty()
	cardiovascular!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	commentsFromIntake!: string | null;

	@GandalfProperty()
	constitutional!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	endocrine!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	ent!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	gastrointestinal!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	genitourinary!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	hasChanges!: boolean | null;

	@GandalfProperty()
	hasReviewOfSystemsFromIntake!: boolean | null;

	@GandalfProperty()
	hematLymphatic!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	immunological!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	musculoskeletal!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	neurological!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	psychological!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	respiratory!: FieldValueMultiSelectionDiffResponse | null;

	@GandalfProperty()
	skin!: FieldValueMultiSelectionDiffResponse | null;

}
