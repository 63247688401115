// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { EyefinityOrderItemResponse } from './eyefinity-order-item-response';

// @ts-ignore
import { EyefinityOrderResponse } from './eyefinity-order-response';

// @ts-ignore
import { EyeglassLensMeasurementResponse } from './eyeglass-lens-measurement-response';

// @ts-ignore
import { EyeglassLensOrderDetailResponse } from './eyeglass-lens-order-detail-response';

// @ts-ignore
import { FrameColorResponse } from './frame-color-response';

// @ts-ignore
import { FrameSizeResponse } from './frame-size-response';

// @ts-ignore
import { LocationProductForOrderResponse } from './location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './location-product-item-for-order-response';

// @ts-ignore
import { OrderEyefinityFeeScheduleResponse } from './order-eyefinity-fee-schedule-response';

// @ts-ignore
import { OrderEyeglassPrescriptionResponse } from './order-eyeglass-prescription-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.eyefinity.EyefinityEyeglassOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class EyefinityEyeglassOrderResponse extends EyefinityOrderResponse {

	@GandalfProperty()
	arCoating!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	associatedAuthorization!: string | null;

	@GandalfProperty()
	benefitContactLens!: boolean | null;

	@GandalfProperty()
	benefitExam!: boolean | null;

	@GandalfProperty()
	benefitFrame!: boolean | null;

	@GandalfProperty()
	benefitLens!: boolean | null;

	@GandalfProperty()
	bevelExternalIdentifier!: string | null;

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	copayExam!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	copayMaterials!: number | null;

	@GandalfProperty()
	createdOn!: Date | null;

	@GandalfProperty()
	distancePd!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate!: Date | null;

	@GandalfProperty()
	edge!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	encounter!: EncounterTableResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedTaxes!: number | null;

	@GandalfProperty()
	externalFrameSupplierIdentifier!: string | null;

	@GandalfProperty()
	externalFrameSupplierName!: string | null;

	@GandalfProperty()
	externalSupplierIdentifier!: string | null;

	@GandalfProperty()
	externalSupplierName!: string | null;

	@GandalfProperty()
	eyefinityFeeSchedule!: OrderEyefinityFeeScheduleResponse | null;

	@GandalfProperty()
	eyefinityOrderDetailId!: number | null;

	@GandalfArray(EyefinityOrderItemResponse)
	eyefinityOrderItems!: EyefinityOrderItemResponse[] | null;

	@GandalfProperty({ propertyType: 'Money' })
	frameAllowance!: number | null;

	@GandalfProperty()
	frameColor!: FrameColorResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	frameCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	frameRemakeCost!: number | null;

	@GandalfProperty()
	frameSize!: FrameSizeResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	frameWholesaleAllowance!: number | null;

	@GandalfProperty()
	glassCoating!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty()
	isValid!: boolean | null;

	@GandalfProperty()
	legacyAuthorizationIdentifier!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	lensCost!: number | null;

	@GandalfConstantDecorator(constants.EyefinityLensLocation)
	@GandalfProperty()
	lensEye!: constants.EyefinityLensLocation | null;

	@GandalfProperty()
	lensMaterialExternalIdentifier!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost!: number | null;

	@GandalfConstantDecorator(constants.OrderLensRequest)
	@GandalfProperty()
	lensRequest!: constants.OrderLensRequest | null;

	@GandalfProperty()
	lensStyle!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	lensTypeExternalIdentifier!: string | null;

	@GandalfProperty()
	lightDarkPercentage!: number | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	mirrorCoating!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	nearPd!: number | null;

	@GandalfProperty()
	nosePads!: boolean | null;

	@GandalfProperty()
	nosePadsText!: string | null;

	@GandalfProperty()
	notificationComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate!: Date | null;

	@GandalfProperty()
	odDetail!: EyeglassLensOrderDetailResponse | null;

	@GandalfProperty()
	odLensMeasurements!: EyeglassLensMeasurementResponse | null;

	@GandalfProperty()
	onHold!: boolean | null;

	@GandalfConstantDecorator(constants.OrderOpticalCenterType)
	@GandalfProperty()
	opticalCenterType!: constants.OrderOpticalCenterType | null;

	@GandalfConstantDecorator(constants.OrderFrameIndication)
	@GandalfProperty()
	orderFrameIndication!: constants.OrderFrameIndication | null;

	@GandalfProperty()
	orderFrameProduct!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	orderFrameProductItem!: LocationProductItemForOrderResponse | null;

	@GandalfConstantDecorator(constants.OrderFrameSource)
	@GandalfProperty()
	orderFrameSource!: constants.OrderFrameSource | null;

	@GandalfProperty()
	orderId!: number | null;

	@GandalfProperty()
	originalOrderId!: number | null;

	@GandalfConstantDecorator(constants.OriginalOrderReason)
	@GandalfProperty()
	originalOrderReason!: constants.OriginalOrderReason | null;

	@GandalfProperty()
	osDetail!: EyeglassLensOrderDetailResponse | null;

	@GandalfProperty()
	osLensMeasurements!: EyeglassLensMeasurementResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost!: number | null;

	@GandalfProperty()
	pantoscopicTilt!: number | null;

	@GandalfProperty()
	patient!: PatientNameResponse | null;

	@GandalfProperty()
	patientCommunication!: PatientCommunicationResponse | null;

	@GandalfProperty()
	patientNotified!: boolean | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfProperty()
	prescription!: OrderEyeglassPrescriptionResponse | null;

	@GandalfProperty()
	pressOn!: boolean | null;

	@GandalfProperty()
	pressOnText!: string | null;

	@GandalfProperty()
	processor!: OrderProcessorConfigResponse | null;

	@GandalfProperty()
	quantity!: number | null;

	@GandalfProperty()
	sampleExternalIdentifier!: string | null;

	@GandalfProperty()
	scratchCoating!: LocationProductForOrderResponse | null;

	@GandalfConstantDecorator(constants.OrderSegHeightType)
	@GandalfProperty()
	segHeightType!: constants.OrderSegHeightType | null;

	@GandalfProperty()
	shipAddress!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost!: number | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfProperty()
	shipToType!: constants.OrderShipToType | null;

	@GandalfConstantDecorator(constants.OrderShipmentCompany)
	@GandalfProperty()
	shippingCompany!: constants.OrderShipmentCompany | null;

	@GandalfProperty()
	slabOff!: boolean | null;

	@GandalfProperty()
	slabOffText!: string | null;

	@GandalfProperty()
	smartflowOrderIdentifier!: number | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty()
	statusCode!: constants.OrderStatusCode | null;

	@GandalfProperty()
	statusDate!: Date | null;

	@GandalfProperty()
	thicknessExternalIdentifier!: string | null;

	@GandalfProperty()
	tintColorExternalIdentifier!: string | null;

	@GandalfProperty()
	tintColorOther!: string | null;

	@GandalfProperty()
	tintType!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	trackingNumber!: string | null;

	@GandalfProperty()
	tray!: string | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty()
	type!: constants.OrderType | null;

	@GandalfProperty()
	uvCoating!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	validationMessage!: string | null;

	@GandalfProperty()
	vendor!: OrderVendorResponse | null;

	@GandalfProperty()
	vendorOrderIdentifier!: string | null;

	@GandalfProperty()
	version!: number | null;

	@GandalfProperty()
	wrapAngle!: number | null;

}
