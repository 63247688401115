// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.framesdata.CreateFramesDataSubscriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateFramesDataSubscriptionRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Subscriber Billing Zip Code is required' } })
	@GandalfValidator({ sizeString: { message: 'Subscriber Billing Zip Code must be between 1 and 20 characters', minLength: 1, maxLength: 20 } })
	@GandalfProperty({ isRequired: true })
	billingPostalCode!: string;

	@GandalfValidator({ notNull: { message: 'Contact is required' } })
	@GandalfProperty({ isRequired: true })
	internalContactEmployeeId!: number;

	@GandalfValidator({ notNull: { message: 'Number of Locations is required' } })
	@GandalfValidator({ min: { min: 1, message: 'Number of Locations must be greater than 0' } })
	@GandalfProperty({ isRequired: true })
	numberOfLocations!: number;

	@GandalfValidator({ notNull: { message: 'Subscriber Shipping Zip Code is required' } })
	@GandalfValidator({ sizeString: { message: 'Subscriber Shipping Zip Code must be between 1 and 20 characters', minLength: 1, maxLength: 20 } })
	@GandalfProperty({ isRequired: true })
	shippingPostalCode!: string;

	@GandalfValidator({ notNull: { message: 'Subscriber Username is required' } })
	@GandalfValidator({ sizeString: { message: 'Subscriber Username must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	username!: string;

}
