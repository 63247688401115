// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterBaudRateResponse } from './master-baud-rate-response';

// @ts-ignore
import { MasterDeviceResponse } from './master-device-response';

/** See com.rev360.pms.api.controller.shared.device.MasterSerialDeviceResponse */
/* istanbul ignore next */
@GandalfModel
export class MasterSerialDeviceResponse extends MasterDeviceResponse {

	@GandalfProperty()
	dataBits!: number;

	@GandalfProperty()
	dataTerminalReady!: boolean;

	@GandalfProperty()
	defaultBaudRate!: MasterBaudRateResponse;

	@GandalfProperty()
	description!: string | null;

	@GandalfConstantDecorator(constants.MasterDeviceKey)
	@GandalfProperty()
	deviceKey!: constants.MasterDeviceKey;

	@GandalfProperty()
	manufacturer!: string;

	@GandalfProperty()
	masterDeviceId!: number;

	@GandalfProperty()
	model!: string;

	@GandalfConstantDecorator(constants.Parity)
	@GandalfProperty()
	parity!: constants.Parity;

	@GandalfProperty()
	requestToSend!: boolean;

	@GandalfProperty()
	stopBits!: number;

	@GandalfArray(MasterBaudRateResponse)
	supportedBaudRates!: MasterBaudRateResponse[];

}
