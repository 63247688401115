// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.accounting.LedgerPostingReportRequest */
/* istanbul ignore next */
@GandalfModel
export class LedgerPostingReportRequest extends GandalfModelBase {

	@GandalfProperty()
	createdByEmployeeId!: number | null;

	@GandalfLabel('Invoice #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Invoice # may not be more than 18 digits' } })
	@GandalfProperty()
	invoiceId!: number | null;

	@GandalfLabel('Posting End Date')
	@GandalfValidator({ notNull: { message: 'Posting End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	ledgerPostingDateEnd!: Date;

	@GandalfLabel('Posting Start Date')
	@GandalfValidator({ notNull: { message: 'Posting Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	ledgerPostingDateStart!: Date;

	@GandalfConstantDecorator(constants.LedgerType)
	@GandalfProperty()
	ledgerType!: constants.LedgerType | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfLabel('Payer #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Payer # may not be more than 18 digits' } })
	@GandalfProperty()
	payerId!: number | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType | null;

}
