// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

/** See com.rev360.pms.api.controller.reporting.accounting.DepositsReportResponse */
/* istanbul ignore next */
@GandalfModel
export class DepositsReportResponse extends GandalfModelBase {

	@GandalfProperty()
	amount!: number | null;

	@GandalfConstantDecorator(constants.CreditCardType)
	@GandalfProperty()
	cardType!: constants.CreditCardType | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	date!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	groupPaymentDate!: Date | null;

	@GandalfProperty()
	groupReferenceNum!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	invoiceId!: number | null;

	@GandalfProperty()
	invoiceItemId!: number | null;

	@GandalfProperty()
	patient!: PatientNameResponse | null;

	@GandalfProperty()
	payerEntityId!: number | null;

	@GandalfProperty()
	payerName!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType | null;

	@GandalfProperty()
	paymentGroupId!: number | null;

	@GandalfProperty()
	practiceLocation!: PracticeLocationSummaryResponse | null;

	@GandalfProperty()
	provider!: PersonNameResponse | null;

	@GandalfProperty()
	quantity!: number | null;

	@GandalfProperty()
	typeString!: string | null;

	@GandalfProperty()
	typeStringCard!: string | null;

	@GandalfProperty()
	typeStringDetail!: string | null;

}
