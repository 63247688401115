// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.accounting.PracticeRemittanceCodeAdjustmentResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeRemittanceCodeAdjustmentResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.RemittanceAdjustmentGroupCode)
	@GandalfProperty()
	groupCode!: constants.RemittanceAdjustmentGroupCode | null;

	@GandalfProperty()
	isActive!: boolean;

	@GandalfProperty()
	isMasterCode!: boolean;

	@GandalfProperty()
	remittanceCodeAdjustmentDescription!: string | null;

	@GandalfProperty()
	remittanceCodeAdjustmentId!: number;

	@GandalfProperty()
	remittanceCodeAdjustmentReason!: ReferenceDataResponse | null;

	@GandalfConstantDecorator(constants.PaymentTransferMethodType)
	@GandalfProperty()
	remittanceCodeAdjustmentTransferType!: constants.PaymentTransferMethodType;

	@GandalfProperty()
	x12Code!: string;

}
