// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.encounter.EncounterTemplateListResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterTemplateListResponse extends GandalfModelBase {

	@GandalfProperty()
	abbreviation!: string | null;

	@GandalfProperty()
	displayColor!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	inClinic!: boolean | null;

	@GandalfProperty()
	includeInMuCalcs!: boolean | null;

	@GandalfProperty()
	interviewTemplateName!: string | null;

	@GandalfProperty()
	isComprehensive!: boolean | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfConstantDecorator(constants.TemplateStatus)
	@GandalfProperty()
	status!: constants.TemplateStatus | null;

	@GandalfProperty()
	totalDuration!: number | null;

}
