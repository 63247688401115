// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientSearchResponse } from './patient-search-response';

/** See com.rev360.pms.api.controller.patient.PatientHeuristicSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientHeuristicSearchResponse extends GandalfModelBase {

	@GandalfProperty()
	moreResults!: boolean | null;

	@GandalfArray(PatientSearchResponse)
	patients!: PatientSearchResponse[];

}
