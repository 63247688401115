// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.ConnectMessageForPatientAppointmentRequest */
/* istanbul ignore next */
@GandalfModel
export class ConnectMessageForPatientAppointmentRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: '{jakarta.validation.constraints.NotNull.message}' } })
	@GandalfProperty({ isRequired: true })
	appointmentId!: number;

	@GandalfConstantDecorator(constants.ConnectMessageMethod)
	@GandalfValidator({ notNull: { message: '{jakarta.validation.constraints.NotNull.message}' } })
	@GandalfProperty({ isRequired: true })
	method!: constants.ConnectMessageMethod;

	@GandalfValidator({ notNull: { message: '{jakarta.validation.constraints.NotNull.message}' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

}
