// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

/** See com.rev360.pms.api.controller.drug.PracticeDrugListResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeDrugListResponse extends GandalfModelBase {

	@GandalfProperty()
	dispensingAmount!: number | null;

	@GandalfProperty()
	dispensingUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	doNotSubstitute!: boolean | null;

	@GandalfProperty()
	dosageAmount!: number | null;

	@GandalfProperty()
	dosageUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	drugId!: number | null;

	@GandalfProperty()
	drugName!: string | null;

	@GandalfProperty()
	durationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	frequency!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty()
	numRefillsAllowed!: number | null;

	@GandalfProperty()
	practiceDrugId!: number | null;

	@GandalfProperty()
	route!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	shortName!: string | null;

}
