// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PreferenceResponse } from './preference-response';

/** See com.rev360.pms.api.controller.admin.practicepreferences.SystemPreferencesResponse */
/* istanbul ignore next */
@GandalfModel
export class SystemPreferencesResponse extends GandalfModelBase {

	@GandalfArray(PreferenceResponse)
	additionalPreferences!: PreferenceResponse[] | null;

	@GandalfProperty()
	autoLockPeriod!: number | null;

	@GandalfProperty()
	expirationDays!: number | null;

	@GandalfProperty()
	minDigits!: number | null;

	@GandalfProperty()
	minLength!: number | null;

	@GandalfProperty()
	minLowerCase!: number | null;

	@GandalfProperty()
	minSpecialChars!: number | null;

	@GandalfProperty()
	minUpperCase!: number | null;

	@GandalfProperty()
	numPasswordChanges!: number | null;

	@GandalfProperty()
	passwordExpires!: boolean | null;

}
