// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.accounting.claims.ClaimsInsuranceClaimResponse */
/* istanbul ignore next */
@GandalfModel
export class ClaimsInsuranceClaimResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amountDue!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	claimDate!: Date;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty()
	externalMessage!: string | null;

	@GandalfProperty()
	id!: number;

	@GandalfProperty()
	insuranceName!: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	invoiceDate!: Date | null;

	@GandalfProperty()
	invoiceId!: number | null;

	@GandalfProperty()
	patientId!: number;

	@GandalfProperty()
	patientName!: PatientNameResponse;

	@GandalfProperty()
	priority!: string | null;

	@GandalfConstantDecorator(constants.ClaimProcessingStatus)
	@GandalfProperty()
	processingStatus!: constants.ClaimProcessingStatus;

	@GandalfProperty()
	secondaryPayerPracticeInsuranceCompanyName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDate!: Date | null;

	@GandalfConstantDecorator(constants.InsuranceClaimStatus)
	@GandalfProperty()
	status!: constants.InsuranceClaimStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	submitDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimSubmitMechanism)
	@GandalfProperty()
	submitMechanism!: constants.ClaimSubmitMechanism;

}
