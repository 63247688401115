// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.CancelAppointmentRequest */
/* istanbul ignore next */
@GandalfModel
export class CancelAppointmentRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Appointment Id must not be null' } })
	@GandalfProperty({ isRequired: true })
	appointmentId!: number;

	@GandalfValidator({ notNull: { message: 'Cancel Future Recurring Appointments is required' } })
	@GandalfProperty({ isRequired: true })
	cancelFutureRecurringAppointments!: boolean;

	@GandalfProperty()
	patientInitiated!: boolean | null;

	@GandalfValidator({ notNull: { message: 'Cancellation Reason is required' } })
	@GandalfValidator({ sizeString: { message: 'Cancel Reason must be between 1 and 4000 characters', minLength: 1, maxLength: 4000 } })
	@GandalfProperty({ isRequired: true })
	reason!: string;

}
