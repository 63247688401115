// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.payment.PaymentDashboardResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentDashboardResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount!: number;

	@GandalfProperty()
	payerName!: string;

	@GandalfProperty({ propertyType: 'LocalDate' })
	paymentDate!: Date;

	@GandalfProperty()
	paymentGroupId!: number;

	@GandalfConstantDecorator(constants.PaymentGroupSourceTypeSearch)
	@GandalfProperty()
	paymentGroupSourceTypeSearch!: constants.PaymentGroupSourceTypeSearch;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty()
	paymentMethodType!: constants.PaymentMethodType;

	@GandalfConstantDecorator(constants.PaymentGroupStatus)
	@GandalfProperty()
	paymentStatus!: constants.PaymentGroupStatus;

	@GandalfProperty()
	referenceNumber!: string | null;

	@GandalfConstantDecorator(constants.PaymentGroupSourceType)
	@GandalfProperty()
	sourceType!: constants.PaymentGroupSourceType;

}
