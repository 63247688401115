// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.general.location.device.AdminCreatePracticeLocationSerialDeviceRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminCreatePracticeLocationSerialDeviceRequest extends GandalfModelBase {

	@GandalfLabel('Baud Rate')
	@GandalfValidator({ notNull: { message: 'Baud Rate is required' } })
	@GandalfProperty({ isRequired: true })
	baudRateId!: number;

	@GandalfLabel('Disable Preview')
	@GandalfValidator({ notNull: { message: 'Disable Preview is required' } })
	@GandalfProperty({ isRequired: true })
	disablePreview!: boolean;

	@GandalfLabel('Name')
	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	displayName!: string;

	@GandalfLabel('Device')
	@GandalfValidator({ notNull: { message: 'Device is required' } })
	@GandalfProperty({ isRequired: true })
	masterDeviceId!: number;

	@GandalfLabel('Location')
	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	practiceLocationId!: number;

}
