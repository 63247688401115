// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.OrderStatusResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderStatusResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty()
	code!: constants.OrderStatusCode | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	endDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	startDate!: Date | null;

	@GandalfProperty()
	updatedBy!: string | null;

}
