// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.accounting.DepositSlipSearchResponse */
/* istanbul ignore next */
@GandalfModel
export class DepositSlipSearchResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	amount!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodCreditCardType)
	@GandalfProperty()
	cardType!: constants.PaymentMethodCreditCardType | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	payerName!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType | null;

	@GandalfProperty()
	paymentDate!: Date | null;

	@GandalfProperty()
	paymentId!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty()
	paymentMethod!: constants.PaymentMethodType | null;

	@GandalfProperty()
	referenceNum!: string | null;

}
