// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PropertyValuesResponse } from './property-values-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.inventory.ContactLensPropertyValuesResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensPropertyValuesResponse extends GandalfModelBase {

	@GandalfProperty()
	add!: PropertyValuesResponse | null;

	@GandalfArray(ReferenceDataResponse)
	addDesignations!: ReferenceDataResponse[] | null;

	@GandalfProperty()
	axis!: PropertyValuesResponse | null;

	@GandalfProperty()
	baseCurve!: PropertyValuesResponse | null;

	@GandalfProperty()
	cylinder!: PropertyValuesResponse | null;

	@GandalfProperty()
	diameter!: PropertyValuesResponse | null;

	@GandalfProperty()
	sphere!: PropertyValuesResponse | null;

}
