// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.accounting.TransferPatientCreditRequest */
/* istanbul ignore next */
@GandalfModel
export class TransferPatientCreditRequest extends GandalfModelBase {

	@GandalfLabel('Amount')
	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfValidator({ moneyConstraints: { positiveAllowed: true, zeroAllowed: false, negativeAllowed: false, message: 'Amount must be positive' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	amount!: number;

	@GandalfLabel('Destination Patient')
	@GandalfValidator({ notNull: { message: 'Destination Patient is required' } })
	@GandalfProperty({ isRequired: true })
	destinationPatientId!: number;

	@GandalfLabel('Source Patient')
	@GandalfValidator({ notNull: { message: 'Source Patient is required' } })
	@GandalfProperty({ isRequired: true })
	sourcePatientId!: number;

}
