// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

/** See com.rev360.pms.api.controller.reporting.accounting.RefundsReportResponse */
/* istanbul ignore next */
@GandalfModel
export class RefundsReportResponse extends GandalfModelBase {

	@GandalfProperty()
	amount!: number | null;

	@GandalfProperty()
	entityId!: number | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType | null;

	@GandalfProperty()
	practiceLocation!: PracticeLocationSummaryResponse | null;

	@GandalfProperty()
	provider!: PersonNameResponse | null;

	@GandalfProperty()
	quantity!: number | null;

	@GandalfProperty()
	referenceNumber!: string | null;

	@GandalfConstantDecorator(constants.RefundFrom)
	@GandalfProperty()
	refundFrom!: constants.RefundFrom | null;

	@GandalfProperty()
	refundId!: number | null;

}
