// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.vendor.VendorLocationConfigResponse */
/* istanbul ignore next */
@GandalfModel
export class VendorLocationConfigResponse extends GandalfModelBase {

	@GandalfProperty()
	accountNumber!: string | null;

	@GandalfProperty()
	externalLocationId!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	password!: string | null;

	@GandalfProperty()
	practiceLocationId!: number | null;

	@GandalfProperty()
	practiceLocationName!: string | null;

	@GandalfProperty()
	userName!: string | null;

}
