// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { ScheduleItemCalendarResponse } from './schedule-item-calendar-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.calendar.AppointmentSlotCalendarResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentSlotCalendarResponse extends ScheduleItemCalendarResponse {

	@GandalfProperty()
	displayColor!: number | null;

	@GandalfProperty()
	employee!: EmployeeNameResponse | null;

	@GandalfArray(String)
	encounterTemplateNames!: string[] | null;

	@GandalfProperty()
	endDate!: Date | null;

	@GandalfProperty()
	groupingPersonId!: number | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	relatedScheduleItemId!: number | null;

	@GandalfProperty()
	roleId!: number | null;

	@GandalfProperty()
	roleName!: string | null;

	@GandalfProperty()
	scheduleItemId!: number | null;

	@GandalfProperty()
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty()
	status!: constants.ScheduleItemStatus | null;

	@GandalfConstantDecorator(constants.ScheduleItemType)
	@GandalfProperty()
	type!: constants.ScheduleItemType | null;

	@GandalfProperty()
	updatedOn!: Date | null;

}
