// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UserSummaryResponse } from './user-summary-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.AppointmentInsuranceVerificationResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentInsuranceVerificationResponse extends GandalfModelBase {

	@GandalfProperty()
	insuranceVerificationComment!: string | null;

	@GandalfConstantDecorator(constants.VerificationStatus)
	@GandalfProperty()
	status!: constants.VerificationStatus | null;

	@GandalfProperty()
	verifiedBy!: UserSummaryResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	verifiedOn!: Date | null;

}
