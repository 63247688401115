// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.waitinglist.CreateWaitingListRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateWaitingListRequest extends GandalfModelBase {

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	comments!: string | null;

	@GandalfLabel('End Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfLabel('Location')
	@GandalfProperty()
	locationId!: number | null;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfConstantDecorator(constants.WaitListPriority)
	@GandalfLabel('Priority')
	@GandalfValidator({ notNull: { message: 'Priority is required' } })
	@GandalfProperty({ isRequired: true })
	priority!: constants.WaitListPriority;

	@GandalfLabel('Provider')
	@GandalfProperty()
	providerId!: number | null;

	@GandalfLabel('Type')
	@GandalfValidator({ notNull: { message: 'Type is required' } })
	@GandalfProperty({ isRequired: true })
	templateId!: number;

}
