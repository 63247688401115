// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.common.AddressResponse */
/* istanbul ignore next */
@GandalfModel
export class AddressResponse extends GandalfModelBase {

	@GandalfProperty()
	address1!: string | null;

	@GandalfProperty()
	address2!: string | null;

	@GandalfProperty()
	city!: string | null;

	@GandalfProperty()
	postalCode!: string | null;

	@GandalfProperty()
	stateProvince!: string | null;

}
