// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.claims.AdditionalClaimInformationResponse */
/* istanbul ignore next */
@GandalfModel
export class AdditionalClaimInformationResponse extends GandalfModelBase {

	@GandalfProperty()
	alternateServiceLocationId!: number | null;

	@GandalfProperty()
	attachmentControlNumber!: string | null;

	@GandalfConstantDecorator(constants.ClaimAttachmentTransmission)
	@GandalfProperty()
	attachmentTransmission!: constants.ClaimAttachmentTransmission | null;

	@GandalfConstantDecorator(constants.ClaimAttachmentType)
	@GandalfProperty()
	attachmentType!: constants.ClaimAttachmentType | null;

	@GandalfProperty()
	authorizationNumber!: string | null;

	@GandalfConstantDecorator(constants.ClaimFrequencyCode)
	@GandalfProperty()
	claimFrequencyCode!: constants.ClaimFrequencyCode | null;

	@GandalfProperty()
	cliaNumber!: string | null;

	@GandalfConstantDecorator(constants.ClaimVisionConditionApply)
	@GandalfProperty()
	conditionContactsApply!: constants.ClaimVisionConditionApply | null;

	@GandalfProperty()
	conditionContactsL1!: boolean | null;

	@GandalfProperty()
	conditionContactsL2!: boolean | null;

	@GandalfProperty()
	conditionContactsL3!: boolean | null;

	@GandalfProperty()
	conditionContactsL4!: boolean | null;

	@GandalfProperty()
	conditionContactsL5!: boolean | null;

	@GandalfConstantDecorator(constants.ClaimVisionConditionApply)
	@GandalfProperty()
	conditionFramesApply!: constants.ClaimVisionConditionApply | null;

	@GandalfProperty()
	conditionFramesL1!: boolean | null;

	@GandalfProperty()
	conditionFramesL2!: boolean | null;

	@GandalfProperty()
	conditionFramesL3!: boolean | null;

	@GandalfProperty()
	conditionFramesL4!: boolean | null;

	@GandalfProperty()
	conditionFramesL5!: boolean | null;

	@GandalfConstantDecorator(constants.ClaimVisionConditionApply)
	@GandalfProperty()
	conditionLensesApply!: constants.ClaimVisionConditionApply | null;

	@GandalfProperty()
	conditionLensesL1!: boolean | null;

	@GandalfProperty()
	conditionLensesL2!: boolean | null;

	@GandalfProperty()
	conditionLensesL3!: boolean | null;

	@GandalfProperty()
	conditionLensesL4!: boolean | null;

	@GandalfProperty()
	conditionLensesL5!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	currentIllnessDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimCurrentIllnessDateQualifier)
	@GandalfProperty()
	currentIllnessDateQualifier!: constants.ClaimCurrentIllnessDateQualifier | null;

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfProperty()
	diagnosisCodeSet!: constants.CodeSet;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endCareDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtApply)
	@GandalfProperty()
	epsdtApply!: constants.ClaimEpsdtApply | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtCode)
	@GandalfProperty()
	epsdtCode1!: constants.ClaimEpsdtCode | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtCode)
	@GandalfProperty()
	epsdtCode2!: constants.ClaimEpsdtCode | null;

	@GandalfConstantDecorator(constants.ClaimEpsdtCode)
	@GandalfProperty()
	epsdtCode3!: constants.ClaimEpsdtCode | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	hospitalizationEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	hospitalizationStartDate!: Date | null;

	@GandalfProperty()
	id!: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	initialTreatmentDate!: Date | null;

	@GandalfProperty()
	invoiceId!: number;

	@GandalfProperty()
	localUse!: string | null;

	@GandalfProperty()
	localUse10d!: string | null;

	@GandalfProperty()
	medicaidResubmitCode!: string | null;

	@GandalfProperty()
	orderingProviderId!: number | null;

	@GandalfProperty()
	originalReferenceNumber!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	outOfWorkEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	outOfWorkStartDate!: Date | null;

	@GandalfProperty()
	outsideLab!: boolean;

	@GandalfProperty({ propertyType: 'Money' })
	outsideLabCharges!: number;

	@GandalfProperty()
	priorAuthorizationNumber!: string | null;

	@GandalfProperty()
	referralNumber!: string | null;

	@GandalfProperty()
	referringProviderId!: number | null;

	@GandalfProperty()
	secondaryPayerId!: number | null;

	@GandalfProperty()
	signatureHandwritten!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	similarIllnessDate!: Date | null;

	@GandalfConstantDecorator(constants.ClaimSimilarIllnessDateQualifier)
	@GandalfProperty()
	similarIllnessDateQualifier!: constants.ClaimSimilarIllnessDateQualifier | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startCareDate!: Date | null;

	@GandalfProperty()
	version!: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	visionPrescriptionDate!: Date | null;

}
