// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.general.location.DailyOfficeHoursAdminResponse */
/* istanbul ignore next */
@GandalfModel
export class DailyOfficeHoursAdminResponse extends GandalfModelBase {

	@GandalfProperty()
	closeTime!: Date | null;

	@GandalfProperty()
	lunchEndTime!: Date | null;

	@GandalfProperty()
	lunchStartTime!: Date | null;

	@GandalfProperty()
	openTime!: Date | null;

}
