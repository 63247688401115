// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.recalls.PatientRecallResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientRecallResponse extends GandalfModelBase {

	@GandalfProperty()
	appointmentDate!: Date | null;

	@GandalfProperty()
	appointmentId!: number | null;

	@GandalfProperty()
	appointmentLocation!: string | null;

	@GandalfProperty()
	appointmentLocationId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	recallDate!: Date | null;

	@GandalfProperty()
	recallId!: number | null;

	@GandalfProperty()
	recallLocationId!: number | null;

	@GandalfProperty()
	recallLocationName!: string | null;

	@GandalfProperty()
	recallReason!: string | null;

	@GandalfConstantDecorator(constants.RecallStatus)
	@GandalfProperty()
	status!: constants.RecallStatus | null;

}
