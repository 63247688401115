// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.person.AllergyResponse */
/* istanbul ignore next */
@GandalfModel
export class AllergyResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	deactivationDate!: Date | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfProperty()
	externalId!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfArray(Number)
	reactionIds!: number[] | null;

	@GandalfArray(ReferenceDataResponse)
	reactions!: ReferenceDataResponse[] | null;

	@GandalfConstantDecorator(constants.AllergySeverity)
	@GandalfProperty()
	severity!: constants.AllergySeverity | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.AllergyStatus)
	@GandalfProperty()
	status!: constants.AllergyStatus | null;

	@GandalfProperty()
	treatment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	updatedDate!: Date | null;

}
