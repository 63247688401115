// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.ContactLensPrescriptionLookupResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensPrescriptionLookupResponse extends GandalfModelBase {

	@GandalfProperty()
	addDesignationOd!: string | null;

	@GandalfProperty()
	addDesignationOs!: string | null;

	@GandalfProperty()
	addPowerOd!: number | null;

	@GandalfProperty()
	addPowerOs!: number | null;

	@GandalfProperty()
	authorizedBy!: string | null;

	@GandalfProperty()
	axisOd!: number | null;

	@GandalfProperty()
	axisOs!: number | null;

	@GandalfProperty()
	baseCurveOd!: number | null;

	@GandalfProperty()
	baseCurveOs!: number | null;

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty()
	cylinderOd!: number | null;

	@GandalfProperty()
	cylinderOs!: number | null;

	@GandalfProperty()
	diameterOd!: number | null;

	@GandalfProperty()
	diameterOs!: number | null;

	@GandalfProperty()
	isMonovisionOd!: boolean | null;

	@GandalfProperty()
	isMonovisionOs!: boolean | null;

	@GandalfProperty()
	isTrial!: boolean | null;

	@GandalfProperty()
	modelOd!: string | null;

	@GandalfProperty()
	modelOs!: string | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty()
	overallLensType!: constants.ContactLensPrescriptionType | null;

	@GandalfConstantDecorator(constants.RxDisplayStatusLegacy)
	@GandalfProperty()
	rxDisplayStatus!: constants.RxDisplayStatusLegacy | null;

	@GandalfProperty()
	rxId!: number | null;

	@GandalfProperty()
	sphereOd!: number | null;

	@GandalfProperty()
	sphereOs!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

}
