// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { IntakeMedicationListResponse } from './intake-medication-list-response';

/** See com.rev360.pms.api.controller.prescription.IntakeMedicationResponse */
/* istanbul ignore next */
@GandalfModel
export class IntakeMedicationResponse extends GandalfModelBase {

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateReported!: Date | null;

	@GandalfProperty()
	intakeId!: number | null;

	@GandalfArray(IntakeMedicationListResponse)
	intakeMedications!: IntakeMedicationListResponse[] | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty()
	medicationCommentsReconciledStatus!: constants.IntakeReconciledStatus | null;

	@GandalfProperty()
	noKnownMedications!: boolean | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty()
	noKnownMedicationsReconciledStatus!: constants.IntakeReconciledStatus | null;

}
