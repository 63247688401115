// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.prescription.ContactLensEyeRequest */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeRequest extends GandalfModelBase {

	@GandalfProperty()
	addPower!: number | null;

	@GandalfProperty()
	axis!: number | null;

	@GandalfProperty()
	baseCurve!: number | null;

	@GandalfProperty()
	cylinder!: number | null;

	@GandalfProperty()
	diameter!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isMonovision!: boolean | null;

	@GandalfProperty()
	locationProductId!: number | null;

	@GandalfProperty()
	locationProductItemId!: number | null;

	@GandalfProperty()
	overnightDurationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	overnightDurationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	productColorId!: number | null;

	@GandalfProperty()
	replacementDurationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	replacementDurationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	sphere!: number | null;

	@GandalfProperty()
	version!: number | null;

}
