// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemTemplateResponse } from './care-plan-item-template-response';

// @ts-ignore
import { EncounterTemplateResponse } from './encounter-template-response';

/** See com.rev360.pms.api.controller.admin.dataconfiguration.careplan.CarePlanItemTemplateRecallResponse */
/* istanbul ignore next */
@GandalfModel
export class CarePlanItemTemplateRecallResponse extends CarePlanItemTemplateResponse {

	@GandalfProperty()
	carePlanItemTemplateId!: number | null;

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	encounterTemplate!: EncounterTemplateResponse | null;

	@GandalfProperty()
	isCheckoutTask!: boolean | null;

	@GandalfProperty()
	recallAmount!: number | null;

	@GandalfConstantDecorator(constants.CarePlanItemRecallDuration)
	@GandalfProperty()
	recallUnit!: constants.CarePlanItemRecallDuration | null;

}
