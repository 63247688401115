// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FileInfoResponse } from './file-info-response';

/** See com.rev360.pms.api.controller.file.PatientFileInfoResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientFileInfoResponse extends FileInfoResponse {

	@GandalfProperty()
	fileId!: number | null;

	@GandalfProperty()
	fileName!: string | null;

	@GandalfProperty()
	mimeType!: string | null;

	@GandalfProperty()
	patientFileId!: number | null;

}
