// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.CreateAppointmentRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateAppointmentRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty()
	employeeId!: number | null;

	@GandalfValidator({ notNull: { message: 'End Time is required' } })
	@GandalfProperty({ isRequired: true })
	endDate!: Date;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId!: number;

	@GandalfProperty()
	overrideValidation!: boolean | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfProperty()
	providerId!: number | null;

	@GandalfArray(Number)
	recurrenceSlotIds!: number[] | null;

	@GandalfProperty()
	roleId!: number | null;

	@GandalfProperty()
	selectedSlotId!: number | null;

	@GandalfValidator({ notNull: { message: 'Start Time is required' } })
	@GandalfProperty({ isRequired: true })
	startDate!: Date;

	@GandalfProperty()
	subTypeId!: number | null;

	@GandalfValidator({ notNull: { message: 'Template is required' } })
	@GandalfProperty({ isRequired: true })
	templateId!: number;

}
