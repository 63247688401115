// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminEyefinityFeeOptionResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminEyefinityFeeOptionResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	chargeBack!: number | null;

	@GandalfProperty()
	code!: string | null;

	@GandalfProperty()
	eyefinityFeeOptionId!: number | null;

	@GandalfProperty()
	optionName!: string | null;

	@GandalfConstantDecorator(constants.EyefinityFeeOptionType)
	@GandalfProperty()
	optionType!: constants.EyefinityFeeOptionType | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientFee!: number | null;

	@GandalfProperty()
	patientPercentageRetailCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	serviceFee!: number | null;

}
