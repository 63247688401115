// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { LocationProductForOrderResponse } from './location-product-for-order-response';

// @ts-ignore
import { LocationProductItemForOrderResponse } from './location-product-item-for-order-response';

/** See com.rev360.pms.api.controller.order.OrderProductOptionResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderProductOptionResponse extends GandalfModelBase {

	@GandalfProperty()
	locationProduct!: LocationProductForOrderResponse | null;

	@GandalfProperty()
	locationProductItem!: LocationProductItemForOrderResponse | null;

	@GandalfProperty()
	value!: string | null;

}
