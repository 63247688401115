// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.externaldata.CreateExternalDataRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateExternalDataRequest extends GandalfModelBase {

	@GandalfLabel('Comments')
	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comments!: string | null;

	@GandalfConstantDecorator(constants.ExternalDataSource)
	@GandalfLabel('Source')
	@GandalfValidator({ notNull: { message: 'Source is required' } })
	@GandalfProperty({ isRequired: true })
	externalDataSource!: constants.ExternalDataSource;

	@GandalfLabel('URL')
	@GandalfValidator({ sizeString: { message: 'URL cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	externalDataSourceText!: string | null;

	@GandalfLabel('Name')
	@GandalfValidator({ notNull: { message: 'Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Name must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	name!: string;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfLabel('Request Date')
	@GandalfProperty({ propertyType: 'LocalDate' })
	requestDate!: Date | null;

	@GandalfConstantDecorator(constants.ExternalDataStatus)
	@GandalfLabel('Status')
	@GandalfValidator({ notNull: { message: 'Status is required' } })
	@GandalfProperty({ isRequired: true })
	status!: constants.ExternalDataStatus;

}
