// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.general.CustomerAgreementsResponse */
/* istanbul ignore next */
@GandalfModel
export class CustomerAgreementsResponse extends GandalfModelBase {

	@GandalfProperty()
	baaComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	baaSignByDate!: Date | null;

	@GandalfProperty()
	baaSignedByFirstName!: string | null;

	@GandalfProperty()
	baaSignedByLastName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	baaSignedOnDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	licenseSignByDate!: Date | null;

	@GandalfProperty()
	licenseSignedByFirstName!: string | null;

	@GandalfProperty()
	licenseSignedByLastName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	licenseSignedOnDate!: Date | null;

}
