// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.pharmacy.PharmacyResponse */
/* istanbul ignore next */
@GandalfModel
export class PharmacyResponse extends GandalfModelBase {

	@GandalfProperty()
	address!: AddressResponse | null;

	@GandalfProperty()
	email!: string | null;

	@GandalfProperty()
	faxNumber!: string | null;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	mainPhone!: string | null;

	@GandalfProperty()
	ncpdp!: string | null;

	@GandalfProperty()
	npi!: string | null;

	@GandalfProperty()
	pharmacyBrandId!: number | null;

	@GandalfProperty()
	pharmacyBrandName!: string | null;

	@GandalfProperty()
	pharmacyId!: number | null;

	@GandalfProperty()
	webSite!: string | null;

}
