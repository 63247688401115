// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.PatientSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientSearchRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { fieldLabel: 'Address', maxLength: 1000, minLength: 0, message: 'Address must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	address!: string | null;

	@GandalfValidator({ sizeString: { message: 'Alternate Id cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	alternateId!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateOfBirth!: Date | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Email', maxLength: 1000, minLength: 0, message: 'Email must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	email!: string | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'First name', maxLength: 1000, minLength: 0, message: 'First name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	firstName!: string | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Last name', maxLength: 1000, minLength: 0, message: 'Last name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	lastName!: string | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfValidator({ max: { max: 9223372036854775807, message: 'ID may not be more than 19 digits' } })
	@GandalfProperty()
	patientId!: number | null;

	@GandalfConstantDecorator(constants.PatientSearchStatus)
	@GandalfProperty()
	patientSearchStatus!: constants.PatientSearchStatus | null;

	@GandalfValidator({ phoneNumber: { message: 'Phone number must contain at least 10 digits' } })
	@GandalfProperty()
	phoneNumber!: string | null;

	@GandalfProperty()
	providerId!: number | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'SIN/PHN', maxLength: 1000, minLength: 0, message: 'SIN/PHN must be less than or equal to 1000 characters' } })
	@GandalfValidator({ sin: { message: 'SIN/PHN must only contain alphanumeric or dash characters' } })
	@GandalfProperty()
	socialInsuranceNumber!: string | null;

	@GandalfValidator({ sizeString: { fieldLabel: 'Social security number', maxLength: 1000, minLength: 0, message: 'Social security number must be less than or equal to 1000 characters' } })
	@GandalfValidator({ socialSecurityNumber: { message: 'Social security number must contain 9 digits' } })
	@GandalfProperty()
	socialSecurityNumber!: string | null;

}
