// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.AddAdhocInvoiceItemRequest */
/* istanbul ignore next */
@GandalfModel
export class AddAdhocInvoiceItemRequest extends GandalfModelBase {

	@GandalfLabel('Code')
	@GandalfValidator({ sizeString: { message: 'Code cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	code!: string | null;

	@GandalfLabel('Description')
	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	description!: string;

	@GandalfLabel('Invoice')
	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId!: number;

	@GandalfLabel('Person')
	@GandalfValidator({ notNull: { message: 'Person is required' } })
	@GandalfProperty({ isRequired: true })
	personId!: number;

	@GandalfLabel('Post Date')
	@GandalfValidator({ notNull: { message: 'Post Date is required' } })
	@GandalfProperty({ isRequired: true })
	postDate!: Date;

	@GandalfLabel('Quantity')
	@GandalfValidator({ notNull: { message: 'Quantity is required' } })
	@GandalfValidator({ max: { max: 999, message: 'Quantity may not be more than 999' } })
	@GandalfProperty({ isRequired: true })
	quantity!: number;

	@GandalfLabel('Tax')
	@GandalfProperty()
	taxId!: number | null;

	@GandalfLabel('Unit Price')
	@GandalfValidator({ moneyConstraints: { positiveAllowed: true, zeroAllowed: true, negativeAllowed: false, message: 'Unit Price cannot be negative' } })
	@GandalfValidator({ notNull: { message: 'Unit Price is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	unitPrice!: number;

}
