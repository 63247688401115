// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PatientEmploymentResponse } from './patient-employment-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.PatientDashboardSummaryResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientDashboardSummaryResponse extends GandalfModelBase {

	@GandalfProperty()
	allowLogin!: boolean | null;

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty()
	dateOfBirth!: Date | null;

	@GandalfProperty()
	dominantEye!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	dominantHand!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	email!: string | null;

	@GandalfProperty()
	emailValid!: boolean | null;

	@GandalfProperty()
	employment!: PatientEmploymentResponse | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty()
	gender!: constants.Gender | null;

	@GandalfProperty()
	guarantorId!: number | null;

	@GandalfProperty()
	hasFullSsn!: boolean | null;

	@GandalfProperty()
	homeAddress!: AddressResponse | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isOnWaitList!: boolean | null;

	@GandalfProperty()
	last4ssn!: string | null;

	@GandalfProperty()
	name!: PatientNameResponse | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfProperty()
	phoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty()
	photoId!: number | null;

	@GandalfProperty()
	primaryLocation!: PracticeLocationSummaryResponse | null;

	@GandalfProperty()
	primaryProvider!: ProviderResponse | null;

	@GandalfProperty()
	sin!: string | null;

	@GandalfConstantDecorator(constants.PatientStatus)
	@GandalfProperty()
	status!: constants.PatientStatus | null;

	@GandalfProperty()
	tempAddress!: AddressResponse | null;

	@GandalfProperty()
	userName!: string | null;

}
