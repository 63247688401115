// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.InvoiceDiagnosesRequest */
/* istanbul ignore next */
@GandalfModel
export class InvoiceDiagnosesRequest extends GandalfModelBase {

	@GandalfLabel('Invoice')
	@GandalfValidator({ notNull: { message: 'Invoice is required' } })
	@GandalfProperty({ isRequired: true })
	invoiceId!: number;

	@GandalfLabel('Diagnoses')
	@GandalfValidator({ notNull: { message: 'Diagnoses is required' } })
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'At least 1 Diagnosis must be selected' } })
	@GandalfArray(Number)
	personDiagnosisIds!: number[];

}
