// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldValueMultiSelectionResponse } from './field-value-multi-selection-response';

// @ts-ignore
import { FieldValueSelectionResponse } from './field-value-selection-response';

/** See com.rev360.pms.api.controller.encounter.history.presentillness.PresentIllnessResponse */
/* istanbul ignore next */
@GandalfModel
export class PresentIllnessResponse extends GandalfModelBase {

	@GandalfProperty()
	contextSelection!: FieldValueSelectionResponse | null;

	@GandalfProperty()
	contextText!: string | null;

	@GandalfProperty()
	diagnosisText!: string | null;

	@GandalfProperty()
	durationAmount!: number | null;

	@GandalfProperty()
	durationText!: string | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	factorsSelection!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty()
	factorsText!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	locationSelection!: FieldValueSelectionResponse | null;

	@GandalfProperty()
	locationText!: string | null;

	@GandalfProperty()
	qualitySelection!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty()
	qualityText!: string | null;

	@GandalfProperty()
	severitySelection!: FieldValueSelectionResponse | null;

	@GandalfProperty()
	severityText!: string | null;

	@GandalfProperty()
	signsSelection!: FieldValueMultiSelectionResponse | null;

	@GandalfProperty()
	signsText!: string | null;

	@GandalfProperty()
	timingSelection!: FieldValueSelectionResponse | null;

	@GandalfProperty()
	timingText!: string | null;

}
