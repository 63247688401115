// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EyeglassPrescriptionResponse } from './eyeglass-prescription-response';

// @ts-ignore
import { OpticalOrderDetailResponse } from './optical-order-detail-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderEyeglassDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderEyeglassDetailResponse extends OpticalOrderDetailResponse {

	@GandalfProperty()
	arCoating!: string | null;

	@GandalfProperty()
	arCoatingSide!: string | null;

	@GandalfProperty()
	distancePd!: number | null;

	@GandalfProperty()
	edgeTreatment!: string | null;

	@GandalfProperty()
	frameBridge!: number | null;

	@GandalfProperty()
	frameColor!: string | null;

	@GandalfProperty()
	frameEye!: number | null;

	@GandalfProperty()
	frameManufacturer!: string | null;

	@GandalfProperty()
	frameProductName!: string | null;

	@GandalfProperty()
	frameTemple!: number | null;

	@GandalfProperty()
	material!: string | null;

	@GandalfProperty()
	mounting!: string | null;

	@GandalfProperty()
	nearPd!: number | null;

	@GandalfProperty()
	odBaseCurve!: number | null;

	@GandalfProperty()
	odBifocalSize!: string | null;

	@GandalfProperty()
	odBlankSize!: number | null;

	@GandalfProperty()
	odLensType!: string | null;

	@GandalfProperty()
	odMonocularPdDistance!: number | null;

	@GandalfProperty()
	odMonocularPdNear!: number | null;

	@GandalfProperty()
	odOpticalCenter!: number | null;

	@GandalfProperty()
	odPal!: string | null;

	@GandalfProperty()
	odProductCode!: string | null;

	@GandalfProperty()
	odSegHeight!: number | null;

	@GandalfProperty()
	odTrifocalSize!: string | null;

	@GandalfProperty()
	odVertexDistance!: number | null;

	@GandalfProperty()
	orderDetailId!: number | null;

	@GandalfProperty()
	osBaseCurve!: number | null;

	@GandalfProperty()
	osBifocalSize!: string | null;

	@GandalfProperty()
	osBlankSize!: number | null;

	@GandalfProperty()
	osLensType!: string | null;

	@GandalfProperty()
	osMonocularPdDistance!: number | null;

	@GandalfProperty()
	osMonocularPdNear!: number | null;

	@GandalfProperty()
	osOpticalCenter!: number | null;

	@GandalfProperty()
	osPal!: string | null;

	@GandalfProperty()
	osProductCode!: string | null;

	@GandalfProperty()
	osSegHeight!: number | null;

	@GandalfProperty()
	osTrifocalSize!: string | null;

	@GandalfProperty()
	osVertexDistance!: number | null;

	@GandalfProperty()
	pantoscopicTilt!: number | null;

	@GandalfProperty()
	photochromic!: string | null;

	@GandalfProperty()
	polarized!: string | null;

	@GandalfProperty()
	prescription!: EyeglassPrescriptionResponse | null;

	@GandalfProperty()
	quantity!: number | null;

	@GandalfProperty()
	scratchCoat!: string | null;

	@GandalfProperty()
	shade!: string | null;

	@GandalfProperty()
	specialInstructions!: string | null;

	@GandalfProperty()
	specialOption!: string | null;

	@GandalfProperty()
	thickness!: string | null;

	@GandalfProperty()
	tintFactor!: string | null;

	@GandalfProperty()
	tintType!: string | null;

	@GandalfProperty()
	uvTreatment!: string | null;

	@GandalfProperty()
	wrapAngle!: number | null;

}
