// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AppointmentInsuranceVerificationResponse } from './appointment-insurance-verification-response';

// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { EncounterTemplateResponse } from './encounter-template-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { RecurringDefinitionResponse } from './recurring-definition-response';

// @ts-ignore
import { ScheduleItemResponse } from './schedule-item-response';

// @ts-ignore
import { SecurityRoleResponse } from './security-role-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.AppointmentResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentResponse extends ScheduleItemResponse {

	@GandalfProperty()
	actualEndDate!: Date | null;

	@GandalfProperty()
	actualStartDate!: Date | null;

	@GandalfProperty()
	appointmentInsuranceVerificationResponse!: AppointmentInsuranceVerificationResponse | null;

	@GandalfProperty()
	confirmationRequired!: boolean | null;

	@GandalfProperty()
	confirmed!: boolean | null;

	@GandalfProperty()
	createdOn!: Date | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	displayColor!: number | null;

	@GandalfProperty()
	employee!: EmployeeNameResponse | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty()
	encounterApprovalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty()
	encounterStatus!: constants.EncounterStatus | null;

	@GandalfProperty()
	endDate!: Date | null;

	@GandalfProperty()
	groupingPersonId!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty()
	interviewStatus!: constants.InterviewStatus | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	locationSummary!: PracticeLocationSummaryResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	patientDob!: Date | null;

	@GandalfProperty()
	patientEmail!: string | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty()
	patientEstablishedStatus!: constants.EstablishedStatus | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty()
	patientGender!: constants.Gender | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfProperty()
	patientPHRLogin!: boolean | null;

	@GandalfProperty()
	patientPersonId!: number | null;

	@GandalfProperty()
	patientPhoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty()
	patientScheduled!: boolean | null;

	@GandalfProperty()
	provider!: ProviderResponse | null;

	@GandalfProperty()
	recurringDefinition!: RecurringDefinitionResponse | null;

	@GandalfProperty()
	relatedAppointmentId!: number | null;

	@GandalfProperty()
	role!: SecurityRoleResponse | null;

	@GandalfProperty()
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty()
	status!: constants.ScheduleItemStatus | null;

	@GandalfProperty()
	subType!: string | null;

	@GandalfProperty()
	subTypeId!: number | null;

	@GandalfProperty()
	summary!: string | null;

	@GandalfProperty()
	template!: EncounterTemplateResponse | null;

	@GandalfConstantDecorator(constants.ScheduleItemType)
	@GandalfProperty()
	type!: constants.ScheduleItemType | null;

	@GandalfProperty()
	updatedOn!: Date | null;

}
