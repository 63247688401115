// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.eyefinity.AdminCopyEyefinityFeeScheduleToLocationRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminCopyEyefinityFeeScheduleToLocationRequest extends GandalfModelBase {

	@GandalfLabel('Fee Schedule')
	@GandalfValidator({ notNull: { message: 'Fee Schedule is required' } })
	@GandalfProperty({ isRequired: true })
	eyefinityFeeScheduleId!: number;

	@GandalfLabel('To Location')
	@GandalfValidator({ notNull: { message: 'To Location is required' } })
	@GandalfProperty({ isRequired: true })
	toPracticeLocationId!: number;

}
