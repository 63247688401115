// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { PracticeLocationSummaryResponse } from './practice-location-summary-response';

/** See com.rev360.pms.api.controller.patient.immunization.PatientVaccinationListResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientVaccinationListResponse extends GandalfModelBase {

	@GandalfProperty()
	administeredAmount!: number | null;

	@GandalfProperty()
	administeredAmountUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	administeredAtLocation!: PracticeLocationSummaryResponse | null;

	@GandalfProperty()
	administeredOn!: Date | null;

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty()
	cvxDetailCode!: string | null;

	@GandalfProperty()
	cvxDetailShortName!: string | null;

	@GandalfConstantDecorator(constants.VaccinationStatus)
	@GandalfProperty()
	status!: constants.VaccinationStatus | null;

	@GandalfProperty()
	vaccinationId!: number | null;

}
