// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { AdminLocationPhoneRequest } from './admin-location-phone-request';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationDetailsRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationDetailsRequest extends GandalfModelBase {

	@GandalfProperty()
	address!: AddressOptionalRequest | null;

	@GandalfLabel('Barcode Label Location #')
	@GandalfValidator({ sizeString: { message: 'Barcode Label Location # cannot be longer than 2 characters', minLength: 0, maxLength: 2 } })
	@GandalfProperty()
	barcodeIdentifier!: string | null;

	@GandalfLabel('Claims Billing Location')
	@GandalfProperty()
	billingLocationClaimsId!: number | null;

	@GandalfLabel('Statements Billing Location')
	@GandalfProperty()
	billingLocationStatementsId!: number | null;

	@GandalfLabel('Billing Provider Name')
	@GandalfValidator({ sizeString: { message: 'Billing Provider Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	billingProviderName!: string | null;

	@GandalfConstantDecorator(constants.ClaimNpiEntity)
	@GandalfLabel('Claim NPI Entity')
	@GandalfProperty()
	claimNpiEntity!: constants.ClaimNpiEntity | null;

	@GandalfLabel('Co-Insurance Tax')
	@GandalfProperty()
	coInsuranceTaxId!: number | null;

	@GandalfLabel('Co-Pay Tax')
	@GandalfProperty()
	coPayTaxId!: number | null;

	@GandalfLabel('Deductible Tax')
	@GandalfProperty()
	deductibleTaxId!: number | null;

	@GandalfLabel('Default Finance Charge Plan')
	@GandalfProperty()
	defaultFinanceChargePlanId!: number | null;

	@GandalfLabel('Email')
	@GandalfValidator({ sizeString: { message: 'Email cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfValidator({ email: { message: 'Invalid email format' } })
	@GandalfProperty()
	email!: string | null;

	@GandalfLabel('Facility Number')
	@GandalfValidator({ sizeString: { message: 'Facility Number cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	facilityNumber!: string | null;

	@GandalfConstantDecorator(constants.FacilityType)
	@GandalfLabel('Facility Type')
	@GandalfProperty()
	facilityType!: constants.FacilityType | null;

	@GandalfLabel('FEIN')
	@GandalfValidator({ sizeString: { message: 'FEIN cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	fein!: string | null;

	@GandalfLabel('Logo')
	@GandalfProperty()
	logoPracticeFileId!: number | null;

	@GandalfLabel('Location Name')
	@GandalfValidator({ notNull: { message: 'Location Name is required' } })
	@GandalfValidator({ sizeString: { message: 'Location Name must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	name!: string;

	@GandalfLabel('NPI')
	@GandalfValidator({ sizeString: { message: 'NPI must be between 10 and 15 characters', minLength: 10, maxLength: 15 } })
	@GandalfProperty()
	npi!: string | null;

	@GandalfSubRequestLabel('Pay-To Address')
	@GandalfProperty()
	payToAddress!: AddressOptionalRequest | null;

	@GandalfValidator({ notNull: { message: 'Phones are required' } })
	@GandalfProperty({ isRequired: true })
	phones!: AdminLocationPhoneRequest;

	@GandalfLabel('Print Name As')
	@GandalfValidator({ sizeString: { message: 'Print Name As cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	printNameAs!: string | null;

	@GandalfConstantDecorator(constants.BillingId)
	@GandalfLabel('Provider Billing ID')
	@GandalfProperty()
	providerBillingId!: constants.BillingId | null;

	@GandalfConstantDecorator(constants.ServiceLocationIndicator)
	@GandalfLabel('Service Location Indicator')
	@GandalfProperty()
	serviceLocationIndicator!: constants.ServiceLocationIndicator | null;

	@GandalfLabel('Location #')
	@GandalfValidator({ sizeString: { message: 'Location # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	storeNumber!: string | null;

	@GandalfLabel('Web Site')
	@GandalfValidator({ sizeString: { message: 'Web Site cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	websiteUrl!: string | null;

}
