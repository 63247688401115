// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderPodOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderPodOrderResponse extends GandalfModelBase {

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn!: Date | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty()
	onHold!: boolean | null;

	@GandalfProperty()
	orderId!: number | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty()
	orderType!: constants.OrderType | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty()
	status!: constants.OrderStatusCode | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate!: Date | null;

}
