// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.dataconfiguration.MasterReferenceEthnicityDataResponse */
/* istanbul ignore next */
@GandalfModel
export class MasterReferenceEthnicityDataResponse extends GandalfModelBase {

	@GandalfProperty()
	categoryId!: number | null;

	@GandalfProperty()
	hierarchicalCode!: string | null;

	@GandalfProperty()
	masterReferenceDataId!: number | null;

	@GandalfProperty()
	uniqueIdentifier!: string | null;

	@GandalfProperty()
	value!: string | null;

}
