// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.practicepreferences.WebServiceUserResponse */
/* istanbul ignore next */
@GandalfModel
export class WebServiceUserResponse extends GandalfModelBase {

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty()
	enabled!: boolean | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	partnerKey!: string | null;

	@GandalfProperty()
	remotePassword!: string | null;

	@GandalfProperty()
	remoteUserName!: string | null;

	@GandalfConstantDecorator(constants.WebServiceUserServiceType)
	@GandalfProperty()
	serviceType!: constants.WebServiceUserServiceType | null;

}
