// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.accounting.PatientInvoiceDashboardSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientInvoiceDashboardSearchRequest extends GandalfModelBase {

	@GandalfLabel('Invoice #')
	@GandalfValidator({ max: { max: 999999999999999999, message: 'Invoice # may not be more than 18 digits' } })
	@GandalfProperty()
	invoiceId!: number | null;

	@GandalfLabel('Location')
	@GandalfProperty()
	locationId!: number | null;

	@GandalfLabel('Patient')
	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfLabel('Payer Name')
	@GandalfValidator({ sizeString: { fieldLabel: 'Payer Name', maxLength: 1000, minLength: 0, message: 'Payer Name must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	payerName!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfLabel('Payer Type')
	@GandalfProperty()
	payerType!: constants.PayerType | null;

	@GandalfLabel('Service Date End')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDateEnd!: Date | null;

	@GandalfLabel('Service Date Start')
	@GandalfProperty({ propertyType: 'LocalDate' })
	serviceDateStart!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceSearchStatus)
	@GandalfLabel('Status')
	@GandalfProperty()
	status!: constants.InvoiceSearchStatus | null;

}
