// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { RefractionEyeResponse } from './refraction-eye-response';

/** See com.rev360.pms.api.controller.prescription.RefractionResponse */
/* istanbul ignore next */
@GandalfModel
export class RefractionResponse extends GandalfModelBase {

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty()
	lastUpdatedFieldTime!: Date | null;

	@GandalfProperty()
	masterId!: number | null;

	@GandalfProperty()
	od!: RefractionEyeResponse | null;

	@GandalfProperty()
	os!: RefractionEyeResponse | null;

	@GandalfProperty()
	refractionName!: string | null;

}
