// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { BaseAgeReportResponse } from './base-age-report-response';

/** See com.rev360.pms.api.controller.reporting.inventory.age.EyeglassFrameAgeReportResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassFrameAgeReportResponse extends BaseAgeReportResponse {

	@GandalfProperty()
	averageAge!: number | null;

	@GandalfProperty()
	averageAgeOfStock!: number | null;

	@GandalfProperty()
	brand!: string | null;

	@GandalfProperty()
	bridge!: number | null;

	@GandalfProperty()
	category!: string | null;

	@GandalfProperty()
	collection!: string | null;

	@GandalfProperty()
	color!: string | null;

	@GandalfProperty()
	colorCode!: string | null;

	@GandalfProperty()
	eye!: number | null;

	@GandalfProperty()
	itemDescription!: string | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	locationProductActualCost!: number | null;

	@GandalfProperty()
	locationProductItemId!: number | null;

	@GandalfProperty()
	manufacturer!: string | null;

	@GandalfProperty()
	maximumAge!: number | null;

	@GandalfProperty()
	maximumAgeOfStock!: number | null;

	@GandalfProperty()
	minimumAge!: number | null;

	@GandalfProperty()
	minimumAgeOfStock!: number | null;

	@GandalfProperty()
	model!: string | null;

	@GandalfProperty()
	sku!: string | null;

	@GandalfProperty()
	stock!: number | null;

	@GandalfProperty()
	temple!: number | null;

	@GandalfProperty()
	upc!: string | null;

}
