// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.reasonforvisit.UpdateReasonForVisitForSignedEncounterRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateReasonForVisitForSignedEncounterRequest extends GandalfModelBase {

	@GandalfLabel('id')
	@GandalfValidator({ notNull: { message: 'id is required' } })
	@GandalfProperty({ isRequired: true })
	id!: number;

	@GandalfConstantDecorator(constants.TocReceivedStatus)
	@GandalfLabel('e-TOC Document')
	@GandalfProperty()
	tocReceivedStatus!: constants.TocReceivedStatus | null;

}
