// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.insurance.CreatePatientMaterialBenefitsRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePatientMaterialBenefitsRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate!: Date | null;

	@GandalfValidator({ sizeString: { message: 'Authorization Number cannot be longer than 40 characters', minLength: 0, maxLength: 40 } })
	@GandalfProperty()
	authorizationNumber!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensAllowance!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	contactLensBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	frameBenefitResetDate!: Date | null;

	@GandalfProperty()
	hasContactLensBenefit!: boolean | null;

	@GandalfProperty()
	hasFrameBenefit!: boolean | null;

	@GandalfProperty()
	hasLensBenefit!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	lensBenefitResetDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	maxFrameAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	minFrameAllowance!: number | null;

}
