// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.admin.UserSessionLogResponse */
/* istanbul ignore next */
@GandalfModel
export class UserSessionLogResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.UserSessionLogAction)
	@GandalfProperty()
	action!: constants.UserSessionLogAction | null;

	@GandalfProperty()
	additionalInfo!: string | null;

	@GandalfProperty()
	dataLogId!: number | null;

	@GandalfProperty()
	date!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isCorrupt!: boolean | null;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfProperty()
	primaryEntityId!: number | null;

	@GandalfConstantDecorator(constants.UserSessionLogEntityType)
	@GandalfProperty()
	primaryEntityType!: constants.UserSessionLogEntityType | null;

	@GandalfProperty()
	secondaryEntityId!: number | null;

	@GandalfConstantDecorator(constants.UserSessionLogEntityType)
	@GandalfProperty()
	secondaryEntityType!: constants.UserSessionLogEntityType | null;

	@GandalfProperty()
	userId!: number | null;

	@GandalfProperty()
	userName!: string | null;

}
