// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PatientCommunicationPreferencesResponse } from './patient-communication-preferences-response';

// @ts-ignore
import { PatientEmploymentResponse } from './patient-employment-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PatientPortalAuthorizedPatientResponse } from './patient-portal-authorized-patient-response';

// @ts-ignore
import { PatientReferralResponse } from './patient-referral-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { PreviousAddressResponse } from './previous-address-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.PatientResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientResponse extends GandalfModelBase {

	@GandalfProperty()
	allowLogin!: boolean | null;

	@GandalfProperty()
	alternateIdentifier!: string | null;

	@GandalfArray(PatientPortalAuthorizedPatientResponse)
	authorizedPatients!: PatientPortalAuthorizedPatientResponse[] | null;

	@GandalfConstantDecorator(constants.BloodType)
	@GandalfProperty()
	bloodType!: constants.BloodType | null;

	@GandalfProperty()
	cellPhoneDeactivatedDate!: Date | null;

	@GandalfProperty()
	cellPhoneStoppedDate!: Date | null;

	@GandalfProperty()
	citizenshipId!: number | null;

	@GandalfProperty()
	communicationPreferences!: PatientCommunicationPreferencesResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dob!: Date | null;

	@GandalfProperty()
	dod!: Date | null;

	@GandalfProperty()
	dominantEye!: ReferenceDataResponse | null;

	@GandalfProperty()
	dominantHand!: ReferenceDataResponse | null;

	@GandalfProperty()
	driversLicense!: string | null;

	@GandalfProperty()
	email!: string | null;

	@GandalfProperty()
	emailDeclined!: boolean | null;

	@GandalfProperty()
	emailValid!: boolean | null;

	@GandalfProperty()
	employment!: PatientEmploymentResponse | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty()
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfArray(ReferenceDataResponse)
	ethnicities!: ReferenceDataResponse[] | null;

	@GandalfProperty()
	ethnicityDeclined!: boolean | null;

	@GandalfProperty()
	fullSsnAvailable!: boolean | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty()
	gender!: constants.Gender | null;

	@GandalfConstantDecorator(constants.GenderIdentity)
	@GandalfProperty()
	genderIdentity!: constants.GenderIdentity | null;

	@GandalfProperty()
	guarantor!: PersonNameResponse | null;

	@GandalfProperty()
	hasPassword!: boolean | null;

	@GandalfProperty()
	hasTemporaryAddress!: boolean | null;

	@GandalfProperty()
	homeAddress!: AddressResponse | null;

	@GandalfConstantDecorator(constants.PersonInterpreterAssistance)
	@GandalfProperty()
	interpreterAssistance!: constants.PersonInterpreterAssistance | null;

	@GandalfProperty()
	last4ssn!: string | null;

	@GandalfProperty()
	maritalStatus!: ReferenceDataResponse | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfArray(PatientNameResponse)
	patientPortalRepresentatives!: PatientNameResponse[] | null;

	@GandalfProperty()
	phoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty()
	photoId!: number | null;

	@GandalfProperty()
	previousAddress!: PreviousAddressResponse | null;

	@GandalfProperty()
	previousFirstName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty()
	previousFirstNameType!: constants.PreviousNameType | null;

	@GandalfProperty()
	previousLastName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty()
	previousLastNameType!: constants.PreviousNameType | null;

	@GandalfProperty()
	previousMiddleName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty()
	previousMiddleNameType!: constants.PreviousNameType | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	previousNameEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	previousNameStartDate!: Date | null;

	@GandalfProperty()
	primaryLocationId!: number | null;

	@GandalfProperty()
	provider!: ProviderResponse | null;

	@GandalfProperty()
	raceDeclined!: boolean | null;

	@GandalfArray(ReferenceDataResponse)
	races!: ReferenceDataResponse[] | null;

	@GandalfArray(PatientReferralResponse)
	referrals!: PatientReferralResponse[] | null;

	@GandalfProperty()
	referrer!: PatientReferralResponse | null;

	@GandalfProperty()
	religion!: ReferenceDataResponse | null;

	@GandalfConstantDecorator(constants.SexualOrientation)
	@GandalfProperty()
	sexualOrientation!: constants.SexualOrientation | null;

	@GandalfProperty()
	sin!: string | null;

	@GandalfProperty()
	spokenLanguage!: ReferenceDataResponse | null;

	@GandalfProperty()
	spokenLanguageDeclined!: boolean | null;

	@GandalfProperty()
	temporaryAddress!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	temporaryAddressEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	temporaryAddressStartDate!: Date | null;

	@GandalfProperty()
	userName!: string | null;

	@GandalfProperty()
	version!: number | null;

	@GandalfProperty()
	writtenLanguage!: ReferenceDataResponse | null;

	@GandalfProperty()
	writtenLanguageDeclined!: boolean | null;

}
