// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.dataconfiguration.diagnosis.DiagnosisSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class DiagnosisSearchRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfValidator({ notNull: { message: 'Code Set is required' } })
	@GandalfProperty({ isRequired: true })
	codeSet!: constants.CodeSet;

	@GandalfValidator({ notNull: { message: 'Please enter a diagnosis code or description to search for' } })
	@GandalfValidator({ sizeString: { fieldLabel: 'Search string', maxLength: 1000, minLength: 0, message: 'Search string must be less than or equal to 1000 characters' } })
	@GandalfProperty({ isRequired: true })
	searchString!: string;

}
