// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.openedge.OpenEdgeCreateStoredCardTransactionRequest */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeCreateStoredCardTransactionRequest extends GandalfModelBase {

	@GandalfLabel('Amount')
	@GandalfValidator({ notNull: { message: 'Amount is required' } })
	@GandalfProperty({ propertyType: 'Money', isRequired: true })
	amount!: number;

	@GandalfLabel('Payer')
	@GandalfProperty()
	payerId!: number | null;

	@GandalfLabel('Payment Stored Token')
	@GandalfValidator({ notNull: { message: 'Payment Stored Token is required' } })
	@GandalfProperty({ isRequired: true })
	paymentStoredTokenId!: number;

	@GandalfLabel('Person')
	@GandalfProperty()
	personId!: number | null;

	@GandalfLabel('Practice Location')
	@GandalfValidator({ notNull: { message: 'Practice Location is required' } })
	@GandalfProperty({ isRequired: true })
	practiceLocationId!: number;

}
