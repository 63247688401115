// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EyeglassPrescriptionEyeResponse } from './eyeglass-prescription-eye-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.prescription.EyeglassPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class EyeglassPrescriptionResponse extends GandalfModelBase {

	@GandalfProperty()
	arCoating!: boolean | null;

	@GandalfProperty()
	arCoatingComment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate!: Date | null;

	@GandalfProperty()
	authorizedBy!: ProviderResponse | null;

	@GandalfProperty()
	distancePd!: number | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty()
	lensType!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	lensTypeComment!: string | null;

	@GandalfProperty()
	material!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	materialComment!: string | null;

	@GandalfProperty()
	nearPd!: number | null;

	@GandalfProperty()
	od!: EyeglassPrescriptionEyeResponse | null;

	@GandalfProperty()
	os!: EyeglassPrescriptionEyeResponse | null;

	@GandalfProperty()
	photochromic!: boolean | null;

	@GandalfProperty()
	photochromicComment!: string | null;

	@GandalfProperty()
	phrEnabled!: boolean | null;

	@GandalfProperty()
	polarized!: boolean | null;

	@GandalfProperty()
	polarizedComment!: string | null;

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty()
	prescriptionAuthorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty()
	status!: constants.PrescriptionStatus | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	stopDate!: Date | null;

	@GandalfProperty()
	tint!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	tintComment!: string | null;

	@GandalfProperty()
	usedFor!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	uvTreatment!: boolean | null;

	@GandalfProperty()
	uvTreatmentComment!: string | null;

}
