// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

// @ts-ignore
import { DurationResponse } from './duration-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeRgpResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeRgpResponse extends ContactLensEyeResponse {

	@GandalfProperty()
	addDiameter!: number | null;

	@GandalfProperty()
	addPower!: number | null;

	@GandalfProperty()
	axis!: number | null;

	@GandalfProperty()
	backOpticalDiameter!: number | null;

	@GandalfProperty()
	baseCurve!: number | null;

	@GandalfProperty()
	baseCurve2!: number | null;

	@GandalfConstantDecorator(constants.BaseCurveUnit)
	@GandalfProperty()
	baseCurveUnit!: constants.BaseCurveUnit | null;

	@GandalfProperty()
	centerThickness!: number | null;

	@GandalfProperty()
	cylinder!: number | null;

	@GandalfProperty()
	diameter!: number | null;

	@GandalfProperty()
	distanceZone!: number | null;

	@GandalfProperty()
	dot!: boolean | null;

	@GandalfProperty()
	edgeLift!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	edgeLiftAmount!: number | null;

	@GandalfProperty()
	fenestration!: boolean | null;

	@GandalfProperty()
	firstCurveRadius!: number | null;

	@GandalfProperty()
	firstCurveWidth!: number | null;

	@GandalfProperty()
	fourthCurveRadius!: number | null;

	@GandalfProperty()
	fourthCurveWidth!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	intermediateSegment!: number | null;

	@GandalfProperty()
	isMonovision!: boolean | null;

	@GandalfProperty()
	landingZoneAngle!: number | null;

	@GandalfProperty()
	lensMaterial!: ReferenceDataResponse | null;

	@GandalfProperty()
	limbalClearance!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	locationProductId!: number | null;

	@GandalfProperty()
	locationProductItemId!: number | null;

	@GandalfProperty()
	locationProductName!: string | null;

	@GandalfProperty()
	notes!: string | null;

	@GandalfProperty()
	opticZone!: number | null;

	@GandalfProperty()
	overnightSchedule!: DurationResponse | null;

	@GandalfProperty()
	peripheralCurveRadius!: number | null;

	@GandalfProperty()
	peripheralCurveWidth!: number | null;

	@GandalfProperty()
	peripheralEdge!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	plasmaTreatment!: boolean | null;

	@GandalfProperty()
	productColorId!: number | null;

	@GandalfProperty()
	productColorName!: string | null;

	@GandalfProperty()
	profile!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	replacementSchedule!: DurationResponse | null;

	@GandalfProperty()
	returnZoneDiameter!: number | null;

	@GandalfProperty()
	sagittalDepth!: number | null;

	@GandalfProperty()
	secondCurveRadius!: number | null;

	@GandalfProperty()
	secondCurveWidth!: number | null;

	@GandalfProperty()
	secondaryCurveRadius!: number | null;

	@GandalfProperty()
	secondaryCurveWidth!: number | null;

	@GandalfProperty()
	segmentHeight!: number | null;

	@GandalfProperty()
	specialCharacteristics!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	sphere!: number | null;

	@GandalfProperty()
	sphere2!: number | null;

	@GandalfProperty()
	thirdCurveRadius!: number | null;

	@GandalfProperty()
	thirdCurveWidth!: number | null;

	@GandalfProperty()
	tint!: string | null;

	@GandalfProperty()
	truncation!: number | null;

	@GandalfProperty()
	version!: number | null;

}
