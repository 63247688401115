// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.laborder.LabOrderListResponse */
/* istanbul ignore next */
@GandalfModel
export class LabOrderListResponse extends GandalfModelBase {

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfProperty()
	fileCount!: number | null;

	@GandalfProperty()
	hasInterpretation!: boolean | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	labName!: string | null;

	@GandalfProperty()
	order!: LoincCodeResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDate!: Date | null;

	@GandalfProperty()
	orderedBy!: ProviderResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	performedDate!: Date | null;

	@GandalfProperty()
	result!: string | null;

	@GandalfProperty()
	resultNum!: number | null;

	@GandalfConstantDecorator(constants.LabOrderResultType)
	@GandalfProperty()
	resultType!: constants.LabOrderResultType | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.LabOrderStatus)
	@GandalfProperty()
	status!: constants.LabOrderStatus | null;

}
