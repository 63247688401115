// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemResponse } from './care-plan-item-response';

// @ts-ignore
import { PracticeDiagnosisResponse } from './practice-diagnosis-response';

/** See com.rev360.pms.api.controller.person.diagnosis.PersonDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonDiagnosisResponse extends GandalfModelBase {

	@GandalfArray(CarePlanItemResponse)
	carePlanItemResponses!: CarePlanItemResponse[] | null;

	@GandalfArray(PersonDiagnosisResponse)
	childDiagnoses!: PersonDiagnosisResponse[] | null;

	@GandalfProperty()
	code!: string | null;

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate!: Date | null;

	@GandalfConstantDecorator(constants.DiagnosisLocation)
	@GandalfProperty()
	diagnosisLocation!: constants.DiagnosisLocation | null;

	@GandalfConstantDecorator(constants.DiagnosisLocationQualifier)
	@GandalfProperty()
	diagnosisLocationQualifier!: constants.DiagnosisLocationQualifier | null;

	@GandalfProperty()
	encounterAutoDiagnosisId!: number | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isPrimary!: boolean | null;

	@GandalfProperty()
	isShowDisabledWarning!: boolean | null;

	@GandalfProperty()
	needConversion!: boolean | null;

	@GandalfProperty()
	originalDiagnosisId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	originalDiagnosisResolutionDate!: Date | null;

	@GandalfProperty()
	parentDiagnosis!: PersonDiagnosisResponse | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfProperty()
	practiceDiagnosis!: PracticeDiagnosisResponse | null;

	@GandalfProperty()
	reason!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	resolutionDate!: Date | null;

	@GandalfProperty()
	shortDescription!: string | null;

	@GandalfConstantDecorator(constants.PersonDiagnosisStatus)
	@GandalfProperty()
	status!: constants.PersonDiagnosisStatus | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	updatedOn!: Date | null;

}
