// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

/** See com.rev360.pms.api.controller.encounter.contactlens.ContactLensTrialListResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensTrialListResponse extends GandalfModelBase {

	@GandalfProperty()
	commentsOd!: string | null;

	@GandalfProperty()
	commentsOs!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isDispensedOd!: boolean | null;

	@GandalfProperty()
	isDispensedOs!: boolean | null;

	@GandalfProperty()
	od!: ContactLensEyeResponse | null;

	@GandalfProperty()
	os!: ContactLensEyeResponse | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty()
	overallLensType!: constants.ContactLensPrescriptionType | null;

	@GandalfProperty()
	productOdName!: string | null;

	@GandalfProperty()
	productOsName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	trialDate!: Date | null;

}
