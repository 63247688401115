// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AttributeDropdownResponse } from './attribute-dropdown-response';

// @ts-ignore
import { InterviewTemplateResponse } from './interview-template-response';

/** See com.rev360.pms.api.controller.encounter.template.EncounterTemplateResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterTemplateResponse extends GandalfModelBase {

	@GandalfProperty()
	displayColor!: number | null;

	@GandalfProperty()
	encounterTemplateId!: number | null;

	@GandalfProperty()
	interviewTemplate!: InterviewTemplateResponse | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfArray(AttributeDropdownResponse)
	subTypes!: AttributeDropdownResponse[] | null;

	@GandalfConstantDecorator(constants.TemplateStatus)
	@GandalfProperty()
	templateStatus!: constants.TemplateStatus | null;

	@GandalfProperty()
	totalDuration!: number | null;

}
