// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.CreateFinanceChargePlanPeriodRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateFinanceChargePlanPeriodRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Age in Days is required' } })
	@GandalfProperty({ isRequired: true })
	ageInDays!: number;

	@GandalfProperty({ propertyType: 'Money' })
	dollarCharge!: number | null;

	@GandalfValidator({ min: { min: 1, message: 'Percent Charge must be greater than or equal to 1' } })
	@GandalfProperty()
	percentCharge!: number | null;

}
