// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { UpdatePersonDiagnosisLocationAndQualifierRequest } from './update-person-diagnosis-location-and-qualifier-request';

/** See com.rev360.pms.api.controller.person.diagnosis.UpdatePersonDiagnosisRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdatePersonDiagnosisRequest extends UpdatePersonDiagnosisLocationAndQualifierRequest {

	@GandalfArray(Number)
	childIdsToUnmap!: number[] | null;

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comment!: string | null;

	@GandalfValidator({ notNull: { message: 'Diagnosis Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	diagnosisDate!: Date;

	@GandalfConstantDecorator(constants.DiagnosisLocation)
	@GandalfProperty()
	diagnosisLocation!: constants.DiagnosisLocation | null;

	@GandalfConstantDecorator(constants.DiagnosisLocationQualifier)
	@GandalfProperty()
	diagnosisLocationQualifier!: constants.DiagnosisLocationQualifier | null;

	@GandalfValidator({ notNull: { message: 'Diagnosis is required' } })
	@GandalfProperty({ isRequired: true })
	id!: number;

	@GandalfProperty()
	needConversion!: boolean | null;

	@GandalfProperty()
	parentIdToUnmap!: number | null;

	@GandalfValidator({ sizeString: { message: 'Reason cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	reason!: string | null;

}
