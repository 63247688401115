// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ScheduleItemCalendarResponse } from './schedule-item-calendar-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.calendar.AppointmentCalendarResponse */
/* istanbul ignore next */
@GandalfModel
export class AppointmentCalendarResponse extends ScheduleItemCalendarResponse {

	@GandalfProperty()
	confirmed!: boolean | null;

	@GandalfProperty()
	displayColor!: number | null;

	@GandalfProperty()
	employee!: EmployeeNameResponse | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty()
	encounterApprovalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty()
	encounterStatus!: constants.EncounterStatus | null;

	@GandalfProperty()
	endDate!: Date | null;

	@GandalfProperty()
	groupingPersonId!: number | null;

	@GandalfProperty()
	hasInterviewTemplate!: boolean | null;

	@GandalfProperty()
	insuranceVerificationComment!: string | null;

	@GandalfConstantDecorator(constants.VerificationStatus)
	@GandalfProperty()
	insuranceVerificationStatus!: constants.VerificationStatus | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty()
	interviewStatus!: constants.InterviewStatus | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	patientDob!: Date | null;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfProperty()
	provider!: ProviderResponse | null;

	@GandalfProperty()
	roleId!: number | null;

	@GandalfProperty()
	roleName!: string | null;

	@GandalfProperty()
	scheduleItemId!: number | null;

	@GandalfProperty()
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty()
	status!: constants.ScheduleItemStatus | null;

	@GandalfProperty()
	subType!: string | null;

	@GandalfProperty()
	template!: string | null;

	@GandalfProperty()
	templateDisplayColor!: number | null;

	@GandalfConstantDecorator(constants.ScheduleItemType)
	@GandalfProperty()
	type!: constants.ScheduleItemType | null;

	@GandalfProperty()
	updatedOn!: Date | null;

}
