// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.dataconfiguration.medication.CreatePracticeDrugRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePracticeDrugRequest extends GandalfModelBase {

	@GandalfValidator({ min: { min: 0, message: 'Dispensing Info must be greater than or equal to 0' } })
	@GandalfProperty()
	dispensingInfoAmount!: number | null;

	@GandalfProperty()
	dispensingInfoUnitId!: number | null;

	@GandalfValidator({ notNull: { message: 'Do Not Substitute is required' } })
	@GandalfProperty({ isRequired: true })
	doNotSubstitute!: boolean;

	@GandalfValidator({ min: { min: 0, message: 'Dosage Amount must be greater than or equal to 0' } })
	@GandalfProperty()
	dosageAmount!: number | null;

	@GandalfProperty()
	dosageUnitId!: number | null;

	@GandalfValidator({ min: { min: 0, message: 'Duration must be greater than or equal to 0' } })
	@GandalfProperty()
	durationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	frequencyId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions!: string | null;

	@GandalfValidator({ min: { min: 0, message: 'Number of Refills must be greater than or equal to 0' } })
	@GandalfProperty()
	numRefillsAllowed!: number | null;

	@GandalfValidator({ notNull: { message: 'Drug is required' } })
	@GandalfProperty({ isRequired: true })
	practiceDrugId!: number;

	@GandalfProperty()
	routeId!: number | null;

}
