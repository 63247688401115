// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MessageParticipantNameResponse } from './message-participant-name-response';

/** See com.rev360.pms.api.controller.messaging.MessageParticipantResponse */
/* istanbul ignore next */
@GandalfModel
export class MessageParticipantResponse extends GandalfModelBase {

	@GandalfProperty()
	attachmentCount!: number | null;

	@GandalfProperty()
	createdOn!: Date | null;

	@GandalfProperty()
	messageId!: number;

	@GandalfProperty()
	messageParticipantId!: number;

	@GandalfProperty()
	messageSender!: MessageParticipantNameResponse;

	@GandalfConstantDecorator(constants.MessagePriority)
	@GandalfProperty()
	priority!: constants.MessagePriority | null;

	@GandalfProperty()
	starred!: boolean;

	@GandalfConstantDecorator(constants.MessageParticipantStatus)
	@GandalfProperty()
	statusCode!: constants.MessageParticipantStatus;

	@GandalfProperty()
	subject!: string | null;

}
