// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.accounting.RefundsReportRequest */
/* istanbul ignore next */
@GandalfModel
export class RefundsReportRequest extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateRangeEnd!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dateRangeStart!: Date | null;

	@GandalfConstantDecorator(constants.DateRangeType)
	@GandalfValidator({ notNull: { message: 'Date range is required' } })
	@GandalfProperty({ isRequired: true })
	dateRangeType!: constants.DateRangeType;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	providerId!: number | null;

	@GandalfConstantDecorator(constants.RefundsReportType)
	@GandalfValidator({ notNull: { message: 'Report type is required' } })
	@GandalfProperty({ isRequired: true })
	refundsReportType!: constants.RefundsReportType;

	@GandalfConstantDecorator(constants.AccountingReportOutputType)
	@GandalfValidator({ notNull: { message: 'Report output is required' } })
	@GandalfProperty({ isRequired: true })
	reportOutputType!: constants.AccountingReportOutputType;

}
