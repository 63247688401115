// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FamilyAccountMemberDetailResponse } from './family-account-member-detail-response';

/** See com.rev360.pms.api.controller.patient.family.FamilyAccountMembersDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class FamilyAccountMembersDetailResponse extends GandalfModelBase {

	@GandalfArray(FamilyAccountMemberDetailResponse)
	familyMembers!: FamilyAccountMemberDetailResponse[] | null;

	@GandalfProperty()
	isPerformanceLimited!: boolean | null;

}
