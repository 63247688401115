// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.query.QuerySortFieldRequest */
/* istanbul ignore next */
@GandalfModel
export class QuerySortFieldRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.QuerySortDirection)
	@GandalfProperty()
	direction!: constants.QuerySortDirection | null;

	@GandalfValidator({ sizeString: { min: 0, max: 100, message: 'ID length must be less than or equal to {max} characters.' } })
	@GandalfProperty()
	id!: string | null;

	@GandalfValidator({ sizeString: { min: 0, max: 100, message: 'Label length must be less than or equal to {max} characters.' } })
	@GandalfProperty()
	label!: string | null;

}
