// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

/** See com.rev360.pms.api.controller.admin.vendorsandpartners.vendor.SalesRepresentativeResponse */
/* istanbul ignore next */
@GandalfModel
export class SalesRepresentativeResponse extends GandalfModelBase {

	@GandalfProperty()
	address!: AddressResponse | null;

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty()
	companyName!: string | null;

	@GandalfProperty()
	credentials!: string | null;

	@GandalfProperty()
	email!: string | null;

	@GandalfProperty()
	firstName!: string | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty()
	gender!: constants.Gender | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	lastName!: string | null;

	@GandalfProperty()
	nickName!: string | null;

	@GandalfProperty()
	phoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty()
	preferredPhone!: string | null;

	@GandalfConstantDecorator(constants.SalesRepresentativeStatus)
	@GandalfProperty()
	status!: constants.SalesRepresentativeStatus | null;

	@GandalfProperty()
	suffix!: string | null;

	@GandalfProperty()
	titleId!: number | null;

}
