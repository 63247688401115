// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeResponse } from './employee-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.encounter.EncounterPullForwardTableResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterPullForwardTableResponse extends GandalfModelBase {

	@GandalfProperty()
	employee!: EmployeeResponse | null;

	@GandalfProperty()
	encounterDate!: Date | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfArray(String)
	performedServiceCodes!: string[] | null;

	@GandalfArray(String)
	personDiagnosisCodes!: string[] | null;

	@GandalfProperty()
	provider!: ProviderResponse | null;

	@GandalfProperty()
	subTypeValue!: string | null;

	@GandalfProperty()
	templateName!: string | null;

}
