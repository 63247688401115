// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

/** See com.rev360.pms.api.controller.admin.solutions.twelve84.Twelve84SubscriptionTableResponse */
/* istanbul ignore next */
@GandalfModel
export class Twelve84SubscriptionTableResponse extends GandalfModelBase {

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	kitReceivedByFirstName!: string | null;

	@GandalfProperty()
	kitReceivedByLastName!: string | null;

	@GandalfProperty()
	kitReceivedDate!: Date | null;

	@GandalfConstantDecorator(constants.TwelveEightyFourKitStatus)
	@GandalfProperty()
	kitStatus!: constants.TwelveEightyFourKitStatus | null;

	@GandalfProperty()
	locationContact!: EmployeeNameResponse | null;

	@GandalfProperty()
	practiceLocationId!: number | null;

	@GandalfProperty()
	practiceLocationName!: string | null;

	@GandalfProperty()
	subscribedByFirstName!: string | null;

	@GandalfProperty()
	subscribedByLastName!: string | null;

	@GandalfProperty()
	subscriptionDate!: Date | null;

	@GandalfConstantDecorator(constants.TwelveEightyFourSubscriptionStatus)
	@GandalfProperty()
	twelve84SubscriptionStatus!: constants.TwelveEightyFourSubscriptionStatus | null;

}
