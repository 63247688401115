// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PaymentResponse } from './payment-response';

/** See com.rev360.pms.api.controller.accounting.payment.PaymentGroupResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentGroupResponse extends GandalfModelBase {

	@GandalfProperty()
	applyFull!: boolean | null;

	@GandalfProperty()
	comment!: string | null;

	@GandalfConstantDecorator(constants.CreditCardType)
	@GandalfProperty()
	creditCardType!: constants.CreditCardType | null;

	@GandalfProperty()
	payerEntityId!: number | null;

	@GandalfProperty()
	payerId!: number;

	@GandalfProperty()
	payerName!: string;

	@GandalfProperty()
	payerPersonInsuranceId!: number | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType;

	@GandalfProperty({ propertyType: 'Money' })
	paymentAmount!: number;

	@GandalfProperty({ propertyType: 'LocalDate' })
	paymentDate!: Date;

	@GandalfProperty()
	paymentGroupId!: number;

	@GandalfProperty()
	paymentLocationId!: number | null;

	@GandalfConstantDecorator(constants.PaymentMethodType)
	@GandalfProperty()
	paymentMethodType!: constants.PaymentMethodType;

	@GandalfArray(PaymentResponse)
	payments!: PaymentResponse[];

	@GandalfProperty()
	referenceNumber!: string | null;

	@GandalfProperty()
	sourcePracticeLocationId!: number | null;

	@GandalfConstantDecorator(constants.PaymentGroupSourceType)
	@GandalfProperty()
	sourceType!: constants.PaymentGroupSourceType;

	@GandalfConstantDecorator(constants.PaymentGroupStatus)
	@GandalfProperty()
	status!: constants.PaymentGroupStatus;

	@GandalfProperty()
	version!: number;

}
