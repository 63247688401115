// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.accounting.feeschedule.CreateFeeScheduleRangeRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateFeeScheduleRangeRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Begin Date is required' } })
	@GandalfProperty({ isRequired: true })
	beginDate!: Date;

	@GandalfValidator({ notNull: { message: 'FeeSchedule must be chosen' } })
	@GandalfProperty({ isRequired: true })
	feeScheduleId!: number;

	@GandalfValidator({ notNull: { message: 'Practice Location must be selected' } })
	@GandalfProperty({ isRequired: true })
	practiceLocationId!: number;

	@GandalfValidator({ notNull: { message: 'Writeoff Reason must be selected' } })
	@GandalfProperty({ isRequired: true })
	writeOffReasonId!: number;

}
