// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.insurance.PersonInsurancePolicyResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonInsurancePolicyResponse extends GandalfModelBase {

	@GandalfProperty()
	coInsurancePercentage!: number | null;

	@GandalfProperty()
	companyAddress!: AddressResponse | null;

	@GandalfProperty()
	companyDefaultInsuranceTypeReferenceId!: number | null;

	@GandalfProperty()
	companyDefaultPriorityReferenceId!: number | null;

	@GandalfProperty()
	companyId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	contactLensFittingCoPay!: number | null;

	@GandalfProperty()
	effectiveDate!: Date | null;

	@GandalfProperty({ propertyType: 'Money' })
	familyDeductible!: number | null;

	@GandalfProperty()
	feeScheduleId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	generalCoPay!: number | null;

	@GandalfProperty()
	groupNumber!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	materialCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	maxFrameAllowance!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	medicalExamCoPay!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	minFrameAllowance!: number | null;

	@GandalfProperty()
	personNameResponse!: PersonNameResponse | null;

	@GandalfProperty()
	policyHolderDob!: Date | null;

	@GandalfProperty({ propertyType: 'Money' })
	routineExamCoPay!: number | null;

	@GandalfProperty()
	rxBinNumber!: string | null;

	@GandalfProperty()
	rxPcnNumber!: string | null;

	@GandalfProperty()
	termedDate!: Date | null;

}
