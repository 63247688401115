// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.encounter.review.EncounterReviewGeneralInformationResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterReviewGeneralInformationResponse extends GandalfModelBase {

	@GandalfProperty()
	contactLensRxCount!: number | null;

	@GandalfProperty()
	contactLensTrialCount!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	encounterDate!: Date | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty()
	encounterStatus!: constants.EncounterStatus | null;

	@GandalfProperty()
	encounterType!: string | null;

	@GandalfProperty()
	eyeglassRxCount!: number | null;

	@GandalfProperty()
	patientRfv!: string | null;

	@GandalfArray(String)
	primaryMedicalProviders!: string[] | null;

	@GandalfArray(String)
	primaryVisionProviders!: string[] | null;

	@GandalfProperty()
	providerName!: PersonNameResponse | null;

	@GandalfProperty()
	providerRfv!: string | null;

	@GandalfArray(String)
	serviceDetails!: string[] | null;

}
