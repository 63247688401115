// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldDefinitionResponse } from './field-definition-response';

/** See com.rev360.pms.api.controller.encounter.dynamic.form.FieldDefinitionHistoryOfPresentIllnessResponse */
/* istanbul ignore next */
@GandalfModel
export class FieldDefinitionHistoryOfPresentIllnessResponse extends GandalfModelBase {

	@GandalfProperty()
	associatedSignsAndSymptomsDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	contextDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	locationDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	modifyingFactorsDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	qualityDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	severityDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	timingDefinition!: FieldDefinitionResponse | null;

}
