// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.billing.BillingInvoiceResponse */
/* istanbul ignore next */
@GandalfModel
export class BillingInvoiceResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'Money' })
	balance!: number | null;

	@GandalfConstantDecorator(constants.BusinessStatus)
	@GandalfProperty()
	businessStatus!: constants.BusinessStatus | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	invoiceDate!: Date | null;

	@GandalfConstantDecorator(constants.InvoiceStatus)
	@GandalfProperty()
	invoiceStatus!: constants.InvoiceStatus | null;

	@GandalfProperty()
	isPreviousPayer!: boolean | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty()
	orderId!: number | null;

	@GandalfProperty()
	payerEntityId!: number | null;

	@GandalfProperty()
	payerId!: number | null;

	@GandalfProperty()
	payerPersonInsuranceId!: number | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType | null;

	@GandalfConstantDecorator(constants.PersonInsuranceStatus)
	@GandalfProperty()
	personInsuranceStatus!: constants.PersonInsuranceStatus | null;

	@GandalfConstantDecorator(constants.BillingInvoiceStatus)
	@GandalfProperty()
	status!: constants.BillingInvoiceStatus | null;

}
