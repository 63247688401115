// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

// @ts-ignore
import { DurationResponse } from './duration-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeSoftResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeSoftResponse extends ContactLensEyeResponse {

	@GandalfProperty()
	addDesignation!: ReferenceDataResponse | null;

	@GandalfProperty()
	addPower!: number | null;

	@GandalfProperty()
	axis!: number | null;

	@GandalfProperty()
	baseCurve!: number | null;

	@GandalfProperty()
	cylinder!: number | null;

	@GandalfProperty()
	diameter!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isMonovision!: boolean | null;

	@GandalfProperty()
	locationProductId!: number | null;

	@GandalfProperty()
	locationProductItemId!: number | null;

	@GandalfProperty()
	locationProductName!: string | null;

	@GandalfProperty()
	overnightSchedule!: DurationResponse | null;

	@GandalfProperty()
	productColorId!: number | null;

	@GandalfProperty()
	productColorName!: string | null;

	@GandalfProperty()
	replacementSchedule!: DurationResponse | null;

	@GandalfProperty()
	sphere!: number | null;

	@GandalfProperty()
	tint!: string | null;

	@GandalfProperty()
	version!: number | null;

}
