// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { EmployeeNameResponse } from './employee-name-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.header.TodaysPatientsAppointmentResponse */
/* istanbul ignore next */
@GandalfModel
export class TodaysPatientsAppointmentResponse extends GandalfModelBase {

	@GandalfProperty()
	appointmentConfirmed!: boolean;

	@GandalfProperty()
	appointmentId!: number;

	@GandalfProperty()
	appointmentSubTypeValue!: string | null;

	@GandalfProperty()
	connectMessageDelivered!: boolean;

	@GandalfProperty()
	connectMessageExists!: boolean;

	@GandalfProperty()
	employeeName!: EmployeeNameResponse | null;

	@GandalfConstantDecorator(constants.EncounterApprovalStatus)
	@GandalfProperty()
	encounterApprovalStatus!: constants.EncounterApprovalStatus | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfConstantDecorator(constants.EncounterStatus)
	@GandalfProperty()
	encounterStatus!: constants.EncounterStatus | null;

	@GandalfProperty()
	endDate!: Date;

	@GandalfProperty()
	insuranceComment!: string | null;

	@GandalfConstantDecorator(constants.VerificationStatus)
	@GandalfProperty()
	insuranceVerifiedStatus!: constants.VerificationStatus | null;

	@GandalfConstantDecorator(constants.InterviewStatus)
	@GandalfProperty()
	interviewStatus!: constants.InterviewStatus | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	patientDob!: Date;

	@GandalfProperty()
	patientName!: PatientNameResponse;

	@GandalfProperty()
	provider!: ProviderResponse | null;

	@GandalfProperty()
	roleName!: string | null;

	@GandalfConstantDecorator(constants.ScheduleItemStatus)
	@GandalfProperty()
	scheduleItemStatus!: constants.ScheduleItemStatus;

	@GandalfProperty()
	startDate!: Date;

	@GandalfProperty()
	templateDisplayColor!: number;

	@GandalfProperty()
	templateDisplayName!: string;

}
