// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.UpdateEyeglassLensMeasurementsRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEyeglassLensMeasurementsRequest extends GandalfModelBase {

	@GandalfProperty()
	baseCurve!: number | null;

	@GandalfProperty()
	blankSize!: number | null;

	@GandalfProperty()
	monocularPdDistance!: number | null;

	@GandalfProperty()
	monocularPdNear!: number | null;

	@GandalfProperty()
	opticalCenter!: number | null;

	@GandalfProperty()
	segHeight!: number | null;

	@GandalfProperty()
	vertexDistance!: number | null;

}
