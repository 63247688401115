// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.AddPatientOwnedFrameRequest */
/* istanbul ignore next */
@GandalfModel
export class AddPatientOwnedFrameRequest extends GandalfModelBase {

	@GandalfProperty()
	a!: number | null;

	@GandalfProperty()
	b!: number | null;

	@GandalfProperty()
	brandId!: number | null;

	@GandalfProperty()
	bridge!: number | null;

	@GandalfProperty()
	circumference!: number | null;

	@GandalfProperty()
	collectionId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Color Code cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	colorCode!: string | null;

	@GandalfValidator({ sizeString: { message: 'Color Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	colorName!: string | null;

	@GandalfProperty()
	dbl!: number | null;

	@GandalfProperty()
	ed!: number | null;

	@GandalfProperty()
	edAngle!: number | null;

	@GandalfProperty()
	eye!: number | null;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId!: number;

	@GandalfValidator({ sizeString: { message: 'Model cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	locationProductName!: string | null;

	@GandalfValidator({ notNull: { message: 'Manufacturer is required' } })
	@GandalfProperty({ isRequired: true })
	manufacturerId!: number;

	@GandalfProperty()
	screwToScrew!: number | null;

	@GandalfProperty()
	temple!: number | null;

	@GandalfValidator({ sizeString: { message: 'Temple Unit cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	templeUnit!: string | null;

}
