// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateDrugAllergyRequest } from './create-drug-allergy-request';

/** See com.rev360.pms.api.controller.person.UpdateDrugAllergyRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateDrugAllergyRequest extends CreateDrugAllergyRequest {

	@GandalfProperty({ propertyType: 'LocalDate' })
	deactivationDate!: Date | null;

	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	drugClassId!: number | null;

	@GandalfProperty()
	drugId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfValidator({ sizeString: { message: 'External ID cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalId!: string | null;

	@GandalfValidator({ notNull: { message: 'Id is required' } })
	@GandalfProperty({ isRequired: true })
	id!: number;

	@GandalfValidator({ notNull: { message: 'Patient Id is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfArray(Number)
	reactionIds!: number[] | null;

	@GandalfConstantDecorator(constants.AllergySeverity)
	@GandalfValidator({ notNull: { message: 'Severity is required' } })
	@GandalfProperty({ isRequired: true })
	severity!: constants.AllergySeverity;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.AllergyStatus)
	@GandalfProperty()
	status!: constants.AllergyStatus | null;

	@GandalfValidator({ sizeString: { message: 'Treatment cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	treatment!: string | null;

}
