// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.mentalstatus.MentalStatusResponse */
/* istanbul ignore next */
@GandalfModel
export class MentalStatusResponse extends GandalfModelBase {

	@GandalfProperty()
	functionalStatusComments!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	mentalStatusComments!: string | null;

	@GandalfConstantDecorator(constants.Mood)
	@GandalfProperty()
	mood!: constants.Mood | null;

	@GandalfProperty()
	moodComments!: string | null;

	@GandalfConstantDecorator(constants.Orientation)
	@GandalfProperty()
	orientation!: constants.Orientation | null;

	@GandalfProperty()
	orientationComments!: string | null;

	@GandalfProperty()
	version!: number | null;

}
