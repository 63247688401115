// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeHybridResponse } from './contact-lens-eye-hybrid-response';

// @ts-ignore
import { ContactLensEyeRgpResponse } from './contact-lens-eye-rgp-response';

// @ts-ignore
import { ContactLensEyeSoftResponse } from './contact-lens-eye-soft-response';

// @ts-ignore
import { MasterReferenceDataResponse } from './master-reference-data-response';

// @ts-ignore
import { ProductForPrescriptionResponse } from './product-for-prescription-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.prescription.ContactLensPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensPrescriptionResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty()
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty()
	authorizedBy!: ProviderResponse | null;

	@GandalfProperty()
	cleanerName!: string | null;

	@GandalfProperty()
	contactLensEyeHybridOd!: ContactLensEyeHybridResponse | null;

	@GandalfProperty()
	contactLensEyeHybridOs!: ContactLensEyeHybridResponse | null;

	@GandalfProperty()
	contactLensEyeRgpOd!: ContactLensEyeRgpResponse | null;

	@GandalfProperty()
	contactLensEyeRgpOs!: ContactLensEyeRgpResponse | null;

	@GandalfProperty()
	contactLensEyeSoftOd!: ContactLensEyeSoftResponse | null;

	@GandalfProperty()
	contactLensEyeSoftOs!: ContactLensEyeSoftResponse | null;

	@GandalfProperty()
	deliveryMethod!: ReferenceDataResponse | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty()
	derivedStatus!: constants.PrescriptionCurrentStatus | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	dispenseOdAmount!: number | null;

	@GandalfProperty()
	dispenseOdUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	dispenseOsAmount!: number | null;

	@GandalfProperty()
	dispenseOsUnit!: MasterReferenceDataResponse | null;

	@GandalfProperty()
	dropsName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfProperty()
	enzymaticCleanerName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isPHREnabled!: boolean | null;

	@GandalfConstantDecorator(constants.ContactLensModality)
	@GandalfProperty()
	modality!: constants.ContactLensModality | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty()
	overallLensType!: constants.ContactLensPrescriptionType | null;

	@GandalfProperty()
	productOd!: ProductForPrescriptionResponse | null;

	@GandalfProperty()
	productOs!: ProductForPrescriptionResponse | null;

	@GandalfProperty()
	solutionName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty()
	status!: constants.PrescriptionStatus | null;

}
