// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FieldDefinitionResponse } from './field-definition-response';

/** See com.rev360.pms.api.controller.encounter.dynamic.form.FieldDefinitionReviewOfSystemsResponse */
/* istanbul ignore next */
@GandalfModel
export class FieldDefinitionReviewOfSystemsResponse extends GandalfModelBase {

	@GandalfProperty()
	cardiovascularFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	constitutionalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	endocrineFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	entFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	gastrointestinalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	genitourinaryFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	hematLymphaticFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	immunologicalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	musculoSkeletalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	neuroFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	psychologicalFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	respiratoryFieldDefinition!: FieldDefinitionResponse | null;

	@GandalfProperty()
	skinFieldDefinition!: FieldDefinitionResponse | null;

}
