// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { EncounterTableResponse } from './encounter-table-response';

// @ts-ignore
import { OrderContactLensPrescriptionResponse } from './order-contact-lens-prescription-response';

// @ts-ignore
import { OrderProcessorConfigResponse } from './order-processor-config-response';

// @ts-ignore
import { OrderResponse } from './order-response';

// @ts-ignore
import { OrderVendorResponse } from './order-vendor-response';

// @ts-ignore
import { PatientCommunicationResponse } from './patient-communication-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.order.ContactLensOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensOrderResponse extends OrderResponse {

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty()
	createdOn!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate!: Date | null;

	@GandalfProperty()
	encounter!: EncounterTableResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	estimatedTaxes!: number | null;

	@GandalfProperty()
	externalSupplierIdentifier!: string | null;

	@GandalfProperty()
	externalSupplierName!: string | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	lensCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost!: number | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	notificationComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate!: Date | null;

	@GandalfProperty()
	odColorId!: number | null;

	@GandalfProperty()
	odPackagingId!: number | null;

	@GandalfProperty()
	odQuantity!: number | null;

	@GandalfProperty()
	onHold!: boolean | null;

	@GandalfProperty()
	orderId!: number | null;

	@GandalfProperty()
	originalOrderId!: number | null;

	@GandalfConstantDecorator(constants.OriginalOrderReason)
	@GandalfProperty()
	originalOrderReason!: constants.OriginalOrderReason | null;

	@GandalfProperty()
	osColorId!: number | null;

	@GandalfProperty()
	osPackagingId!: number | null;

	@GandalfProperty()
	osQuantity!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost!: number | null;

	@GandalfProperty()
	patient!: PatientNameResponse | null;

	@GandalfProperty()
	patientCommunication!: PatientCommunicationResponse | null;

	@GandalfProperty()
	patientNotified!: boolean | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfProperty()
	prescription!: OrderContactLensPrescriptionResponse | null;

	@GandalfProperty()
	processor!: OrderProcessorConfigResponse | null;

	@GandalfProperty()
	shipAddress!: AddressResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost!: number | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfProperty()
	shipToType!: constants.OrderShipToType | null;

	@GandalfConstantDecorator(constants.OrderShipmentCompany)
	@GandalfProperty()
	shippingCompany!: constants.OrderShipmentCompany | null;

	@GandalfProperty()
	smartflowOrderIdentifier!: number | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty()
	statusCode!: constants.OrderStatusCode | null;

	@GandalfProperty()
	statusDate!: Date | null;

	@GandalfProperty()
	trackingNumber!: string | null;

	@GandalfProperty()
	tray!: string | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty()
	type!: constants.OrderType | null;

	@GandalfProperty()
	unitOd!: string | null;

	@GandalfProperty()
	unitOs!: string | null;

	@GandalfProperty()
	vendor!: OrderVendorResponse | null;

	@GandalfProperty()
	vendorOrderIdentifier!: string | null;

	@GandalfProperty()
	version!: number | null;

}
