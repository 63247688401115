// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.AlertResponse */
/* istanbul ignore next */
@GandalfModel
export class AlertResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.AlertCategory)
	@GandalfProperty()
	category!: constants.AlertCategory | null;

	@GandalfProperty()
	createdBy!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn!: Date | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfConstantDecorator(constants.AlertDisplayType)
	@GandalfProperty()
	displayType!: constants.AlertDisplayType | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfConstantDecorator(constants.AlertSeverity)
	@GandalfProperty()
	severity!: constants.AlertSeverity | null;

	@GandalfConstantDecorator(constants.AlertStatus)
	@GandalfProperty()
	status!: constants.AlertStatus | null;

}
