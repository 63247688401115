// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OrdersSearchResponse } from './orders-search-response';

/** See com.rev360.pms.api.controller.order.OrderQueryResponse */
/* istanbul ignore next */
@GandalfModel
export class OrderQueryResponse extends GandalfModelBase {

	@GandalfArray(OrdersSearchResponse)
	pageResults!: OrdersSearchResponse[] | null;

	@GandalfProperty()
	total!: number | null;

}
