// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.twowaytext.TwoWayTextSimpleSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class TwoWayTextSimpleSearchRequest extends GandalfModelBase {

	@GandalfLabel('Search')
	@GandalfValidator({ sizeString: { fieldLabel: 'Search', maxLength: 1000, minLength: 2, message: 'Search must be between 2 and 1000 characters' } })
	@GandalfValidator({ notNull: { message: 'Search is required' } })
	@GandalfProperty({ isRequired: true })
	search!: string;

}
