// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.patient.task.PatientTaskResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientTaskResponse extends GandalfModelBase {

	@GandalfProperty()
	assignee!: PersonNameResponse | null;

	@GandalfProperty()
	categoryId!: number | null;

	@GandalfProperty()
	categoryName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	completionDate!: Date | null;

	@GandalfProperty()
	createdBy!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn!: Date | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate!: Date | null;

	@GandalfProperty()
	notifyOnCompletion!: boolean | null;

	@GandalfProperty()
	notifyOnUpdate!: boolean | null;

	@GandalfConstantDecorator(constants.TaskPriority)
	@GandalfProperty()
	priority!: constants.TaskPriority | null;

	@GandalfConstantDecorator(constants.TaskStatus)
	@GandalfProperty()
	status!: constants.TaskStatus | null;

	@GandalfProperty()
	taskId!: number | null;

}
