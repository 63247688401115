// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { FormFieldResponse } from './form-field-response';

/** See com.rev360.pms.api.controller.admin.encounter.examtestlibrary.AdminWorkflowTestTemplateSvcTriggerDetailResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminWorkflowTestTemplateSvcTriggerDetailResponse extends GandalfModelBase {

	@GandalfArray(Number)
	formFieldIds!: number[] | null;

	@GandalfArray(FormFieldResponse)
	formFieldResponses!: FormFieldResponse[] | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	practiceLocationId!: number | null;

	@GandalfProperty()
	serviceCode!: string | null;

	@GandalfProperty()
	serviceDescription!: string | null;

	@GandalfProperty()
	serviceId!: number | null;

	@GandalfConstantDecorator(constants.TriggerRuleType)
	@GandalfProperty()
	triggerRuleType!: constants.TriggerRuleType | null;

}
