// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.BulkNotifyPatientsRequest */
/* istanbul ignore next */
@GandalfModel
export class BulkNotifyPatientsRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	comment!: string | null;

	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'OrderIds are required' } })
	@GandalfArray(Number)
	orderIds!: number[] | null;

}
