// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.query.QueryCriteriaFieldResponse */
/* istanbul ignore next */
@GandalfModel
export class QueryCriteriaFieldResponse extends GandalfModelBase {

	@GandalfProperty()
	id!: string | null;

	@GandalfProperty()
	label!: string | null;

	@GandalfProperty()
	operand1!: string | null;

	@GandalfProperty()
	operand1Label!: string | null;

	@GandalfProperty()
	operand2!: string | null;

	@GandalfConstantDecorator(constants.QueryCriteriaOperator)
	@GandalfProperty()
	operator!: constants.QueryCriteriaOperator | null;

	@GandalfConstantDecorator(constants.QueryCriteriaOuterOperator)
	@GandalfProperty()
	outerOperator!: constants.QueryCriteriaOuterOperator | null;

	@GandalfConstantDecorator(constants.QueryFieldType)
	@GandalfProperty()
	type!: constants.QueryFieldType | null;

}
