// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.mentalstatus.UpdateMentalStatusRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateMentalStatusRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Functional Status Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	functionalStatusComments!: string | null;

	@GandalfValidator({ sizeString: { message: 'Mental Status Comments cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	mentalStatusComments!: string | null;

	@GandalfValidator({ notNull: { message: 'Mental Status Id must not be null.' } })
	@GandalfProperty({ isRequired: true })
	mentalStatusId!: number;

	@GandalfConstantDecorator(constants.Mood)
	@GandalfValidator({ notNull: { message: 'Mood must not be null.' } })
	@GandalfProperty({ isRequired: true })
	mood!: constants.Mood;

	@GandalfValidator({ sizeString: { message: 'Assessment of Mood Affect cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	moodComments!: string | null;

	@GandalfConstantDecorator(constants.Orientation)
	@GandalfValidator({ notNull: { message: 'Orientation must not be null.' } })
	@GandalfProperty({ isRequired: true })
	orientation!: constants.Orientation;

	@GandalfValidator({ sizeString: { message: 'Orientation to Time/Person/Place cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	orientationComments!: string | null;

	@GandalfValidator({ notNull: { message: 'Version must not be null.' } })
	@GandalfProperty({ isRequired: true })
	version!: number;

}
