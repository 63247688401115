// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.document.PatientDocumentResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientDocumentResponse extends GandalfModelBase {

	@GandalfProperty()
	bottomMargin!: number | null;

	@GandalfProperty()
	categoryId!: number;

	@GandalfProperty()
	documentContent!: string | null;

	@GandalfProperty()
	documentName!: string;

	@GandalfProperty()
	leftMargin!: number | null;

	@GandalfProperty()
	patientDocumentId!: number;

	@GandalfProperty()
	patientId!: number;

	@GandalfProperty()
	rightMargin!: number | null;

	@GandalfProperty()
	topMargin!: number | null;

}
