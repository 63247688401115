// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.presentillness.CreatePresentIllnessRequest */
/* istanbul ignore next */
@GandalfModel
export class CreatePresentIllnessRequest extends GandalfModelBase {

	@GandalfProperty()
	contextSelectionId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Context Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	contextText!: string | null;

	@GandalfValidator({ sizeString: { message: 'Condition cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	diagnosisText!: string | null;

	@GandalfProperty()
	durationAmount!: number | null;

	@GandalfValidator({ sizeString: { message: 'Duration Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	durationText!: string | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	durationUnit!: constants.DurationUnit | null;

	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ isRequired: true })
	encounterId!: number;

	@GandalfArray(Number)
	factorsSelectionIds!: number[] | null;

	@GandalfValidator({ sizeString: { message: 'Modifying Factors Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	factorsText!: string | null;

	@GandalfProperty()
	locationSelectionId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Location Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	locationText!: string | null;

	@GandalfArray(Number)
	qualitySelectionIds!: number[] | null;

	@GandalfValidator({ sizeString: { message: 'Quality Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	qualityText!: string | null;

	@GandalfProperty()
	severitySelectionId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Severity Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	severityText!: string | null;

	@GandalfArray(Number)
	signsSelectionIds!: number[] | null;

	@GandalfValidator({ sizeString: { message: 'Assoc S&S Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	signsText!: string | null;

	@GandalfProperty()
	timingSelectionId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Timing Text cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	timingText!: string | null;

}
