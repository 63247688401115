// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.claimsprocessor.UpdateStatusForPracticeLocationExternalCredentialRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateStatusForPracticeLocationExternalCredentialRequest extends GandalfModelBase {

	@GandalfLabel('Active')
	@GandalfValidator({ notNull: { message: 'Active is required' } })
	@GandalfProperty({ isRequired: true })
	active!: boolean;

	@GandalfConstantDecorator(constants.ExternalSystem)
	@GandalfLabel('External System')
	@GandalfValidator({ notNull: { message: 'External System is required' } })
	@GandalfProperty({ isRequired: true })
	externalSystem!: constants.ExternalSystem;

	@GandalfLabel('Practice Location')
	@GandalfValidator({ notNull: { message: 'Practice Location is required' } })
	@GandalfProperty({ isRequired: true })
	practiceLocationId!: number;

}
