// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.accounting.openedge.OpenEdgeVoidTransactionResponse */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeVoidTransactionResponse extends GandalfModelBase {

	@GandalfProperty()
	customerReceipt!: string | null;

	@GandalfProperty()
	errorCode!: string | null;

	@GandalfProperty()
	errorMessage!: string | null;

	@GandalfProperty()
	paymentTransactionId!: number | null;

	@GandalfConstantDecorator(constants.OpenEdgeCommonVoidStatus)
	@GandalfProperty()
	result!: constants.OpenEdgeCommonVoidStatus | null;

	@GandalfConstantDecorator(constants.OpenEdgeCommonTransactionStatus)
	@GandalfProperty()
	returnStatus!: constants.OpenEdgeCommonTransactionStatus | null;

	@GandalfConstantDecorator(constants.OpenEdgeCommonTransactionStatus)
	@GandalfProperty()
	voidStatus!: constants.OpenEdgeCommonTransactionStatus | null;

}
