// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { InvoiceItemPracticeDiagnosisResponse } from './invoice-item-practice-diagnosis-response';

/** See com.rev360.pms.api.controller.accounting.InvoiceItemPersonDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class InvoiceItemPersonDiagnosisResponse extends GandalfModelBase {

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate!: Date;

	@GandalfProperty()
	personDiagnosisId!: number;

	@GandalfProperty()
	practiceDiagnosis!: InvoiceItemPracticeDiagnosisResponse;

}
