// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.dynamic.form.FormPositionResponse */
/* istanbul ignore next */
@GandalfModel
export class FormPositionResponse extends GandalfModelBase {

	@GandalfProperty()
	height!: number | null;

	@GandalfProperty()
	left!: number | null;

	@GandalfProperty()
	top!: number | null;

	@GandalfProperty()
	width!: number | null;

}
