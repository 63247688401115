// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.document.PatientDocumentContentRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientDocumentContentRequest extends GandalfModelBase {

	@GandalfLabel('Content')
	@GandalfValidator({ sizeString: { message: 'Content cannot be longer than 65535 characters', minLength: 0, maxLength: 65535 } })
	@GandalfProperty()
	content!: string;

	@GandalfLabel('Patient Document')
	@GandalfValidator({ notNull: { message: 'Patient Document is required' } })
	@GandalfProperty({ isRequired: true })
	patientDocumentId!: number;

}
