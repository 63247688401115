// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { RecurPatternDailyResponse } from './recur-pattern-daily-response';

// @ts-ignore
import { RecurPatternMonthlyResponse } from './recur-pattern-monthly-response';

// @ts-ignore
import { RecurPatternWeeklyResponse } from './recur-pattern-weekly-response';

// @ts-ignore
import { RecurPatternYearlyResponse } from './recur-pattern-yearly-response';

/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.RecurringDefinitionResponse */
/* istanbul ignore next */
@GandalfModel
export class RecurringDefinitionResponse extends GandalfModelBase {

	@GandalfProperty()
	dailyRecurPattern!: RecurPatternDailyResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfConstantDecorator(constants.RecurEndType)
	@GandalfProperty()
	endType!: constants.RecurEndType | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	monthlyRecurPattern!: RecurPatternMonthlyResponse | null;

	@GandalfProperty()
	numRecurOccurrences!: number | null;

	@GandalfConstantDecorator(constants.RecurPatternType)
	@GandalfProperty()
	recurPatternType!: constants.RecurPatternType | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfProperty()
	weeklyRecurPattern!: RecurPatternWeeklyResponse | null;

	@GandalfProperty()
	yearlyRecurPattern!: RecurPatternYearlyResponse | null;

}
