// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.connect.campaign.ConnectCampaignListResponse */
/* istanbul ignore next */
@GandalfModel
export class ConnectCampaignListResponse extends GandalfModelBase {

	@GandalfProperty()
	connectCampaignId!: number | null;

	@GandalfProperty()
	createdOn!: Date | null;

	@GandalfProperty()
	ineligibleCount!: number | null;

	@GandalfProperty()
	name!: string | null;

	@GandalfProperty()
	openedCount!: number | null;

	@GandalfProperty()
	optedOutCount!: number | null;

	@GandalfProperty()
	queryName!: string | null;

	@GandalfProperty()
	scheduledDate!: Date | null;

	@GandalfConstantDecorator(constants.ConnectCampaignStatus)
	@GandalfProperty()
	status!: constants.ConnectCampaignStatus | null;

	@GandalfProperty()
	subject!: string | null;

	@GandalfProperty()
	totalCount!: number | null;

	@GandalfProperty()
	undeliveredCount!: number | null;

}
