// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.communicationtemplate.AdminPracticeTemplateResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeTemplateResponse extends GandalfModelBase {

	@GandalfProperty()
	bottomMargin!: number;

	@GandalfProperty()
	content!: string;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	fulfillsPcpCommunicationCqm!: boolean;

	@GandalfProperty()
	leftMargin!: number;

	@GandalfProperty()
	name!: string;

	@GandalfProperty()
	practiceTemplateId!: number;

	@GandalfProperty()
	rightMargin!: number;

	@GandalfProperty()
	topMargin!: number;

}
