// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreatePatientOtherInformationRequest } from './create-patient-other-information-request';

/** See com.rev360.pms.api.controller.patient.PatientOtherInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientOtherInformationRequest extends CreatePatientOtherInformationRequest {

	@GandalfValidator({ sizeString: { message: 'Alternate ID cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	alternateId!: string | null;

	@GandalfValidator({ notNull: { message: 'DOB is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	dob!: Date;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty()
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfProperty()
	ethnicityDeclined!: boolean | null;

	@GandalfArray(Number)
	ethnicityIds!: number[] | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfValidator({ notNull: { message: 'Gender is required' } })
	@GandalfProperty({ isRequired: true })
	gender!: constants.Gender;

	@GandalfConstantDecorator(constants.GenderIdentity)
	@GandalfProperty()
	genderIdentity!: constants.GenderIdentity | null;

	@GandalfProperty()
	guarantorId!: number | null;

	@GandalfConstantDecorator(constants.PersonInterpreterAssistance)
	@GandalfProperty()
	interpreterAssistance!: constants.PersonInterpreterAssistance | null;

	@GandalfValidator({ sizeString: { message: 'Last 4 SSN must be 4 characters in length', minLength: 4, maxLength: 4 } })
	@GandalfProperty()
	last4Ssn!: string | null;

	@GandalfProperty()
	raceDeclined!: boolean | null;

	@GandalfArray(Number)
	raceIds!: number[] | null;

	@GandalfConstantDecorator(constants.SexualOrientation)
	@GandalfProperty()
	sexualOrientation!: constants.SexualOrientation | null;

	@GandalfValidator({ sizeString: { message: 'SIN/PHN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfValidator({ sin: { message: 'SIN/PHN must only contain alphanumeric or dash characters' } })
	@GandalfProperty()
	sin!: string | null;

	@GandalfProperty()
	spokenLanguageDeclined!: boolean | null;

	@GandalfProperty()
	spokenLanguageId!: number | null;

	@GandalfValidator({ sizeString: { message: 'SSN cannot be longer than 128 characters', minLength: 0, maxLength: 128 } })
	@GandalfValidator({ socialSecurityNumber: { message: 'Social security number must contain 9 digits' } })
	@GandalfProperty()
	ssn!: string | null;

	@GandalfProperty()
	writtenLanguageDeclined!: boolean | null;

	@GandalfProperty()
	writtenLanguageId!: number | null;

}
