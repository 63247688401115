// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PersonNameResponse } from './person-name-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryLatestFindingsEyeglassResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryLatestFindingsEyeglassResponse extends GandalfModelBase {

	@GandalfProperty()
	arCoating!: boolean | null;

	@GandalfProperty()
	arCoatingComment!: string | null;

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty()
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty()
	authorizedPrescriber!: PersonNameResponse | null;

	@GandalfProperty()
	distancePd!: number | null;

	@GandalfProperty()
	eyeglassPrescriptionId!: number | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty()
	eyeglassPrescriptionStatus!: constants.PrescriptionCurrentStatus | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty()
	lensType!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	lensTypeComment!: string | null;

	@GandalfProperty()
	material!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	materialComment!: string | null;

	@GandalfProperty()
	nearPd!: number | null;

	@GandalfProperty()
	odAxis!: number | null;

	@GandalfProperty()
	odCylinder!: number | null;

	@GandalfProperty()
	odHorizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty()
	odHorizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty()
	odNearAdd!: number | null;

	@GandalfProperty()
	odPrescriptionBalance!: boolean | null;

	@GandalfProperty()
	odSphere!: number | null;

	@GandalfProperty()
	odVerticalPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty()
	odVerticalPrismOrientation!: constants.VerticalPrismOrientation | null;

	@GandalfProperty()
	osAxis!: number | null;

	@GandalfProperty()
	osCylinder!: number | null;

	@GandalfProperty()
	osHorizontalPrism!: number | null;

	@GandalfConstantDecorator(constants.HorizontalPrismOrientation)
	@GandalfProperty()
	osHorizontalPrismOrientation!: constants.HorizontalPrismOrientation | null;

	@GandalfProperty()
	osNearAdd!: number | null;

	@GandalfProperty()
	osPrescriptionBalance!: boolean | null;

	@GandalfProperty()
	osSphere!: number | null;

	@GandalfProperty()
	osVerticalPrism!: number | null;

	@GandalfConstantDecorator(constants.VerticalPrismOrientation)
	@GandalfProperty()
	osVerticalPrismOrientation!: constants.VerticalPrismOrientation | null;

	@GandalfProperty()
	photochromic!: boolean | null;

	@GandalfProperty()
	photochromicComment!: string | null;

	@GandalfProperty()
	polarized!: boolean | null;

	@GandalfProperty()
	polarizedComment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	prescriptionExpireDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	prescriptionStartDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty()
	prescriptionStatus!: constants.PrescriptionStatus | null;

	@GandalfProperty()
	prescriptionUses!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	tint!: ReferenceDataSummaryResponse | null;

	@GandalfProperty()
	tintComment!: string | null;

	@GandalfProperty()
	uvTreatment!: boolean | null;

	@GandalfProperty()
	uvTreatmentComment!: string | null;

}
