// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

// @ts-ignore
import { ProviderInsuranceIdentifierResponse } from './provider-insurance-identifier-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.provider.externalprovider.ExternalProviderResponse */
/* istanbul ignore next */
@GandalfModel
export class ExternalProviderResponse extends ProviderResponse {

	@GandalfProperty()
	address!: AddressResponse | null;

	@GandalfProperty()
	altPracticeName!: string | null;

	@GandalfProperty()
	credentials!: string | null;

	@GandalfProperty()
	deaNum!: string | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	directAddress!: string | null;

	@GandalfProperty()
	ein!: string | null;

	@GandalfProperty()
	email!: string | null;

	@GandalfProperty()
	firstName!: string | null;

	@GandalfConstantDecorator(constants.Gender)
	@GandalfProperty()
	gender!: constants.Gender | null;

	@GandalfProperty()
	lastName!: string | null;

	@GandalfProperty()
	licenseNum!: string | null;

	@GandalfProperty()
	nickName!: string | null;

	@GandalfProperty()
	npi!: string | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfProperty()
	phoneNumbers!: PhoneNumbersResponse | null;

	@GandalfProperty()
	providerDisplayColor!: number | null;

	@GandalfProperty()
	providerId!: number | null;

	@GandalfArray(ProviderInsuranceIdentifierResponse)
	providerInsuranceIdentifierResponses!: ProviderInsuranceIdentifierResponse[] | null;

	@GandalfProperty()
	providerType!: ReferenceDataResponse | null;

	@GandalfProperty()
	providerTypeId!: number | null;

	@GandalfProperty()
	registrationNum!: string | null;

	@GandalfConstantDecorator(constants.ProviderStatus)
	@GandalfProperty()
	status!: constants.ProviderStatus | null;

	@GandalfProperty()
	suffix!: string | null;

	@GandalfProperty()
	titleId!: number | null;

	@GandalfProperty()
	titleName!: string | null;

	@GandalfProperty()
	upin!: string | null;

}
