// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { PreviousAddressRequest } from './previous-address-request';

/** See com.rev360.pms.api.controller.patient.PatientAdditionalInformationRequest */
/* istanbul ignore next */
@GandalfModel
export class PatientAdditionalInformationRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.BloodType)
	@GandalfProperty()
	bloodType!: constants.BloodType | null;

	@GandalfProperty()
	citizenshipId!: number | null;

	@GandalfProperty()
	dominantEyeId!: number | null;

	@GandalfProperty()
	dominantHandId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Driver’s License cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	driversLicense!: string | null;

	@GandalfProperty()
	maritalStatusId!: number | null;

	@GandalfSubRequestLabel('Previous Address')
	@GandalfLabel('Previous Address')
	@GandalfProperty()
	previousAddress!: PreviousAddressRequest | null;

	@GandalfValidator({ sizeString: { message: 'Previous First Name cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	previousFirstName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty()
	previousFirstNameType!: constants.PreviousNameType | null;

	@GandalfValidator({ sizeString: { message: 'Previous Last Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	previousLastName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty()
	previousLastNameType!: constants.PreviousNameType | null;

	@GandalfValidator({ sizeString: { message: 'Previous Middle Name cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	previousMiddleName!: string | null;

	@GandalfConstantDecorator(constants.PreviousNameType)
	@GandalfProperty()
	previousMiddleNameType!: constants.PreviousNameType | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	previousNameEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	previousNameStartDate!: Date | null;

	@GandalfProperty()
	religionId!: number | null;

	@GandalfSubRequestLabel('Temporary Address')
	@GandalfLabel('Temporary Address')
	@GandalfProperty()
	temporaryAddress!: AddressOptionalRequest | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	temporaryAddressEndDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	temporaryAddressStartDate!: Date | null;

}
