// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DurationResponse } from './duration-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalSummaryLatestFindingsContactLensTrialResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalSummaryLatestFindingsContactLensTrialResponse extends GandalfModelBase {

	@GandalfProperty()
	contactLensTrialId!: number | null;

	@GandalfProperty()
	odAddDesignation!: string | null;

	@GandalfProperty()
	odAddPower!: number | null;

	@GandalfProperty()
	odAxis!: number | null;

	@GandalfProperty()
	odBaseCurve!: number | null;

	@GandalfProperty()
	odComment!: string | null;

	@GandalfProperty()
	odCylinder!: number | null;

	@GandalfProperty()
	odDiameter!: number | null;

	@GandalfProperty()
	odIsDispensed!: boolean | null;

	@GandalfProperty()
	odIsMonovision!: boolean | null;

	@GandalfProperty()
	odLocationProductName!: string | null;

	@GandalfProperty()
	odOvernightSchedule!: DurationResponse | null;

	@GandalfProperty()
	odReplacementSchedule!: DurationResponse | null;

	@GandalfProperty()
	odSphere!: number | null;

	@GandalfProperty()
	osAddDesignation!: string | null;

	@GandalfProperty()
	osAddPower!: number | null;

	@GandalfProperty()
	osAxis!: number | null;

	@GandalfProperty()
	osBaseCurve!: number | null;

	@GandalfProperty()
	osComment!: string | null;

	@GandalfProperty()
	osCylinder!: number | null;

	@GandalfProperty()
	osDiameter!: number | null;

	@GandalfProperty()
	osIsDispensed!: boolean | null;

	@GandalfProperty()
	osIsMonovision!: boolean | null;

	@GandalfProperty()
	osLocationProductName!: string | null;

	@GandalfProperty()
	osOvernightSchedule!: DurationResponse | null;

	@GandalfProperty()
	osReplacementSchedule!: DurationResponse | null;

	@GandalfProperty()
	osSphere!: number | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty()
	overallLensType!: constants.ContactLensPrescriptionType | null;

}
