// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.inventory.reconciliation.ReconciliationItemResponse */
/* istanbul ignore next */
@GandalfModel
export class ReconciliationItemResponse extends GandalfModelBase {

	@GandalfProperty()
	countedItems!: number | null;

	@GandalfProperty()
	itemDescription!: string | null;

	@GandalfProperty()
	itemSku!: string | null;

	@GandalfProperty()
	itemUpc!: string | null;

	@GandalfProperty()
	notes!: string | null;

	@GandalfProperty()
	productCategoryName!: string | null;

	@GandalfProperty()
	productManufacturerName!: string | null;

	@GandalfProperty()
	productName!: string | null;

	@GandalfProperty()
	reconciledItems!: number | null;

	@GandalfProperty()
	reportedItems!: number | null;

}
