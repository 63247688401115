// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { BaseCostReportResponse } from './base-cost-report-response';

/** See com.rev360.pms.api.controller.reporting.inventory.cost.ContactLensCostReportResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensCostReportResponse extends BaseCostReportResponse {

	@GandalfProperty()
	addDesignation!: string | null;

	@GandalfProperty()
	addPower!: number | null;

	@GandalfProperty()
	axis!: number | null;

	@GandalfProperty()
	baseCurve!: number | null;

	@GandalfProperty()
	brand!: string | null;

	@GandalfProperty()
	category!: string | null;

	@GandalfProperty()
	collection!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	costOfStock!: number | null;

	@GandalfProperty()
	cylinder!: number | null;

	@GandalfProperty()
	diameter!: number | null;

	@GandalfProperty()
	isTrial!: boolean | null;

	@GandalfProperty()
	itemDescription!: string | null;

	@GandalfProperty()
	lensColor!: string | null;

	@GandalfProperty()
	lensColorCode!: string | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	locationProductActualCost!: number | null;

	@GandalfProperty()
	locationProductItemId!: number | null;

	@GandalfProperty()
	manufacturer!: string | null;

	@GandalfProperty()
	model!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	retail!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	retailOfStock!: number | null;

	@GandalfProperty()
	sku!: string | null;

	@GandalfProperty()
	sphere!: number | null;

	@GandalfProperty()
	stock!: number | null;

	@GandalfProperty()
	upc!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	wholesale!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	wholesaleOfStock!: number | null;

}
