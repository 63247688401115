// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MasterDeviceResponse } from './master-device-response';

/** See com.rev360.pms.api.controller.shared.device.MasterFileDeviceResponse */
/* istanbul ignore next */
@GandalfModel
export class MasterFileDeviceResponse extends MasterDeviceResponse {

	@GandalfProperty()
	description!: string | null;

	@GandalfConstantDecorator(constants.MasterDeviceKey)
	@GandalfProperty()
	deviceKey!: constants.MasterDeviceKey;

	@GandalfProperty()
	fileExtension!: string;

	@GandalfProperty()
	manufacturer!: string;

	@GandalfProperty()
	masterDeviceId!: number;

	@GandalfProperty()
	model!: string;

}
