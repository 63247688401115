// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressOptionalRequest } from './address-optional-request';

// @ts-ignore
import { EyefinityOrderItemRequest } from './eyefinity-order-item-request';

// @ts-ignore
import { UpdateEyefinityOrderRequest } from './update-eyefinity-order-request';

// @ts-ignore
import { UpdateEyeglassLensMeasurementsRequest } from './update-eyeglass-lens-measurements-request';

/** See com.rev360.pms.api.controller.order.eyefinity.UpdateEyefinityEyeglassOrderRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateEyefinityEyeglassOrderRequest extends UpdateEyefinityOrderRequest {

	@GandalfLabel('AR Coating')
	@GandalfProperty()
	arCoatingId!: number | null;

	@GandalfLabel('Associated Authorization')
	@GandalfValidator({ sizeString: { message: 'Associated Authorization cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	associatedAuthorization!: string | null;

	@GandalfLabel('Contact Lens Benefit')
	@GandalfProperty()
	benefitContactLens!: boolean | null;

	@GandalfLabel('Exam Benefit')
	@GandalfProperty()
	benefitExam!: boolean | null;

	@GandalfLabel('Frame Benefit')
	@GandalfProperty()
	benefitFrame!: boolean | null;

	@GandalfLabel('Lens Benefit')
	@GandalfProperty()
	benefitLens!: boolean | null;

	@GandalfLabel('Lens Bevel')
	@GandalfValidator({ sizeString: { message: 'Lens Bevel cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	bevelExternalIdentifier!: string | null;

	@GandalfValidator({ sizeString: { message: 'Comment cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	comment!: string | null;

	@GandalfLabel('Exam Copay')
	@GandalfProperty({ propertyType: 'Money' })
	copayExam!: number | null;

	@GandalfLabel('Materials Copay')
	@GandalfProperty({ propertyType: 'Money' })
	copayMaterials!: number | null;

	@GandalfLabel('DPD')
	@GandalfProperty()
	distancePd!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	dueDate!: Date | null;

	@GandalfLabel('Lens Edge')
	@GandalfProperty()
	edgeId!: number | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfLabel('Estimate Discounts')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedDiscounts!: number | null;

	@GandalfLabel('Estimated Insurance Benefit')
	@GandalfProperty({ propertyType: 'Money' })
	estimatedInsuranceBenefit!: number | null;

	@GandalfLabel('Frame Supplier')
	@GandalfValidator({ sizeString: { message: 'Frame Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalFrameSupplierIdentifier!: string | null;

	@GandalfLabel('Frame Supplier Name')
	@GandalfValidator({ sizeString: { message: 'Frame Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalFrameSupplierName!: string | null;

	@GandalfValidator({ sizeString: { message: 'Supplier cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	externalSupplierIdentifier!: string | null;

	@GandalfValidator({ sizeString: { message: 'Supplier Name cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	externalSupplierName!: string | null;

	@GandalfLabel('Fee Schedule')
	@GandalfProperty()
	eyefinityFeeScheduleId!: number | null;

	@GandalfLabel('Order Items')
	@GandalfArray(EyefinityOrderItemRequest)
	eyefinityOrderItems!: EyefinityOrderItemRequest[] | null;

	@GandalfLabel('Allowance')
	@GandalfProperty({ propertyType: 'Money' })
	frameAllowance!: number | null;

	@GandalfLabel('Frame Color')
	@GandalfProperty()
	frameColorId!: number | null;

	@GandalfLabel('Frame Cost')
	@GandalfProperty({ propertyType: 'Money' })
	frameCost!: number | null;

	@GandalfLabel('Frame Product')
	@GandalfProperty()
	frameId!: number | null;

	@GandalfLabel('Frame Inventory')
	@GandalfProperty()
	frameItemId!: number | null;

	@GandalfLabel('Frame Remake Cost')
	@GandalfProperty({ propertyType: 'Money' })
	frameRemakeCost!: number | null;

	@GandalfLabel('Frame Size')
	@GandalfProperty()
	frameSizeId!: number | null;

	@GandalfLabel('Wholesale Allowance')
	@GandalfProperty({ propertyType: 'Money' })
	frameWholesaleAllowance!: number | null;

	@GandalfLabel('Glass Coating')
	@GandalfProperty()
	glassCoatingId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Instructions cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions!: string | null;

	@GandalfLabel('Is Valid')
	@GandalfProperty()
	isValid!: boolean | null;

	@GandalfLabel('Lens Cost')
	@GandalfProperty({ propertyType: 'Money' })
	lensCost!: number | null;

	@GandalfConstantDecorator(constants.EyefinityLensLocation)
	@GandalfLabel('Lens Eye')
	@GandalfValidator({ notNull: { message: 'Lens Eye is required' } })
	@GandalfProperty({ isRequired: true })
	lensEye!: constants.EyefinityLensLocation;

	@GandalfLabel('Lens Material')
	@GandalfValidator({ sizeString: { message: 'Lens Material cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	lensMaterialExternalIdentifier!: string | null;

	@GandalfLabel('Lens Remake Cost')
	@GandalfProperty({ propertyType: 'Money' })
	lensRemakeCost!: number | null;

	@GandalfConstantDecorator(constants.OrderLensRequest)
	@GandalfLabel('Lens Request')
	@GandalfValidator({ notNull: { message: 'Lens Request is required' } })
	@GandalfProperty({ isRequired: true })
	lensRequest!: constants.OrderLensRequest;

	@GandalfLabel('Lens Style')
	@GandalfProperty()
	lensStyleId!: number | null;

	@GandalfLabel('Lens Type')
	@GandalfValidator({ sizeString: { message: 'Lens Type cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	lensTypeExternalIdentifier!: string | null;

	@GandalfLabel('Tint Sample %')
	@GandalfValidator({ min: { min: 0, message: '{jakarta.validation.constraints.Min.message}' } })
	@GandalfValidator({ max: { max: 100, message: '{jakarta.validation.constraints.Max.message}' } })
	@GandalfProperty()
	lightDarkPercentage!: number | null;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId!: number;

	@GandalfLabel('Mirror Coating')
	@GandalfProperty()
	mirrorCoatingId!: number | null;

	@GandalfLabel('NPD')
	@GandalfProperty()
	nearPd!: number | null;

	@GandalfLabel('Lens Nose Pads')
	@GandalfProperty()
	nosePads!: boolean | null;

	@GandalfLabel('Lens Nose Pads Other')
	@GandalfValidator({ sizeString: { message: 'Lens Nose Pads Other cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	nosePadsOther!: string | null;

	@GandalfValidator({ sizeString: { message: 'Notification Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	notificationComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notificationDate!: Date | null;

	@GandalfSubRequestLabel('OD')
	@GandalfLabel('OD Measurements')
	@GandalfValidator({ notNull: { message: 'OD Measurements is required' } })
	@GandalfProperty({ isRequired: true })
	odLensMeasurements!: UpdateEyeglassLensMeasurementsRequest;

	@GandalfConstantDecorator(constants.OrderOpticalCenterType)
	@GandalfLabel('Optical Center Type')
	@GandalfProperty()
	opticalCenterType!: constants.OrderOpticalCenterType | null;

	@GandalfConstantDecorator(constants.OrderFrameIndication)
	@GandalfLabel('Frame Indicator')
	@GandalfValidator({ notNull: { message: 'Frame Indicator is required' } })
	@GandalfProperty({ isRequired: true })
	orderFrameIndication!: constants.OrderFrameIndication;

	@GandalfConstantDecorator(constants.OrderFrameSource)
	@GandalfLabel('Frame Source')
	@GandalfValidator({ notNull: { message: 'Frame Source is required' } })
	@GandalfProperty({ isRequired: true })
	orderFrameSource!: constants.OrderFrameSource;

	@GandalfValidator({ notNull: { message: 'Order is required' } })
	@GandalfProperty({ isRequired: true })
	orderId!: number;

	@GandalfSubRequestLabel('OS')
	@GandalfLabel('OS Measurements')
	@GandalfValidator({ notNull: { message: 'OS Measurements is required' } })
	@GandalfProperty({ isRequired: true })
	osLensMeasurements!: UpdateEyeglassLensMeasurementsRequest;

	@GandalfProperty({ propertyType: 'Money' })
	otherCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	otherRemakeCost!: number | null;

	@GandalfLabel('Panto')
	@GandalfProperty()
	pantoscopicTilt!: number | null;

	@GandalfValidator({ notNull: { message: 'Patient Notified is required' } })
	@GandalfProperty({ isRequired: true })
	patientNotified!: boolean;

	@GandalfLabel('Eyeglass Prescription')
	@GandalfProperty()
	prescriptionId!: number | null;

	@GandalfLabel('Lens Press-on')
	@GandalfProperty()
	pressOn!: boolean | null;

	@GandalfLabel('Lens Press-on Other')
	@GandalfValidator({ sizeString: { message: 'Lens Press-on Other cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	pressOnOther!: string | null;

	@GandalfProperty()
	processorId!: number | null;

	@GandalfLabel('Quantity')
	@GandalfValidator({ notNull: { message: 'Quantity is required' } })
	@GandalfProperty({ isRequired: true })
	quantity!: number;

	@GandalfLabel('Tint Sample')
	@GandalfValidator({ sizeString: { message: 'Tint Sample cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	sampleExternalIdentifier!: string | null;

	@GandalfLabel('Scratch Coating')
	@GandalfProperty()
	scratchCoatingId!: number | null;

	@GandalfConstantDecorator(constants.OrderSegHeightType)
	@GandalfLabel('Seg Height Type')
	@GandalfProperty()
	segHeightType!: constants.OrderSegHeightType | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipCost!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	shipRemakeCost!: number | null;

	@GandalfProperty()
	shipToOtherAddress!: AddressOptionalRequest | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfValidator({ notNull: { message: 'Ship To is required' } })
	@GandalfProperty({ isRequired: true })
	shipToType!: constants.OrderShipToType;

	@GandalfConstantDecorator(constants.OrderShipmentCompany)
	@GandalfProperty()
	shippingCompany!: constants.OrderShipmentCompany | null;

	@GandalfLabel('Lens Slab Off')
	@GandalfProperty()
	slabOff!: boolean | null;

	@GandalfLabel('Lens Slab Off Other')
	@GandalfValidator({ sizeString: { message: 'Lens Slab Off Other cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	slabOffOther!: string | null;

	@GandalfLabel('Lens Thickness')
	@GandalfValidator({ sizeString: { message: 'Lens Thickness cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	thicknessExternalIdentifier!: string | null;

	@GandalfLabel('Tint Color')
	@GandalfValidator({ sizeString: { message: 'Tint Color cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	tintColorExternalIdentifier!: string | null;

	@GandalfLabel('Tint Color Other')
	@GandalfValidator({ sizeString: { message: 'Tint Color Other cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	tintColorOther!: string | null;

	@GandalfLabel('Tint Type')
	@GandalfProperty()
	tintTypeId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Tracking # cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	trackingNumber!: string | null;

	@GandalfLabel('Tray')
	@GandalfValidator({ sizeString: { message: 'Tray cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	tray!: string | null;

	@GandalfLabel('UV Coating')
	@GandalfProperty()
	uvCoatingId!: number | null;

	@GandalfProperty()
	vendorId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Vendor Order # cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	vendorOrderIdentifier!: string | null;

	@GandalfValidator({ notNull: { message: 'Version is required' } })
	@GandalfProperty({ isRequired: true })
	version!: number;

	@GandalfLabel('Wrap')
	@GandalfProperty()
	wrapAngle!: number | null;

}
