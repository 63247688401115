// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientWorkflowStepMenuResponse } from './patient-workflow-step-menu-response';

/** See com.rev360.pms.api.controller.patient.encounter.workflow.PatientWorkflowMenuResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientWorkflowMenuResponse extends GandalfModelBase {

	@GandalfProperty()
	id!: number | null;

	@GandalfArray(PatientWorkflowStepMenuResponse)
	workflowSteps!: PatientWorkflowStepMenuResponse[] | null;

}
