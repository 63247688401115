// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.family.AddFamilyMemberRequest */
/* istanbul ignore next */
@GandalfModel
export class AddFamilyMemberRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Family Patient id is required' } })
	@GandalfProperty({ isRequired: true })
	familyPatientId!: number;

	@GandalfProperty()
	headOfHousehold!: boolean | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientIdToAdd!: number;

	@GandalfValidator({ notNull: { message: 'Family role is required' } })
	@GandalfProperty({ isRequired: true })
	roleId!: number;

}
