// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.assessment.AssessmentPersonDiagnosisResponse */
/* istanbul ignore next */
@GandalfModel
export class AssessmentPersonDiagnosisResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.EncounterAutoDiagnosisStatus)
	@GandalfProperty()
	autoDiagnosisStatus!: constants.EncounterAutoDiagnosisStatus | null;

	@GandalfProperty()
	code!: string | null;

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfProperty()
	codeSet!: constants.CodeSet | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate!: Date | null;

	@GandalfConstantDecorator(constants.DiagnosisLocation)
	@GandalfProperty()
	diagnosisLocation!: constants.DiagnosisLocation | null;

	@GandalfConstantDecorator(constants.DiagnosisLocationQualifier)
	@GandalfProperty()
	diagnosisLocationQualifier!: constants.DiagnosisLocationQualifier | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	disabledDate!: Date | null;

	@GandalfProperty()
	encounterAutoDiagnosisId!: number | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty()
	isPrimary!: boolean | null;

	@GandalfProperty()
	isShowDisabledWarning!: boolean | null;

	@GandalfProperty()
	needConversion!: boolean | null;

	@GandalfProperty()
	originalDiagnosisId!: number | null;

	@GandalfProperty()
	personDiagnosisId!: number | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfProperty()
	practiceDiagnosisId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	resolutionDate!: Date | null;

	@GandalfProperty()
	shortDescription!: string | null;

	@GandalfConstantDecorator(constants.PersonDiagnosisStatus)
	@GandalfProperty()
	status!: constants.PersonDiagnosisStatus | null;

}
