// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ServiceLineResponse } from './service-line-response';

/** See com.rev360.pms.api.controller.accounting.payment.PaymentInfoResponse */
/* istanbul ignore next */
@GandalfModel
export class PaymentInfoResponse extends GandalfModelBase {

	@GandalfProperty()
	claimId!: string;

	@GandalfProperty()
	claimStatus!: string | null;

	@GandalfProperty()
	insuredFirstName!: string | null;

	@GandalfProperty()
	insuredIdentificationNumber!: string | null;

	@GandalfProperty()
	insuredLastName!: string | null;

	@GandalfProperty()
	insuredMiddleName!: string | null;

	@GandalfProperty()
	insuredSuffix!: string | null;

	@GandalfProperty()
	memberIdentificationNumber!: string | null;

	@GandalfProperty()
	patientFirstName!: string | null;

	@GandalfProperty()
	patientLastName!: string | null;

	@GandalfProperty()
	patientMiddleName!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	patientResponsibility!: number;

	@GandalfProperty()
	patientSuffix!: string | null;

	@GandalfProperty()
	payerClaimControlNumber!: string | null;

	@GandalfProperty()
	renderingProviderFirstName!: string | null;

	@GandalfProperty()
	renderingProviderLastName!: string | null;

	@GandalfProperty()
	renderingProviderNPI!: string | null;

	@GandalfArray(ServiceLineResponse)
	serviceLines!: ServiceLineResponse[] | null;

}
