// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminOpenEdgeCardReaderResponse } from './admin-open-edge-card-reader-response';

/** See com.rev360.pms.api.controller.admin.solutions.openedge.OpenEdgeSettingsResponse */
/* istanbul ignore next */
@GandalfModel
export class OpenEdgeSettingsResponse extends GandalfModelBase {

	@GandalfArray(AdminOpenEdgeCardReaderResponse)
	cardReaders!: AdminOpenEdgeCardReaderResponse[] | null;

	@GandalfProperty()
	isActive!: boolean | null;

	@GandalfProperty()
	motoAccountAuthKey!: string | null;

	@GandalfProperty()
	motoAccountTerminalId!: string | null;

	@GandalfProperty()
	motoAccountXWebId!: string | null;

	@GandalfProperty()
	openEdgeSettingsId!: number | null;

	@GandalfProperty()
	payOnlineEnabled!: boolean | null;

	@GandalfProperty()
	payOnlineUrlPath!: string | null;

	@GandalfProperty()
	practiceLocationId!: number | null;

	@GandalfProperty()
	retailAccountAuthKey!: string | null;

	@GandalfProperty()
	retailAccountTerminalId!: string | null;

	@GandalfProperty()
	retailAccountXWebId!: string | null;

	@GandalfProperty()
	statementPayOnlineEnabled!: boolean | null;

}
