// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { LoincCodeResponse } from './loinc-code-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.patient.pod.PatientPodLabOrderResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientPodLabOrderResponse extends GandalfModelBase {

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	endDate!: Date | null;

	@GandalfProperty()
	fileCount!: number | null;

	@GandalfProperty()
	hasInterpretation!: boolean;

	@GandalfProperty()
	labName!: string | null;

	@GandalfProperty()
	labOrderId!: number;

	@GandalfProperty()
	order!: LoincCodeResponse | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDate!: Date | null;

	@GandalfConstantDecorator(constants.LabOrderStatus)
	@GandalfProperty()
	orderStatus!: constants.LabOrderStatus;

	@GandalfProperty()
	orderedBy!: ProviderResponse | null;

	@GandalfConstantDecorator(constants.LabOrderResultStatus)
	@GandalfProperty()
	resultStatus!: constants.LabOrderResultStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

}
