// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ReferenceDataResponse } from './reference-data-response';

/** See com.rev360.pms.api.controller.patient.PatientNameResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientNameResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.PatientStatus)
	@GandalfProperty()
	activeStatus!: constants.PatientStatus | null;

	@GandalfProperty()
	credentials!: string | null;

	@GandalfProperty()
	firstName!: string | null;

	@GandalfProperty()
	lastName!: string | null;

	@GandalfProperty()
	middleName!: string | null;

	@GandalfProperty()
	nickname!: string | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	personId!: number | null;

	@GandalfProperty()
	pronunciation!: string | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty()
	status!: constants.EstablishedStatus | null;

	@GandalfProperty()
	suffix!: string | null;

	@GandalfProperty()
	title!: ReferenceDataResponse | null;

}
