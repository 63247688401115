// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ProfessionalTimeResponse } from './professional-time-response';

/** See com.rev360.pms.api.controller.encounter.timer.ProfessionalTimeSessionResponse */
/* istanbul ignore next */
@GandalfModel
export class ProfessionalTimeSessionResponse extends GandalfModelBase {

	@GandalfProperty()
	isStopped!: boolean | null;

	@GandalfProperty()
	professionalTime!: ProfessionalTimeResponse | null;

	@GandalfProperty()
	professionalTimeSessionId!: number | null;

	@GandalfProperty()
	totalUserSessionTime!: number | null;

}
