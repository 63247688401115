// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.alert.UpdateAlertRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateAlertRequest extends GandalfModelBase {

	@GandalfLabel('Alert')
	@GandalfValidator({ notNull: { message: 'Alert is required' } })
	@GandalfProperty({ isRequired: true })
	alertId!: number;

	@GandalfConstantDecorator(constants.AlertCategory)
	@GandalfLabel('Category')
	@GandalfValidator({ notNull: { message: 'Category is required' } })
	@GandalfProperty({ isRequired: true })
	category!: constants.AlertCategory;

	@GandalfLabel('Description')
	@GandalfValidator({ notNull: { message: 'Description is required' } })
	@GandalfValidator({ sizeString: { message: 'Description must be between 1 and 255 characters', minLength: 1, maxLength: 255 } })
	@GandalfProperty({ isRequired: true })
	description!: string;

	@GandalfConstantDecorator(constants.AlertDisplayType)
	@GandalfLabel('Display Type')
	@GandalfValidator({ notNull: { message: 'Display Type is required' } })
	@GandalfProperty({ isRequired: true })
	displayType!: constants.AlertDisplayType;

	@GandalfConstantDecorator(constants.AlertSeverity)
	@GandalfLabel('Severity')
	@GandalfValidator({ notNull: { message: 'Severity is required' } })
	@GandalfProperty({ isRequired: true })
	severity!: constants.AlertSeverity;

}
