// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MedicationPrescriptionRequest } from './medication-prescription-request';

/** See com.rev360.pms.api.controller.prescription.CreateMedicationPrescriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateMedicationPrescriptionRequest extends MedicationPrescriptionRequest {

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfValidator({ notNull: { message: 'Authorization Type is required' } })
	@GandalfProperty({ isRequired: true })
	authorizationType!: constants.PrescriptionAuthorizationType;

	@GandalfValidator({ notNull: { message: 'Authorize Prescription is required' } })
	@GandalfProperty({ isRequired: true })
	authorizePrescription!: boolean;

	@GandalfProperty()
	authorizedByProviderId!: number | null;

	@GandalfProperty()
	bodyLocationId!: number | null;

	@GandalfProperty()
	diagnosisId!: number | null;

	@GandalfValidator({ min: { min: 0, message: 'Dispensing Info must be greater than or equal to 0' } })
	@GandalfProperty()
	dispenseAmount!: number | null;

	@GandalfProperty()
	dispenseUnitId!: number | null;

	@GandalfValidator({ notNull: { message: 'Do Not Substitute is required' } })
	@GandalfProperty({ isRequired: true })
	doNotSubstitute!: boolean;

	@GandalfValidator({ min: { min: 0, message: 'Dosage Amount must be greater than or equal to 0' } })
	@GandalfProperty()
	dosageAmount!: number | null;

	@GandalfProperty()
	dosageUnitId!: number | null;

	@GandalfValidator({ notNull: { message: 'Drug is required' } })
	@GandalfProperty({ isRequired: true })
	drugId!: number;

	@GandalfValidator({ min: { min: 0, message: 'Duration must be greater than or equal to 0' } })
	@GandalfProperty()
	durationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate!: Date | null;

	@GandalfProperty()
	frequencyId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	instructions!: string | null;

	@GandalfValidator({ notNull: { message: 'Originated Without CPOE is required' } })
	@GandalfProperty({ isRequired: true })
	nonCpoeEntry!: boolean;

	@GandalfValidator({ min: { min: 0, message: 'Number of Refills must be greater than or equal to 0' } })
	@GandalfProperty()
	numRefillsAllowed!: number | null;

	@GandalfValidator({ notNull: { message: 'Patient is required' } })
	@GandalfProperty({ isRequired: true })
	patientId!: number;

	@GandalfProperty()
	routeId!: number | null;

	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	startDate!: Date;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfValidator({ notNull: { message: 'Status is required' } })
	@GandalfProperty({ isRequired: true })
	status!: constants.PrescriptionStatus;

	@GandalfProperty({ propertyType: 'LocalDate' })
	stopDate!: Date | null;

	@GandalfValidator({ notNull: { message: 'Use Provider Signature is required' } })
	@GandalfProperty({ isRequired: true })
	useProviderSignature!: boolean;

}
