// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.order.UpdateNonBillableRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateNonBillableRequest extends GandalfModelBase {

	@GandalfLabel('Billable Item(s)')
	@GandalfValidator({ notNull: { message: 'Billable Items are required' } })
	@GandalfValidator({ sizeArray: { min: 1, max: 2147483647, message: 'Billable Items are required' } })
	@GandalfArray(Number)
	billableItemIds!: number[];

	@GandalfLabel('Reason')
	@GandalfValidator({ notNull: { message: 'Reason is required' } })
	@GandalfProperty({ isRequired: true })
	reasonId!: number;

}
