// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PracticeLocationAccessCodeResponse } from './practice-location-access-code-response';

/** See com.rev360.pms.api.controller.admin.general.location.AdminPracticeLocationPhrResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminPracticeLocationPhrResponse extends GandalfModelBase {

	@GandalfArray(PracticeLocationAccessCodeResponse)
	accessCodes!: PracticeLocationAccessCodeResponse[] | null;

	@GandalfProperty()
	allowPhrScheduling!: boolean | null;

	@GandalfProperty()
	allowRegisterPhrScheduling!: boolean | null;

	@GandalfArray(Number)
	patientScheduleNotificationPersonIds!: number[] | null;

	@GandalfArray(Number)
	patientUpdateNotificationPersonIds!: number[] | null;

	@GandalfArray(Number)
	registrationNotificationPersonIds!: number[] | null;

	@GandalfArray(Number)
	registrationScheduleNotificationPersonIds!: number[] | null;

}
