// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

/** See com.rev360.pms.api.controller.reporting.schedule.EncounterReportResponse */
/* istanbul ignore next */
@GandalfModel
export class EncounterReportResponse extends GandalfModelBase {

	@GandalfProperty()
	approvalStatus!: string | null;

	@GandalfProperty()
	employeeFirstName!: string | null;

	@GandalfProperty()
	employeeLastName!: string | null;

	@GandalfProperty()
	encounterDate!: Date | null;

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfConstantDecorator(constants.EstablishedStatus)
	@GandalfProperty()
	establishedStatus!: constants.EstablishedStatus | null;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	patientId!: number | null;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfProperty()
	providerFirstName!: string | null;

	@GandalfProperty()
	providerLastName!: string | null;

	@GandalfProperty()
	status!: string | null;

	@GandalfProperty()
	subTypeValue!: string | null;

	@GandalfProperty()
	templateName!: string | null;

}
