// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.person.PersonGeneralHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonGeneralHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	drugAllergyComment!: string | null;

	@GandalfProperty()
	insurance!: string | null;

	@GandalfProperty()
	medications!: string | null;

	@GandalfProperty()
	noKnownDrugAllergies!: boolean | null;

	@GandalfProperty()
	noLatexSensitivity!: boolean | null;

	@GandalfProperty()
	noOtherKnownAllergies!: boolean | null;

	@GandalfProperty()
	otherAllergyComment!: string | null;

	@GandalfProperty()
	personId!: number | null;

}
