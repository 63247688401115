// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.solutions.twelve84.SubscribeTwelve84SubscriptionRequest */
/* istanbul ignore next */
@GandalfModel
export class SubscribeTwelve84SubscriptionRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Location Contact is required' } })
	@GandalfProperty({ isRequired: true })
	locationContactEmployeeId!: number;

	@GandalfValidator({ notNull: { message: 'Practice Location is required' } })
	@GandalfProperty({ isRequired: true })
	practiceLocationId!: number;

}
