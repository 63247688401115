// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CarePlanItemResponse } from './care-plan-item-response';

/** See com.rev360.pms.api.controller.encounter.plan.PersonDiagnosisWithCarePlansResponse */
/* istanbul ignore next */
@GandalfModel
export class PersonDiagnosisWithCarePlansResponse extends GandalfModelBase {

	@GandalfArray(CarePlanItemResponse)
	carePlanItems!: CarePlanItemResponse[] | null;

	@GandalfProperty()
	code!: string | null;

	@GandalfConstantDecorator(constants.CodeSet)
	@GandalfProperty()
	codeSet!: constants.CodeSet | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	diagnosisDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	disabledDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isPrimary!: boolean | null;

	@GandalfProperty()
	shortDescription!: string | null;

	@GandalfProperty()
	showDisabledWarning!: boolean | null;

}
