// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientFileInfoResponse } from './patient-file-info-response';

/** See com.rev360.pms.api.controller.insurance.IntakeInsuranceListResponse */
/* istanbul ignore next */
@GandalfModel
export class IntakeInsuranceListResponse extends GandalfModelBase {

	@GandalfProperty()
	insuranceCompanyName!: string | null;

	@GandalfProperty()
	intakeInsuranceId!: number | null;

	@GandalfProperty()
	patientFileInfoResponse!: PatientFileInfoResponse | null;

	@GandalfProperty()
	personInsuranceId!: number | null;

	@GandalfProperty()
	policyHolder!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	policyHolderDob!: Date | null;

	@GandalfConstantDecorator(constants.PolicyHolderRelationship)
	@GandalfProperty()
	policyHolderRelationship!: constants.PolicyHolderRelationship | null;

	@GandalfProperty()
	policyNumber!: string | null;

	@GandalfProperty()
	practiceInsuranceCompanyId!: number | null;

	@GandalfConstantDecorator(constants.IntakeReconciledStatus)
	@GandalfProperty()
	reconciledStatus!: constants.IntakeReconciledStatus | null;

	@GandalfConstantDecorator(constants.IntakeStatus)
	@GandalfProperty()
	status!: constants.IntakeStatus | null;

}
