// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.file.MedicationPrescriptionImportResponse */
/* istanbul ignore next */
@GandalfModel
export class MedicationPrescriptionImportResponse extends GandalfModelBase {

	@GandalfProperty()
	code!: string | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty()
	derivedStatus!: constants.PrescriptionCurrentStatus;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	lastModificationDate!: Date | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty()
	status!: constants.PrescriptionStatus | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	stopDate!: Date | null;

}
