// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.vendorsandpartners.insurancecompanies.practiceinsuranceremittancecodeadjustment.X12CodeExistsRequest */
/* istanbul ignore next */
@GandalfModel
export class X12CodeExistsRequest extends GandalfModelBase {

	@GandalfConstantDecorator(constants.RemittanceAdjustmentGroupCode)
	@GandalfLabel('Group Code')
	@GandalfProperty()
	groupCode!: constants.RemittanceAdjustmentGroupCode | null;

	@GandalfLabel('Practice Insurance Company')
	@GandalfValidator({ notNull: { message: 'Practice Insurance Company is required' } })
	@GandalfProperty({ isRequired: true })
	practiceInsuranceCompanyId!: number;

	@GandalfLabel('Adjustment Code')
	@GandalfValidator({ notNull: { message: 'Adjustment Code is required' } })
	@GandalfValidator({ sizeString: { message: 'Adjustment Code must be between 1 and 20 characters', minLength: 1, maxLength: 20 } })
	@GandalfProperty({ isRequired: true })
	x12Code!: string;

}
