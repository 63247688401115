// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.inventory.MinMaxInventoryReportResponse */
/* istanbul ignore next */
@GandalfModel
export class MinMaxInventoryReportResponse extends GandalfModelBase {

	@GandalfProperty()
	averageAge!: number | null;

	@GandalfProperty()
	averageAgeOfSales!: number | null;

	@GandalfProperty()
	averageAgeOfStock!: number | null;

	@GandalfProperty()
	costOfSales!: number | null;

	@GandalfProperty()
	costOfStock!: number | null;

	@GandalfProperty()
	itemContactAddDesignation!: string | null;

	@GandalfProperty()
	itemContactAddPower!: number | null;

	@GandalfProperty()
	itemContactAxis!: number | null;

	@GandalfProperty()
	itemContactBaseCurve!: number | null;

	@GandalfProperty()
	itemContactCylinder!: number | null;

	@GandalfProperty()
	itemContactDiameter!: number | null;

	@GandalfProperty()
	itemContactIsTrial!: boolean | null;

	@GandalfProperty()
	itemContactLensColor!: string | null;

	@GandalfProperty()
	itemContactLensColorCode!: string | null;

	@GandalfProperty()
	itemContactSphere!: number | null;

	@GandalfProperty()
	itemDescription!: string | null;

	@GandalfProperty()
	itemFrameBridge!: string | null;

	@GandalfProperty()
	itemFrameColor!: string | null;

	@GandalfProperty()
	itemFrameColorCode!: string | null;

	@GandalfProperty()
	itemFrameEye!: string | null;

	@GandalfProperty()
	itemFrameTemple!: string | null;

	@GandalfProperty()
	itemFrameTempleUnit!: string | null;

	@GandalfProperty()
	itemId!: number | null;

	@GandalfProperty()
	itemIsStocked!: boolean | null;

	@GandalfProperty()
	itemMinStockQty!: number | null;

	@GandalfProperty()
	itemPrice!: number | null;

	@GandalfProperty()
	itemQuantityInStock!: number | null;

	@GandalfProperty()
	itemSku!: string | null;

	@GandalfProperty()
	itemUpc!: string | null;

	@GandalfProperty()
	locationProductBrandName!: string | null;

	@GandalfProperty()
	locationProductCategoryName!: string | null;

	@GandalfProperty()
	locationProductCode!: string | null;

	@GandalfProperty()
	locationProductCollectionName!: string | null;

	@GandalfProperty()
	locationProductCost!: number | null;

	@GandalfProperty()
	locationProductId!: number | null;

	@GandalfProperty()
	locationProductManufacturerName!: string | null;

	@GandalfProperty()
	locationProductModel!: string | null;

	@GandalfProperty()
	locationProductPrice!: number | null;

	@GandalfProperty()
	locationProductWholesale!: number | null;

	@GandalfProperty()
	maximumAge!: number | null;

	@GandalfProperty()
	maximumAgeOfStock!: number | null;

	@GandalfProperty()
	minimumAge!: number | null;

	@GandalfProperty()
	minimumAgeOfStock!: number | null;

	@GandalfProperty()
	practiceLocationId!: number | null;

	@GandalfProperty()
	practiceLocationName!: string | null;

	@GandalfProperty()
	retailOfStock!: number | null;

	@GandalfProperty()
	retailPrice!: number | null;

	@GandalfProperty()
	salesAdjustments!: number | null;

	@GandalfProperty()
	salesDiscountAmount!: number | null;

	@GandalfProperty()
	salesGrossSales!: number | null;

	@GandalfProperty()
	salesNetSales!: number | null;

	@GandalfProperty()
	salesQuantity!: number | null;

	@GandalfProperty()
	salesTaxAmount!: number | null;

	@GandalfProperty()
	wholesaleOfSales!: number | null;

	@GandalfProperty()
	wholesaleOfStock!: number | null;

}
