// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { MedicationPrescriptionResponse } from './medication-prescription-response';

// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.prescription.ExternalMedicationPrescriptionResponse */
/* istanbul ignore next */
@GandalfModel
export class ExternalMedicationPrescriptionResponse extends MedicationPrescriptionResponse {

	@GandalfProperty({ propertyType: 'LocalDate' })
	authorizationDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty()
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty()
	authorizedBy!: ProviderResponse | null;

	@GandalfProperty()
	authorizingProviderFirstName!: string | null;

	@GandalfProperty()
	authorizingProviderLastName!: string | null;

	@GandalfProperty()
	bodyLocationId!: number | null;

	@GandalfProperty()
	daysSupply!: number | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	descriptionWithoutDosage!: string | null;

	@GandalfProperty()
	diagnosisCode!: string | null;

	@GandalfProperty()
	diagnosisId!: number | null;

	@GandalfProperty()
	dispenseAmount!: number | null;

	@GandalfProperty()
	dispenseUnitId!: number | null;

	@GandalfProperty()
	doNotSubstitute!: boolean | null;

	@GandalfProperty()
	dosage!: string | null;

	@GandalfProperty()
	dosageAmount!: number | null;

	@GandalfProperty()
	dosageUnitId!: number | null;

	@GandalfProperty()
	drugId!: number | null;

	@GandalfProperty()
	drugName!: string | null;

	@GandalfProperty()
	duration!: string | null;

	@GandalfProperty()
	durationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	durationUnit!: constants.DurationUnit | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate!: Date | null;

	@GandalfProperty()
	externalIdentifier!: string | null;

	@GandalfConstantDecorator(constants.PrescriptionCurrentStatus)
	@GandalfProperty()
	externalMedicationPrescriptionStatus!: constants.PrescriptionCurrentStatus | null;

	@GandalfProperty()
	frequencyId!: number | null;

	@GandalfProperty()
	generalMedicationId!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	modifiedDate!: Date | null;

	@GandalfProperty()
	nonCpoeEntry!: boolean | null;

	@GandalfProperty()
	numRefillsAllowed!: number | null;

	@GandalfProperty()
	pharmacyAddress1!: string | null;

	@GandalfProperty()
	pharmacyAddress2!: string | null;

	@GandalfProperty()
	pharmacyCity!: string | null;

	@GandalfProperty()
	pharmacyContactEmail!: string | null;

	@GandalfProperty()
	pharmacyContactFax!: string | null;

	@GandalfProperty()
	pharmacyContactPhone!: string | null;

	@GandalfProperty()
	pharmacyName!: string | null;

	@GandalfProperty()
	pharmacyState!: string | null;

	@GandalfProperty()
	pharmacyZipCode!: string | null;

	@GandalfProperty()
	primaryProviderFirstName!: string | null;

	@GandalfProperty()
	primaryProviderLastName!: string | null;

	@GandalfProperty()
	routeId!: number | null;

	@GandalfProperty()
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty()
	status!: constants.PrescriptionStatus | null;

	@GandalfProperty()
	stopDate!: Date | null;

	@GandalfProperty()
	useProviderSignature!: boolean | null;

	@GandalfProperty()
	version!: number | null;

}
