// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.SignEncounterRequest */
/* istanbul ignore next */
@GandalfModel
export class SignEncounterRequest extends GandalfModelBase {

	@GandalfLabel('Encounter')
	@GandalfValidator({ notNull: { message: 'Encounter is required' } })
	@GandalfProperty({ isRequired: true })
	encounterId!: number;

	@GandalfLabel('Password')
	@GandalfValidator({ notNull: { message: 'Password is required' } })
	@GandalfValidator({ sizeString: { message: 'Password must be between 1 and 128 characters', minLength: 1, maxLength: 128 } })
	@GandalfProperty({ isRequired: true })
	userPassword!: string;

}
