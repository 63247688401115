// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.schedule.scheduleitem.recurrence.CreateRecurPatternYearlyRequest */
/* istanbul ignore next */
@GandalfModel
export class CreateRecurPatternYearlyRequest extends GandalfModelBase {

	@GandalfValidator({ min: { min: 1, message: 'Day of month must be between 1 and 31' } })
	@GandalfValidator({ max: { max: 31, message: 'Day of month must be between 1 and 31' } })
	@GandalfProperty()
	yearlyDayOfMonth!: number | null;

	@GandalfConstantDecorator(constants.DaySelect)
	@GandalfProperty()
	yearlyDaySelect!: constants.DaySelect | null;

	@GandalfConstantDecorator(constants.MonthSelect)
	@GandalfProperty()
	yearlyMonthSelect!: constants.MonthSelect | null;

	@GandalfConstantDecorator(constants.MonthSelect)
	@GandalfProperty()
	yearlyMonthSelect2!: constants.MonthSelect | null;

	@GandalfConstantDecorator(constants.YearlyPatternType)
	@GandalfValidator({ notNull: { message: 'Pattern type must be set' } })
	@GandalfProperty({ isRequired: true })
	yearlyPatternType!: constants.YearlyPatternType;

	@GandalfConstantDecorator(constants.WeekSelect)
	@GandalfProperty()
	yearlyWeekSelect!: constants.WeekSelect | null;

}
