// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AddressResponse } from './address-response';

// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PhoneNumbersResponse } from './phone-numbers-response';

/** See com.rev360.pms.api.controller.patient.family.PatientFamilyInformationResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientFamilyInformationResponse extends GandalfModelBase {

	@GandalfProperty()
	email!: string | null;

	@GandalfProperty()
	emailDeclined!: boolean | null;

	@GandalfProperty()
	homeAddress!: AddressResponse | null;

	@GandalfProperty()
	patientName!: PatientNameResponse | null;

	@GandalfProperty()
	phoneNumbers!: PhoneNumbersResponse | null;

}
