// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.encounter.history.ros.ReviewOfSystemsRequest */
/* istanbul ignore next */
@GandalfModel
export class ReviewOfSystemsRequest extends GandalfModelBase {

	@GandalfArray(Number)
	cardiovascularIds!: number[] | null;

	@GandalfValidator({ sizeString: { message: 'Comments cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	comments!: string | null;

	@GandalfArray(Number)
	constitutionalIds!: number[] | null;

	@GandalfValidator({ notNull: { message: 'Encounter Id is required' } })
	@GandalfProperty({ isRequired: true })
	encounterId!: number;

	@GandalfArray(Number)
	endocrineIds!: number[] | null;

	@GandalfArray(Number)
	entIds!: number[] | null;

	@GandalfArray(Number)
	gastrointestinalIds!: number[] | null;

	@GandalfArray(Number)
	genitourinaryIds!: number[] | null;

	@GandalfArray(Number)
	hematLymphaticIds!: number[] | null;

	@GandalfArray(Number)
	immunologicalIds!: number[] | null;

	@GandalfArray(Number)
	musculoskeletalIds!: number[] | null;

	@GandalfArray(Number)
	neurologicalIds!: number[] | null;

	@GandalfArray(Number)
	psychologicalIds!: number[] | null;

	@GandalfArray(Number)
	respiratoryIds!: number[] | null;

	@GandalfArray(Number)
	skinIds!: number[] | null;

}
