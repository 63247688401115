// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ProviderResponse } from './provider-response';

/** See com.rev360.pms.api.controller.prescription.MedicationPrescriptionListResponse */
/* istanbul ignore next */
@GandalfModel
export class MedicationPrescriptionListResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.PrescriptionAuthorizationType)
	@GandalfProperty()
	authorizationType!: constants.PrescriptionAuthorizationType | null;

	@GandalfProperty()
	authorizedBy!: ProviderResponse | null;

	@GandalfProperty()
	authorizingProviderFirstName!: string | null;

	@GandalfProperty()
	authorizingProviderLastName!: string | null;

	@GandalfProperty()
	description!: string | null;

	@GandalfProperty()
	drugCode!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	expirationDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	isRxNormDrug!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	modifiedDate!: Date | null;

	@GandalfProperty()
	primaryProviderFirstName!: string | null;

	@GandalfProperty()
	primaryProviderLastName!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	startDate!: Date | null;

	@GandalfConstantDecorator(constants.PrescriptionStatus)
	@GandalfProperty()
	status!: constants.PrescriptionStatus | null;

	@GandalfConstantDecorator(constants.MedicationPrescriptionType)
	@GandalfProperty()
	type!: constants.MedicationPrescriptionType | null;

	@GandalfProperty()
	useProviderSignature!: boolean | null;

}
