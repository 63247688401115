// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { CreateOrUpdateHistoryDiagnosisCarePlanItemRequest } from './create-or-update-history-diagnosis-care-plan-item-request';

/** See com.rev360.pms.api.controller.encounter.history.reasonforvisit.UpdateReasonForVisitRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateReasonForVisitRequest extends GandalfModelBase {

	@GandalfProperty()
	additionalReasonValueId!: number | null;

	@GandalfArray(CreateOrUpdateHistoryDiagnosisCarePlanItemRequest)
	historyDiagnosisCarePlanItems!: CreateOrUpdateHistoryDiagnosisCarePlanItemRequest[] | null;

	@GandalfValidator({ notNull: { message: 'Id is required' } })
	@GandalfProperty({ isRequired: true })
	id!: number;

	@GandalfValidator({ notNull: { message: 'Medical Reason is required' } })
	@GandalfProperty({ isRequired: true })
	isMedicalReasonForVisit!: boolean;

	@GandalfValidator({ notNull: { message: 'Transition of Care is required' } })
	@GandalfProperty({ isRequired: true })
	isTransitionOfCare!: boolean;

	@GandalfValidator({ sizeString: { message: 'Patient Reason For Visit cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	patientReasonForVisit!: string | null;

	@GandalfValidator({ sizeString: { message: 'Provider Reason For Visit cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	providerReasonForVisit!: string | null;

	@GandalfProperty()
	referringProviderId!: number | null;

	@GandalfConstantDecorator(constants.TocReceivedStatus)
	@GandalfProperty()
	tocReceivedStatus!: constants.TocReceivedStatus | null;

}
