// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.admin.employee.detailed.request.AdminEmployeeProviderDetailsRequest */
/* istanbul ignore next */
@GandalfModel
export class AdminEmployeeProviderDetailsRequest extends GandalfModelBase {

	@GandalfLabel('API Key')
	@GandalfValidator({ sizeString: { message: 'API Key cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	apiKey!: string | null;

	@GandalfLabel('DEA #')
	@GandalfValidator({ sizeString: { message: 'DEA # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	deaNumber!: string | null;

	@GandalfLabel('Display Color')
	@GandalfProperty()
	displayColor!: number | null;

	@GandalfLabel('EIN #')
	@GandalfValidator({ sizeString: { message: 'EIN # cannot be longer than 20 characters', minLength: 0, maxLength: 20 } })
	@GandalfProperty()
	einNumber!: string | null;

	@GandalfProperty()
	employeeIsProvider!: boolean | null;

	@GandalfLabel('Provider Photo')
	@GandalfProperty()
	externalPhotoId!: number | null;

	@GandalfLabel('Insurances')
	@GandalfArray(Number)
	insuranceIdentifierIds!: number[] | null;

	@GandalfLabel('License #')
	@GandalfValidator({ sizeString: { message: 'License # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	licenseNumber!: string | null;

	@GandalfLabel('NPI')
	@GandalfValidator({ sizeString: { message: 'NPI must be between 10 and 15 characters', minLength: 10, maxLength: 15 } })
	@GandalfProperty()
	npi!: string | null;

	@GandalfLabel('Description')
	@GandalfValidator({ sizeString: { message: 'Description cannot be longer than 4000 characters', minLength: 0, maxLength: 4000 } })
	@GandalfProperty()
	portalDescription!: string | null;

	@GandalfLabel('Practitioner #')
	@GandalfValidator({ sizeString: { message: 'Practitioner # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	practitionerNum!: string | null;

	@GandalfLabel('Provider Type')
	@GandalfProperty()
	providerType!: number | null;

	@GandalfLabel('Registration #')
	@GandalfValidator({ sizeString: { message: 'Registration # cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	registrationNum!: string | null;

	@GandalfLabel('Show Descriptions and Images on Portal')
	@GandalfValidator({ notNull: { message: 'Show Descriptions and Images on Portal is required' } })
	@GandalfProperty({ isRequired: true })
	showPublic!: boolean;

	@GandalfLabel('Signature')
	@GandalfProperty()
	signatureId!: number | null;

	@GandalfLabel('UPIN')
	@GandalfValidator({ sizeString: { message: 'UPIN cannot be longer than 50 characters', minLength: 0, maxLength: 50 } })
	@GandalfProperty()
	upin!: string | null;

}
