// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.query.QuerySelectFieldResponse */
/* istanbul ignore next */
@GandalfModel
export class QuerySelectFieldResponse extends GandalfModelBase {

	@GandalfConstantDecorator(constants.QueryAddressLabelField)
	@GandalfProperty()
	addressLabel!: constants.QueryAddressLabelField | null;

	@GandalfConstantDecorator(constants.QueryFieldAlignment)
	@GandalfProperty()
	align!: constants.QueryFieldAlignment | null;

	@GandalfProperty()
	id!: string | null;

	@GandalfProperty()
	label!: string | null;

	@GandalfProperty()
	width!: number | null;

}
