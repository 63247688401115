// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeResponse } from './contact-lens-eye-response';

/** See com.rev360.pms.api.controller.encounter.history.contactlens.ContactLensHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class ContactLensHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty()
	continuousWearPeriodAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	continuousWearPeriodUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	dailyWearingTimeAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	dailyWearingTimeUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	dropsUsed!: string | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	lensAgeAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	lensAgeUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	od!: ContactLensEyeResponse | null;

	@GandalfProperty()
	os!: ContactLensEyeResponse | null;

	@GandalfProperty()
	otherSupplies!: string | null;

	@GandalfConstantDecorator(constants.ContactLensHistoryType)
	@GandalfProperty()
	overallLensType!: constants.ContactLensHistoryType | null;

	@GandalfProperty()
	replacementScheduleAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	replacementScheduleUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	solutionUsed!: string | null;

	@GandalfProperty()
	supplyRemainingAmount!: number | null;

	@GandalfConstantDecorator(constants.ContactLensHistoryPackaging)
	@GandalfProperty()
	supplyRemainingPacking!: constants.ContactLensHistoryPackaging | null;

	@GandalfProperty()
	todaysWearingTimeAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	todaysWearingTimeUnit!: constants.DurationUnit | null;

}
