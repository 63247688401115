// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.common.AddressOptionalRequest */
/* istanbul ignore next */
@GandalfModel
export class AddressOptionalRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { message: 'Address line 1 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine1!: string | null;

	@GandalfValidator({ sizeString: { message: 'Address line 2 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine2!: string | null;

	@GandalfValidator({ sizeString: { message: 'City cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	city!: string | null;

	@GandalfLabel('Zip Code')
	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	nonStandardPostalCode!: string | null;

	@GandalfLabel('Zip Code')
	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ postalCode: { message: 'Zip code is invalid' } })
	@GandalfProperty()
	postalCode!: string | null;

	@GandalfValidator({ sizeString: { message: 'State Province cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	stateProvince!: string | null;

}
