// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.category.UpdateRelatedCategoryRequest */
/* istanbul ignore next */
@GandalfModel
export class UpdateRelatedCategoryRequest extends GandalfModelBase {

	@GandalfLabel('Related Category Id')
	@GandalfValidator({ notNull: { message: 'Related Category Id is required' } })
	@GandalfProperty({ isRequired: true })
	relatedCategoryId!: number;

	@GandalfLabel('Value Category')
	@GandalfProperty()
	valueCategoryId!: number | null;

}
