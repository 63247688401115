// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.common.AddressRequiredRequest */
/* istanbul ignore next */
@GandalfModel
export class AddressRequiredRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Address line 1 is required' } })
	@GandalfValidator({ sizeString: { message: 'Address line 1 must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfProperty({ isRequired: true })
	addressLine1!: string;

	@GandalfValidator({ sizeString: { message: 'Address line 2 cannot be longer than 100 characters', minLength: 0, maxLength: 100 } })
	@GandalfProperty()
	addressLine2!: string | null;

	@GandalfValidator({ sizeString: { message: 'City must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfValidator({ notNull: { message: 'City is required' } })
	@GandalfProperty({ isRequired: true })
	city!: string;

	@GandalfLabel('Zip Code')
	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfProperty()
	nonStandardPostalCode!: string | null;

	@GandalfLabel('Zip Code')
	@GandalfValidator({ sizeString: { message: 'Zip Code cannot be longer than 30 characters', minLength: 0, maxLength: 30 } })
	@GandalfValidator({ postalCode: { message: 'Zip code is invalid' } })
	@GandalfProperty()
	postalCode!: string | null;

	@GandalfValidator({ sizeString: { message: 'State Province must be between 1 and 100 characters', minLength: 1, maxLength: 100 } })
	@GandalfValidator({ notNull: { message: 'State/Province is required' } })
	@GandalfProperty({ isRequired: true })
	stateProvince!: string;

}
