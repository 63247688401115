// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.shared.category.FindRelatedCategoriesRequest */
/* istanbul ignore next */
@GandalfModel
export class FindRelatedCategoriesRequest extends GandalfModelBase {

	@GandalfLabel('Parent Id')
	@GandalfValidator({ notNull: { message: 'Parent Id is required' } })
	@GandalfProperty({ isRequired: true })
	parentId!: number;

	@GandalfConstantDecorator(constants.RelatedEntityType)
	@GandalfLabel('Related Entity Type')
	@GandalfValidator({ notNull: { message: 'Related Entity Type is required' } })
	@GandalfProperty({ isRequired: true })
	relatedEntityType!: constants.RelatedEntityType;

}
