// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.barcode.BarcodeSearchRequest */
/* istanbul ignore next */
@GandalfModel
export class BarcodeSearchRequest extends GandalfModelBase {

	@GandalfValidator({ notNull: { message: 'Barcode is required' } })
	@GandalfValidator({ sizeString: { fieldLabel: 'Barcode', maxLength: 1000, minLength: 1, message: 'Barcode must be between 1 and 1000 characters' } })
	@GandalfProperty({ isRequired: true })
	barcodeValue!: string;

	@GandalfValidator({ notNull: { message: 'Location ID is required' } })
	@GandalfProperty({ isRequired: true })
	locationId!: number;

	@GandalfProperty()
	productCategoryId!: number | null;

	@GandalfConstantDecorator(constants.BarcodeSearchType)
	@GandalfValidator({ notNull: { message: 'Search type is required' } })
	@GandalfProperty({ isRequired: true })
	searchType!: constants.BarcodeSearchType;

}
