// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OpticalOrderPodOrderResponse } from './optical-order-pod-order-response';

// @ts-ignore
import { ReferenceDataSummaryResponse } from './reference-data-summary-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderPodEyeglassResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderPodEyeglassResponse extends OpticalOrderPodOrderResponse {

	@GandalfProperty()
	brand!: string | null;

	@GandalfProperty()
	color!: string | null;

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn!: Date | null;

	@GandalfProperty()
	eye!: number | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfProperty()
	model!: string | null;

	@GandalfProperty()
	onHold!: boolean | null;

	@GandalfProperty()
	orderId!: number | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty()
	orderType!: constants.OrderType | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty()
	status!: constants.OrderStatusCode | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate!: Date | null;

	@GandalfProperty()
	usedFor!: ReferenceDataSummaryResponse | null;

}
