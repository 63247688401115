// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.ordersoptical.PatientOrderHistoryResponse */
/* istanbul ignore next */
@GandalfModel
export class PatientOrderHistoryResponse extends GandalfModelBase {

	@GandalfProperty()
	comment!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	completionDate!: Date | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	notifiedComments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	notifiedDate!: Date | null;

	@GandalfProperty()
	onHold!: boolean | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	orderDate!: Date | null;

	@GandalfConstantDecorator(constants.OrderProcessorType)
	@GandalfProperty()
	orderProcessorType!: constants.OrderProcessorType | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty()
	orderType!: constants.OrderType | null;

	@GandalfConstantDecorator(constants.OrderShipToType)
	@GandalfProperty()
	shipToType!: constants.OrderShipToType | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty()
	status!: constants.OrderStatusCode | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate!: Date | null;

	@GandalfProperty()
	vendorName!: string | null;

	@GandalfProperty()
	vendorSmartflowCode!: string | null;

}
