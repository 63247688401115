// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { OpticalOrderPodOrderResponse } from './optical-order-pod-order-response';

/** See com.rev360.pms.api.controller.patient.opticalsummary.OpticalOrderPodContactLensResponse */
/* istanbul ignore next */
@GandalfModel
export class OpticalOrderPodContactLensResponse extends OpticalOrderPodOrderResponse {

	@GandalfProperty()
	comments!: string | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	createdOn!: Date | null;

	@GandalfProperty()
	instructions!: string | null;

	@GandalfConstantDecorator(constants.ContactLensPrescriptionType)
	@GandalfProperty()
	lensType!: constants.ContactLensPrescriptionType | null;

	@GandalfProperty()
	odColor!: string | null;

	@GandalfProperty()
	odModel!: string | null;

	@GandalfProperty()
	odPackagingType!: string | null;

	@GandalfProperty()
	odQuantity!: number | null;

	@GandalfProperty()
	onHold!: boolean | null;

	@GandalfProperty()
	orderId!: number | null;

	@GandalfConstantDecorator(constants.OrderType)
	@GandalfProperty()
	orderType!: constants.OrderType | null;

	@GandalfProperty()
	osColor!: string | null;

	@GandalfProperty()
	osModel!: string | null;

	@GandalfProperty()
	osPackagingType!: string | null;

	@GandalfProperty()
	osQuantity!: number | null;

	@GandalfConstantDecorator(constants.OrderStatusCode)
	@GandalfProperty()
	status!: constants.OrderStatusCode | null;

	@GandalfProperty({ propertyType: 'LocalDate' })
	statusDate!: Date | null;

}
