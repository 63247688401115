// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.reporting.inventory.sales.BasicSalesReportRequest */
/* istanbul ignore next */
@GandalfModel
export class BasicSalesReportRequest extends GandalfModelBase {

	@GandalfValidator({ sizeString: { fieldLabel: 'Filter content', maxLength: 1000, minLength: 0, message: 'Filter content must be less than or equal to 1000 characters' } })
	@GandalfProperty()
	generalFilter!: string | null;

	@GandalfValidator({ notNull: { message: 'Location is required' } })
	@GandalfProperty({ isRequired: true })
	locationId!: number;

	@GandalfProperty()
	locationProductCategoryId!: number | null;

	@GandalfValidator({ notNull: { message: 'End Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	salesEndDate!: Date;

	@GandalfValidator({ notNull: { message: 'Start Date is required' } })
	@GandalfProperty({ propertyType: 'LocalDate', isRequired: true })
	salesStartDate!: Date;

}
