// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { ContactLensEyeRequest } from './contact-lens-eye-request';

/** See com.rev360.pms.api.controller.prescription.ContactLensEyeRgpRequest */
/* istanbul ignore next */
@GandalfModel
export class ContactLensEyeRgpRequest extends ContactLensEyeRequest {

	@GandalfProperty()
	addDiameter!: number | null;

	@GandalfProperty()
	addPower!: number | null;

	@GandalfProperty()
	axis!: number | null;

	@GandalfProperty()
	backOpticalDiameter!: number | null;

	@GandalfProperty()
	baseCurve!: number | null;

	@GandalfProperty()
	baseCurve2!: number | null;

	@GandalfConstantDecorator(constants.BaseCurveUnit)
	@GandalfProperty()
	baseCurveUnit!: constants.BaseCurveUnit | null;

	@GandalfProperty()
	centerThickness!: number | null;

	@GandalfProperty()
	cylinder!: number | null;

	@GandalfProperty()
	diameter!: number | null;

	@GandalfProperty()
	distanceZone!: number | null;

	@GandalfProperty()
	dot!: boolean | null;

	@GandalfProperty()
	edgeLiftAmount!: number | null;

	@GandalfProperty()
	edgeLiftId!: number | null;

	@GandalfProperty()
	fenestration!: boolean | null;

	@GandalfProperty()
	firstCurveRadius!: number | null;

	@GandalfProperty()
	firstCurveWidth!: number | null;

	@GandalfProperty()
	fourthCurveRadius!: number | null;

	@GandalfProperty()
	fourthCurveWidth!: number | null;

	@GandalfProperty()
	id!: number | null;

	@GandalfProperty()
	intermediateSegment!: number | null;

	@GandalfProperty()
	isMonovision!: boolean | null;

	@GandalfProperty()
	landingZoneAngle!: number | null;

	@GandalfProperty()
	lensMaterialId!: number | null;

	@GandalfProperty()
	limbalClearanceId!: number | null;

	@GandalfProperty()
	locationProductId!: number | null;

	@GandalfProperty()
	locationProductItemId!: number | null;

	@GandalfValidator({ sizeString: { message: 'Notes cannot be longer than 255 characters', minLength: 0, maxLength: 255 } })
	@GandalfProperty()
	notes!: string | null;

	@GandalfProperty()
	opticZone!: number | null;

	@GandalfProperty()
	overnightDurationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	overnightDurationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	peripheralCurveRadius!: number | null;

	@GandalfProperty()
	peripheralCurveWidth!: number | null;

	@GandalfProperty()
	peripheralEdgeId!: number | null;

	@GandalfProperty()
	plasmaTreatment!: boolean | null;

	@GandalfProperty()
	productColorId!: number | null;

	@GandalfProperty()
	profileId!: number | null;

	@GandalfProperty()
	replacementDurationAmount!: number | null;

	@GandalfConstantDecorator(constants.DurationUnit)
	@GandalfProperty()
	replacementDurationUnit!: constants.DurationUnit | null;

	@GandalfProperty()
	returnZoneDiameter!: number | null;

	@GandalfProperty()
	sagittalDepth!: number | null;

	@GandalfProperty()
	secondCurveRadius!: number | null;

	@GandalfProperty()
	secondCurveWidth!: number | null;

	@GandalfProperty()
	secondaryCurveRadius!: number | null;

	@GandalfProperty()
	secondaryCurveWidth!: number | null;

	@GandalfProperty()
	segmentHeight!: number | null;

	@GandalfProperty()
	specialCharacteristicsId!: number | null;

	@GandalfProperty()
	sphere!: number | null;

	@GandalfProperty()
	sphere2!: number | null;

	@GandalfProperty()
	thirdCurveRadius!: number | null;

	@GandalfProperty()
	thirdCurveWidth!: number | null;

	@GandalfProperty()
	truncation!: number | null;

	@GandalfProperty()
	version!: number | null;

}
