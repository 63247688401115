// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


/** See com.rev360.pms.api.controller.patient.ongoingcare.RefractiveCareResponse */
/* istanbul ignore next */
@GandalfModel
export class RefractiveCareResponse extends GandalfModelBase {

	@GandalfProperty()
	encounterId!: number | null;

	@GandalfProperty()
	odAxis!: number | null;

	@GandalfProperty()
	odCylinder!: number | null;

	@GandalfProperty()
	odSphere!: number | null;

	@GandalfProperty()
	odVisualAcuity!: string | null;

	@GandalfProperty()
	osAxis!: number | null;

	@GandalfProperty()
	osCylinder!: number | null;

	@GandalfProperty()
	osSphere!: number | null;

	@GandalfProperty()
	osVisualAcuity!: string | null;

	@GandalfProperty()
	templateName!: string | null;

	@GandalfProperty()
	testDateTime!: Date | null;

	@GandalfProperty()
	testName!: string | null;

}
