// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { PatientNameResponse } from './patient-name-response';

// @ts-ignore
import { PersonNameResponse } from './person-name-response';

/** See com.rev360.pms.api.controller.reporting.accounting.SalesReportResponse */
/* istanbul ignore next */
@GandalfModel
export class SalesReportResponse extends GandalfModelBase {

	@GandalfProperty()
	adjustmentId!: number | null;

	@GandalfConstantDecorator(constants.InvoiceItemAdjustmentType)
	@GandalfProperty()
	adjustmentType!: constants.InvoiceItemAdjustmentType | null;

	@GandalfProperty({ propertyType: 'Money' })
	adjustments!: number | null;

	@GandalfProperty()
	categoryId!: string | null;

	@GandalfProperty()
	categoryName!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	discounts!: number | null;

	@GandalfProperty({ propertyType: 'Money' })
	gross!: number | null;

	@GandalfProperty()
	invoiceId!: number | null;

	@GandalfProperty()
	itemDescription!: string | null;

	@GandalfProperty()
	itemId!: number | null;

	@GandalfProperty()
	itemName!: string | null;

	@GandalfProperty()
	itemNameId!: string | null;

	@GandalfProperty()
	itemTaxId!: number | null;

	@GandalfProperty()
	itemTaxName!: string | null;

	@GandalfConstantDecorator(constants.InvoiceItemType)
	@GandalfProperty()
	itemType!: constants.InvoiceItemType | null;

	@GandalfProperty()
	locationId!: number | null;

	@GandalfProperty()
	locationName!: string | null;

	@GandalfProperty()
	manufacturerId!: number | null;

	@GandalfProperty()
	manufacturerName!: string | null;

	@GandalfProperty({ propertyType: 'Money' })
	net!: number | null;

	@GandalfProperty()
	patient!: PatientNameResponse | null;

	@GandalfProperty()
	payerEntityId!: number | null;

	@GandalfProperty()
	payerId!: number | null;

	@GandalfProperty()
	payerName!: string | null;

	@GandalfConstantDecorator(constants.PayerType)
	@GandalfProperty()
	payerType!: constants.PayerType | null;

	@GandalfProperty()
	provider!: PersonNameResponse | null;

	@GandalfProperty()
	qtySold!: number | null;

	@GandalfProperty()
	reportCategoryId!: string | null;

	@GandalfProperty()
	reportCategoryName!: string | null;

	@GandalfProperty()
	reportName!: string | null;

	@GandalfProperty()
	reportNameId!: string | null;

	@GandalfProperty()
	reportType!: string | null;

	@GandalfProperty()
	reportTypeString!: string | null;

	@GandalfProperty()
	responsibleForSale!: PersonNameResponse | null;

	@GandalfProperty({ propertyType: 'Money' })
	taxes!: number | null;

}
