// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { DailyOfficeHoursResponse } from './daily-office-hours-response';

/** See com.rev360.pms.api.controller.schedule.PracticeLocationOfficeHoursResponse */
/* istanbul ignore next */
@GandalfModel
export class PracticeLocationOfficeHoursResponse extends GandalfModelBase {

	@GandalfProperty()
	friday!: DailyOfficeHoursResponse | null;

	@GandalfProperty()
	monday!: DailyOfficeHoursResponse | null;

	@GandalfProperty()
	saturday!: DailyOfficeHoursResponse | null;

	@GandalfProperty()
	sunday!: DailyOfficeHoursResponse | null;

	@GandalfProperty()
	thursday!: DailyOfficeHoursResponse | null;

	@GandalfProperty()
	tuesday!: DailyOfficeHoursResponse | null;

	@GandalfProperty()
	wednesday!: DailyOfficeHoursResponse | null;

}
