// @ts-ignore
import { GandalfArray } from 'gandalf';
// @ts-ignore
import { GandalfConstantDecorator } from 'gandalf';
// @ts-ignore
import { GandalfLabel } from 'gandalf';
// @ts-ignore
import { GandalfModel } from 'gandalf';
// @ts-ignore
import { GandalfModelBase } from 'gandalf';
// @ts-ignore
import { GandalfProperty } from 'gandalf';
// @ts-ignore
import { GandalfSubRequestLabel } from 'gandalf';
// @ts-ignore
import { GandalfValidator } from 'gandalf';
// @ts-ignore
import * as constants from '../constants';


// @ts-ignore
import { AdminWorkflowTestTemplateFieldResponse } from './admin-workflow-test-template-field-response';

/** See com.rev360.pms.api.controller.admin.communicationtemplate.AdminWorkflowTestTemplateFieldsResponse */
/* istanbul ignore next */
@GandalfModel
export class AdminWorkflowTestTemplateFieldsResponse extends GandalfModelBase {

	@GandalfArray(AdminWorkflowTestTemplateFieldResponse)
	fields!: AdminWorkflowTestTemplateFieldResponse[];

	@GandalfProperty()
	masterIdentifier!: number | null;

	@GandalfProperty()
	name!: string;

	@GandalfProperty()
	workflowTestTemplateId!: number;

}
